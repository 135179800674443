import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import { useQuery } from 'react-query';
import {
    DeleteEnterpriseRequest,
    SaveEnterpriseLonLatRequest,
    VisitHistoryExcelRequest,
} from '../../components/typings/enterprise/types';

const getEnterpriseApi = (
    currListPage: number,
    pageSize: number,
    searchValue: string,
) => {
    return axiosApiInstance.get(
        apiUrl +
            '/api/v2/join/list?size=' +
            pageSize +
            '&page=' +
            (currListPage - 1) +
            (!!searchValue ? '&searchText=' + searchValue : ''),
    );
};

export const useEnterpriseListQuery = (
    currListPage: number,
    pageSize: number,
    searchValue: string,
) =>
    useQuery(
        ['useEnterpriseListQuery'],
        () => getEnterpriseApi(currListPage, pageSize, searchValue),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const deleteEnterpriseInfo = (params: DeleteEnterpriseRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/enterprise',
        method: 'DELETE',
        data: params,
    });

export const getEnterpriseInfoApi = (bargSn: number) => {
    return axiosApiInstance({
        url: apiUrl + '/api/v2/entp/barg/info',
        method: 'GET',
        params: {
            bargSn,
        },
    });
};

export const getBbargeStateMemberMainApi = (
    entpNo: string,
    bargNo: string,
    dy: string,
) => {
    return axiosApiInstance({
        url: apiUrl + '/api/barg/stats/member/main',
        method: 'GET',
        params: {
            entp_no: entpNo,
            barg_no: bargNo,
            dy,
        },
    });
};

export const getCalcMainApi = (
    entpNo: string,
    bargNo: string,
    dy: string,
    gbnCd: string,
) => {
    return axiosApiInstance({
        url: apiUrl + '/api/barg/calc/main',
        method: 'GET',
        params: {
            entp_no: entpNo,
            barg_no: bargNo,
            gbn_cd: gbnCd,
            dy,
        },
    });
};

export const saveEnterpriseLonLat = (params: SaveEnterpriseLonLatRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/enterprise',
        method: 'PUT',
        data: params,
    });

export const getVisitHistoryExcel = (params: VisitHistoryExcelRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/visit-history',
        method: 'GET',
        responseType: 'blob',
        params: params,
        headers: { responseType: 'arraybuffer' },
    });

export const setManagerActive = (bargNo: string) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/manager/active',
        method: 'POST',
        data: {
            bbargeNo: bargNo,
        },
    });

const getEnterpriseLogApi = (
    currListPage: number,
    pageSize: number,
    searchValue: string,
) => {
    return axiosApiInstance.get(
        apiUrl +
            '/api/v1/join/get-company-log?size=' +
            pageSize +
            '&page=' +
            (currListPage - 1) +
            (!!searchValue ? '&search=' + searchValue : ''),
    );
};

export const useEnterpriseLogListQuery = (
    currListPage: number,
    pageSize: number,
    searchValue: string,
) =>
    useQuery(
        ['useEnterpriseLogListQuery'],
        () => getEnterpriseLogApi(currListPage, pageSize, searchValue),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );
