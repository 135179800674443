import { ReservationStatus } from '../components/typings/common/types';

const imageExtArr = [
    'png',
    'jpeg',
    'jpg',
    'gif',
    'svg',
    'PNG',
    'JPEG',
    'JPG',
    'GIF',
    'SVG',
];

const excelExtArr = ['xls', 'xlsx'];

export const getProductStatusCdNm = (
    code: 'sale' | 'reserved' | 'soldout' | 'delete',
) => {
    switch (code) {
        case 'sale':
            return '판매중';
        case 'reserved':
            return '예약중';
        case 'soldout':
            return '판매완료';
        case 'delete':
            return '삭제';
        default:
            return '';
    }
};

export const CheckPassword = (uid: string, upw: string) => {
    if (!/^[a-zA-Z0-9]{8,20}$/.test(upw)) {
        alert('비밀번호는 숫자와 영문자 조합으로 8~20자리를 사용해야 합니다.');
        return false;
    }
    let chk_num = upw.search(/[0-9]/g);
    let chk_eng = upw.search(/[a-z]/gi);
    if (chk_num < 0 || chk_eng < 0) {
        alert('비밀번호는 숫자와 영무자를 혼용하여야 합니다.');
        return false;
    }
    if (/(\w)\1\1\1/.test(upw)) {
        alert('비밀번호에 같은 문자를 4번 이상 사용하실 수 없습니다.');
        return false;
    }
    if (upw.search(uid) > -1) {
        alert('ID가 포함된 비밀번호는 사용하실 수 없습니다.');
        return false;
    }
    return true;
};

export const checkImageExt = (str: string) => {
    return imageExtArr.includes(str);
};

export const checkExcelExt = (str: string) => {
    return excelExtArr.includes(str);
};

export const parseNoticeType = (str: 'BIZ' | 'USER') => {
    switch (str) {
        case 'USER':
            return '사용자';
        case 'BIZ':
            return '사업자';
        default:
            return '';
    }
};
export const getReservationStatusName = (code: ReservationStatus) => {
    switch (code) {
        case 'RESERVATION':
            return '예약확정';
        case 'RESERVATION_WAIT':
            return '확정대기';
        case 'CANCEL':
            return '사용자취소';
        case 'BIZ_CANCEL':
            return '사업자취소';
        case 'PAYMENT_ERROR':
            return '결제오류';
        default:
            return '';
    }
};

export const clickImg = (imgSrc: string, width?: number, height?: number) => {
    const imageWin = window.open(
        '',
        '',
        `width=${width ? width : 1000}px, height=${height ? height : 503}px`,
    );
    imageWin?.document.write("<html><body style='margin:0'>");
    imageWin?.document.write("<img src='" + imgSrc + "' />");
    imageWin?.document.write('</body></html>');
};

export const clickMp4 = (imgSrc: string, width?: number, height?: number) => {
    const imageWin = window.open(
        '',
        '',
        `width=${width ? width : 1200}px, height=${height ? height : 1200}px`,
    );
    imageWin?.document.write("<html><body style='margin:0'>");
    imageWin?.document.write('<video id="video" controls loop>');
    imageWin?.document.write("<source src='" + imgSrc + "' type='video/mp4'>");
    imageWin?.document.write('</video>');
    imageWin?.document.write('</body></html>');
};

export const parseStringDateTime = (v: string) => {
    return [
        v.substring(0, 4),
        '-',
        v.substring(4, 6),
        '-',
        v.substring(6, 8),
        ' ',
        v.substring(8, 10),
        ':',
        v.substring(10, 12),
        ':',
        v.substring(12, 14),
    ].join('');
};

export const getUserInfoValue = (key: string) => {
    const userInfoTxt = window.localStorage.getItem('userInfo');
    let userInfo = userInfoTxt ? JSON.parse(userInfoTxt) : null;
    if (!!userInfo && userInfo[key]) {
        return userInfo[key];
    }
    return '';
};
