import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import searchImage from '../../assets/search.png';
import Pagination from '../common/Pagination';
import { useMutation, useQueryClient } from 'react-query';
import {
    insertItem,
    setLeppaMarketItemVisibility,
    useLeppaMarketItemsQuery,
} from '../../hooks/leppa-market/useLeppaMarket';
import {
    CloudUploadRounded,
    VisibilityOffRounded,
    VisibilityRounded,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { checkImageExt, clickImg } from '../../utils/util';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { useLeppaMarketCategoryQuery } from '../../hooks/category/useCategory';
import { LeppaMarketCategoryData } from '../typings/category/response';
import { LeppaMarketItemsData } from '../typings/leppa-market/response';
import { ExistingOptionData } from '../typings/leppa-market/types';

const Container = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div`
    margin: 0;
    padding: 0;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
    white-space: pre-wrap;
`;

const TitleTd = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
    white-space: pre-wrap;
    cursor: pointer;
`;

const SearchArea = styled.div`
    display: flex;
`;

const SearchInput = styled.input`
    width: 256px;
    height: 46px;
    padding: 0 15px;
    box-sizing: border-box;
    border: 1px solid #398fce;
    vertical-align: top;
`;

const SearchBtn = styled.span`
    float: right;
    width: 46px;
    height: 46px;
    line-height: 46px;
    background: #398fce url(${searchImage}) no-repeat 13px 13px;
    font-size: 0;
    text-indent: -9999px;
    cursor: pointer;
`;

const TitleArea = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Price = styled.div``;
const DiscountPrice = styled.div``;
const OriginPrice = styled.div`
    text-decoration: line-through;
`;

const CustomButton = styled.div`
    background: #398fce;
    padding: 10px 15px;
    border-radius: 3px;
    border: 1px solid #398fce;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    height: 24px;
    min-width: 28px;
`;

const OptionArea = styled.div`
    display: flex;
    align-items: center;
`;

const LeppaMarketItems = () => {
    const pageSize = 20;
    const [currListPage, setCurrListPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [inputProductPopup, setInputProductPopup] = useState(false);
    const [categoryId, setCategoryId] = useState(0);
    const [inputFields, setInputFields] = useState<Array<ExistingOptionData>>([
        {
            optionId: 'temp1',
            title: '',
            price: 0,
        },
    ]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [optionGroupTitle, setOptionGroupTitle] = useState('선택');
    const [newFieldId, setNewFieldId] = useState(1);
    const [mainFileName, setMainFileName] = useState('');
    const [topFileName, setTopFileName] = useState('');
    const [detailFileName, setDetailFileName] = useState('');
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const { isLoading, isError, data, refetch } = useLeppaMarketItemsQuery(
        currListPage,
        pageSize,
        searchText,
    );
    const { data: categoryData } = useLeppaMarketCategoryQuery();

    const setLeppaMarketItemVisibilityMutation = useMutation(
        setLeppaMarketItemVisibility,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useLeppaMarketItemsQuery');
            },
        },
    );

    const insertItemMutation = useMutation(insertItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('useLeppaMarketItemsQuery');
        },
    });

    const setList = (page: number) => {
        setCurrListPage(page);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (currListPage > 1) {
                setCurrListPage(1);
            } else {
                refetch();
            }
        }
    };

    const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const onSearch = () => {
        if (currListPage > 1) {
            setCurrListPage(1);
        } else {
            refetch();
        }
    };

    const firstCategoryId = categoryData?.data.ResultData.content[0].sn;

    useEffect(() => {
        if (firstCategoryId) {
            setCategoryId(firstCategoryId);
        }
    }, [firstCategoryId]);

    useEffect(() => {
        refetch();
    }, [currListPage, refetch]);

    const setItemVisibility = (
        v: LeppaMarketItemsData,
        isVisibility: string,
    ) => {
        let msg = '하단배너를 노출하시겠습니까?';
        if (isVisibility === 'N') {
            msg = '하단배너를 비노출하시겠습니까?';
        }
        if (window.confirm(msg)) {
            let params = {
                itemId: v.itemId,
                showYn: isVisibility,
            };
            setLeppaMarketItemVisibilityMutation.mutate(params);
        }
    };

    const handleInputProduct = () => {
        setInputProductPopup(true);
    };

    const handleClose = () => {
        setCategoryId(firstCategoryId);
        setInputFields([
            {
                optionId: 'temp1',
                title: '',
                price: 0,
            },
        ]);
        setOptionGroupTitle('선택');
        setTitle('');
        setDescription('');
        setPrice(0);
        setDiscount(0);
        setMainFileName('');
        setTopFileName('');
        setDetailFileName('');
        setInputProductPopup(false);
    };

    const handleAddOption = () => {
        let newField = {
            optionId: `newField_${newFieldId}`,
            title: '',
            price: 0,
        };
        setNewFieldId(newFieldId + 1);
        setInputFields([...inputFields, newField]);
    };

    const handleNewOptionChange = (
        idx: number,
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        let newOption = [...inputFields];
        let key = e.target.name;
        let value: string | number = e.target.value;
        if (key === 'price') {
            value = parseInt(value);
            if (isNaN(value)) {
                value = 0;
            }
        }
        newOption[idx][key] = value;
        setInputFields(newOption);
    };

    const handleOptionDelete = (v: ExistingOptionData) => {
        let newOption = [...inputFields];
        let targetOptionId = v.optionId;

        if (newOption.length === 1) {
            alert('옵션은 1개 이상 필요합니다.');
            return false;
        }

        const itemToFind = newOption.find(function (item) {
            return item.optionId === targetOptionId;
        });
        const idx = newOption.indexOf(itemToFind as ExistingOptionData);
        if (idx > -1) {
            newOption.splice(idx, 1);
        }
        setInputFields(newOption);
    };

    const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
        let fileName = e.target.value;
        if (!checkImageExt(fileName.substring(fileName.lastIndexOf('.') + 1))) {
            alert('이미지 파일만 첨부 가능합니다.');
            return false;
        }
        setMainFileName(fileName.substring(fileName.lastIndexOf('\\') + 1));
    };

    const handleChangeTopImage = () => {
        let fileInput = document.getElementById('file-top') as HTMLInputElement;
        if (fileInput && fileInput.files) {
            let files = fileInput.files;
            let fileName = [];
            for (let i = 0, cnt = files.length; i < cnt; i++) {
                if (
                    !checkImageExt(
                        files[i].name.substring(
                            files[i].name.lastIndexOf('.') + 1,
                        ),
                    )
                ) {
                    alert('이미지 파일만 첨부 가능합니다.');
                    setTopFileName('');
                    return false;
                }
                fileName.push(files[i].name);
            }

            setTopFileName(fileName.join());
        }
    };

    const handleChangeDetailImage = () => {
        let fileInput = document.getElementById(
            'file-detail',
        ) as HTMLInputElement;
        if (fileInput && fileInput.files) {
            let files = fileInput.files;
            let fileName = [];
            for (let i = 0, cnt = files.length; i < cnt; i++) {
                if (
                    !checkImageExt(
                        files[i].name.substring(
                            files[i].name.lastIndexOf('.') + 1,
                        ),
                    )
                ) {
                    alert('이미지 파일만 첨부 가능합니다.');
                    setDetailFileName('');
                    return false;
                }
                fileName.push(files[i].name);
            }

            setDetailFileName(fileName.join());
        }
    };

    const handleOptionSubmit = () => {
        if (
            title === '' ||
            description === '' ||
            mainFileName === '' ||
            topFileName === '' ||
            detailFileName === ''
        ) {
            alert('정보입력 또는 사진을 첨부하세요');
            return false;
        }
        for (let i = 0, cnt = inputFields.length; i < cnt; i++) {
            if (inputFields[i].title === '') {
                alert('정보입력 또는 사진을 첨부하세요');
                return false;
            }
        }

        let option = {
            optionTitle: optionGroupTitle,
            options: inputFields,
        };

        let params = {
            title,
            description,
            price,
            discount,
            categorySn: categoryId,
            options: JSON.stringify(option),
        };
        insertItemMutation.mutate(params);
        handleClose();
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Container>
                    <TitleArea>
                        <Title>레빠마켓 상품 리스트</Title>
                        <SearchArea>
                            <SearchInput
                                placeholder="검색어를 입력하세요."
                                onKeyDown={onKeyDown}
                                value={searchText}
                                onChange={onSearchChange}
                            />
                            <SearchBtn onClick={onSearch} />
                            <CustomButton onClick={handleInputProduct}>
                                상품 등록
                            </CustomButton>
                        </SearchArea>
                    </TitleArea>
                    <TableArea>
                        <Table>
                            <colgroup>
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>상품명</Th>
                                    <Th>메인이미지</Th>
                                    <Th>가격</Th>
                                    <Th>노출여부</Th>
                                    <Th>카테고리명</Th>
                                    <Th>최종수정일</Th>
                                    <Th>최종수정자</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.content.map(
                                    (v: LeppaMarketItemsData) => {
                                        return (
                                            <tr key={v.itemId}>
                                                <TitleTd
                                                    onClick={() => {
                                                        navigate(
                                                            `/leppa-market-items/${v.itemId}`,
                                                        );
                                                    }}
                                                >
                                                    {v.title}
                                                </TitleTd>
                                                <TitleTd>
                                                    <img
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        src={v.mainImage}
                                                        alt="상품이미지"
                                                        onClick={() =>
                                                            clickImg(
                                                                v.mainImage,
                                                            )
                                                        }
                                                    />
                                                </TitleTd>
                                                <Td>
                                                    {v.discount > 0 ? (
                                                        <>
                                                            <OriginPrice>
                                                                {`${v.price.toLocaleString()}원`}
                                                            </OriginPrice>
                                                            <DiscountPrice>
                                                                {`${(
                                                                    (v.price *
                                                                        (100 -
                                                                            v.discount)) /
                                                                    100
                                                                ).toLocaleString()}원`}
                                                            </DiscountPrice>
                                                        </>
                                                    ) : (
                                                        <Price>{`${v.price.toLocaleString()}원`}</Price>
                                                    )}
                                                </Td>
                                                <Td>
                                                    {v.showYn === 'Y' && (
                                                        <VisibilityRounded
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                setItemVisibility(
                                                                    v,
                                                                    'N',
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                    {v.showYn === 'N' && (
                                                        <VisibilityOffRounded
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                setItemVisibility(
                                                                    v,
                                                                    'Y',
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </Td>
                                                <Td>{v.categoryNm}</Td>
                                                <Td>{v.modifiedDate}</Td>
                                                <Td>{v.modifiedId}</Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                    {data?.data.ResultData.totalElements > 0 && (
                        <Pagination
                            itemsCount={data?.data.ResultData.totalElements}
                            pageSize={pageSize}
                            currPage={currListPage}
                            setList={setList}
                        />
                    )}
                    <Dialog
                        open={inputProductPopup}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            상품 등록
                        </DialogTitle>
                        <DialogContent>
                            <Select
                                labelId="category"
                                id="category"
                                value={categoryId}
                                label="카테고리"
                                onChange={(e) => {
                                    setCategoryId(Number(e.target.value));
                                }}
                            >
                                {categoryData &&
                                    categoryData.data.ResultData.content.map(
                                        (v: LeppaMarketCategoryData) => (
                                            <MenuItem key={v.sn} value={v.sn}>
                                                {v.name}
                                            </MenuItem>
                                        ),
                                    )}
                            </Select>
                            <TextField
                                id="standard-basic"
                                label="상품명"
                                fullWidth
                                style={{ margin: '8px 0px 8px 0px' }}
                                InputLabelProps={{ shrink: true }}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />
                            <TextField
                                id="standard-basic"
                                label="상품설명"
                                fullWidth
                                style={{ margin: '8px 0px 8px 0px' }}
                                InputLabelProps={{ shrink: true }}
                                value={description}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}
                            />
                            <TextField
                                id="standard-basic"
                                label="상품가격"
                                type="number"
                                fullWidth
                                style={{ margin: '8px 0px 8px 0px' }}
                                InputLabelProps={{ shrink: true }}
                                value={price}
                                onChange={(e) => {
                                    setPrice(Number(e.target.value));
                                }}
                            />
                            <TextField
                                id="standard-basic"
                                label="할인율"
                                type="number"
                                fullWidth
                                style={{ margin: '8px 0px 8px 0px' }}
                                InputLabelProps={{ shrink: true }}
                                value={discount}
                                onChange={(e) => {
                                    setDiscount(Number(e.target.value));
                                }}
                            />
                            <OptionArea>
                                <TextField
                                    id="fileName"
                                    label="메인 이미지"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={mainFileName}
                                    onChange={(e) => {
                                        setMainFileName(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadRounded />}
                                >
                                    Upload
                                    <input
                                        id={'file-main'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="file"
                                        accept="image/gif,image/jpeg,image/png,image/jpg"
                                        onChange={handleChangeImage}
                                        multiple
                                    />
                                </Button>
                            </OptionArea>
                            <OptionArea>
                                <TextField
                                    id="fileName"
                                    label="옵션그룹명"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={optionGroupTitle}
                                    onChange={(e) => {
                                        setOptionGroupTitle(e.target.value);
                                    }}
                                />
                                <CustomButton onClick={handleAddOption}>
                                    추가
                                </CustomButton>
                            </OptionArea>
                            {inputFields.map((v, idx) => (
                                <OptionArea key={idx}>
                                    <TextField
                                        id="title"
                                        name="title"
                                        label="옵션명"
                                        style={{
                                            margin: '8px 0px 8px 0px',
                                            width: '46%',
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        value={v.title}
                                        onChange={(e) => {
                                            handleNewOptionChange(idx, e);
                                        }}
                                    />
                                    <TextField
                                        id="price"
                                        name="price"
                                        label="추가금액"
                                        style={{
                                            margin: '8px 0px 8px 0px',
                                            width: '46%',
                                            marginLeft: '1%',
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        value={v.price}
                                        onChange={(e) => {
                                            handleNewOptionChange(idx, e);
                                        }}
                                    />
                                    <CustomButton
                                        onClick={() => {
                                            handleOptionDelete(v);
                                        }}
                                    >
                                        삭제
                                    </CustomButton>
                                </OptionArea>
                            ))}
                            <OptionArea>
                                <TextField
                                    id="topFileName"
                                    label="상단이미지"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={topFileName}
                                    onChange={(e) => {
                                        setTopFileName(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadRounded />}
                                >
                                    Upload
                                    <input
                                        id={'file-top'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="file"
                                        accept="image/gif,image/jpeg,image/png,image/jpg"
                                        onChange={handleChangeTopImage}
                                        multiple
                                    />
                                </Button>
                            </OptionArea>
                            <OptionArea>
                                <TextField
                                    id="detailFileName"
                                    label="상세이미지"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={detailFileName}
                                    onChange={(e) => {
                                        setDetailFileName(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadRounded />}
                                >
                                    Upload
                                    <input
                                        id={'file-detail'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="file"
                                        accept="image/gif,image/jpeg,image/png,image/jpg"
                                        onChange={handleChangeDetailImage}
                                        multiple
                                    />
                                </Button>
                            </OptionArea>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleOptionSubmit}
                                color="primary"
                            >
                                등록
                            </Button>
                            <Button onClick={handleClose} color="primary">
                                닫기
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            )}
        </>
    );
};

export default React.memo(LeppaMarketItems);
