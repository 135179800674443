import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd';
import {
    CloudUploadRounded,
    DeleteRounded,
    EditRounded,
    VisibilityOffRounded,
    VisibilityRounded,
} from '@mui/icons-material';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteIntegrationAdvertisement,
    InsertIntegrationAdvertisement,
    modifyIntegrationAdvertisement,
    setIntegrationAdvertisementDragOrderChange,
    setIntegrationAdvertisementVisibility,
    useGetIntegrationAdvertisement,
} from '../../hooks/ad/useIntegrationAd';
import {
    BannerType,
    IntegrationAdvertisement,
} from '../typings/integrationAd/types';
import styled from '@emotion/styled';
import { checkImageExt } from '../../utils/util';
import Items from '../../assets/Vector.png';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div`
    clear: both;
`;

const Table = styled.div`
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.div`
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
    width: 10%;
`;

const Td = styled.div`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
    width: 10%;
`;

const SelectBox = styled.select`
    height: 42px;
    padding: 10px 12px;
    border: 1px solid #ebeef0;
    border-radius: 8px;
    -webkit-appearance: none; /* 크롬 화살표 없애기 */
    -moz-appearance: none; /* 파이어폭스 화살표 없애기 */
    appearance: none; /* 화살표 없애기 */
    background: url(${Items}) no-repeat right 15px center;
    option {
        padding: 10px 12px;
    }
    margin-right: 10px;
`;
const AdBanner = () => {
    const [
        integrationAdvertisementDialogOpen,
        setIntegrationAdvertisementDialogOpen,
    ] = useState(false);
    const queryClient = useQueryClient();

    const [bannerType, setBannerType] = useState<BannerType>('MAIN_TOP');
    const [editSn, setEditSn] = useState(0);
    const [company, setCompany] = useState('');
    const [url, setUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const [eventTitle, setEventTitle] = useState('');
    const [description, setDescription] = useState('');
    const [bgColor, setBgColor] = useState('');

    const { isLoading, isError, data, refetch } =
        useGetIntegrationAdvertisement(bannerType);

    const setIntegrationAdvertisementDragOrderChangeMutation = useMutation(
        setIntegrationAdvertisementDragOrderChange,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useGetIntegrationAdvertisement');
            },
        },
    );

    const setIntegrationAdvertisementVisibilityMutation = useMutation(
        setIntegrationAdvertisementVisibility,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useGetIntegrationAdvertisement');
            },
        },
    );

    const deleteIntegrationAdvertisementMutation = useMutation(
        deleteIntegrationAdvertisement,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useGetIntegrationAdvertisement');
            },
        },
    );

    const modifyIntegrationAdvertisementMutation = useMutation(
        modifyIntegrationAdvertisement,
        {
            onSuccess: (res) => {
                if (res.data.ResultCode === 1) {
                    handleClose();
                    queryClient.invalidateQueries(
                        'useGetIntegrationAdvertisement',
                    );
                } else if (res.data.ResultCode === 38) {
                    alert('클릭 이벤트 구분명은 중복될 수 없습니다.');
                }
            },
        },
    );

    const InsertIntegrationAdvertisementMutation = useMutation(
        InsertIntegrationAdvertisement,
        {
            onSuccess: (res) => {
                if (res.data.ResultCode === 1) {
                    handleClose();
                    queryClient.invalidateQueries(
                        'useGetIntegrationAdvertisement',
                    );
                } else if (res.data.ResultCode === 38) {
                    alert('클릭 이벤트 구분명은 중복될 수 없습니다.');
                }
            },
        },
    );

    useEffect(() => {
        refetch();
    }, [bannerType, refetch]);

    const handleClickOpen = () => {
        setIntegrationAdvertisementDialogOpen(true);
    };

    const setAdVisibility = (
        v: IntegrationAdvertisement,
        isVisibility: 'Y' | 'N',
    ) => {
        let msg = '광고 배너를 노출하시겠습니까?';
        if (isVisibility === 'N') {
            msg = '광고 배너를 비노출하시겠습니까?';
        }
        if (window.confirm(msg)) {
            let params = {
                bannerId: v.bannerId,
                isVisibility,
            };
            setIntegrationAdvertisementVisibilityMutation.mutate(params);
        }
    };
    const editClick = (v: IntegrationAdvertisement) => {
        setIntegrationAdvertisementDialogOpen(true);
        setEditSn(v.bannerId);
        setCompany(v.company);
        setUrl(v.url);
        setDescription(v.description);
        setEventTitle(v.eventTitle);
        setBgColor(v.bgColor);
    };

    const deleteClick = (v: IntegrationAdvertisement) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteIntegrationAdvertisementMutation.mutate(v.bannerId);
        }
    };

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            const { source, destination, draggableId } = result;
            if (!destination || source.index === destination.index) {
                return;
            }
            let params = {
                bannerId: parseInt(draggableId.replace('ad-integration-', '')),
                targetOrdering: destination.index,
                bannerType,
            };
            setIntegrationAdvertisementDragOrderChangeMutation.mutate(params);
        },
        [setIntegrationAdvertisementDragOrderChangeMutation, bannerType],
    );

    const handleClose = () => {
        setIntegrationAdvertisementDialogOpen(false);
        setEditSn(0);
        setCompany('');
        setFileName('');
        setUrl('');
        setDescription('');
        setBgColor('');
        setEventTitle('');
    };

    const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
        let fileName = e.target.value;
        if (!checkImageExt(fileName.substring(fileName.lastIndexOf('.') + 1))) {
            alert('이미지 파일만 첨부 가능합니다.');
            return false;
        }
        setFileName(fileName.substring(fileName.lastIndexOf('\\') + 1));
    };
    const onModifyIntegrationAdvertisement = () => {
        if (company === '') {
            alert('회사명을 입력해 주세요.');
            return false;
        }
        if (eventTitle === '') {
            alert('구분명을 입력해 주세요.');
            return false;
        }
        let params = {
            bannerId: editSn,
            company,
            url,
            description,
            fileName,
            bgColor,
            bannerType,
            eventTitle,
        };
        modifyIntegrationAdvertisementMutation.mutate(params);
    };

    const onSubmitIntegrationAdvertisement = () => {
        if (company === '') {
            alert('회사명을 입력해 주세요.');
            return false;
        }
        if (eventTitle === '') {
            alert('구분명을 입력해 주세요.');
            return false;
        }
        if (fileName === '') {
            alert('이미지를 선택해 주세요.');
            return false;
        }
        let params = {
            bannerType,
            company,
            url,
            description,
            eventTitle,
            bgColor,
        };
        InsertIntegrationAdvertisementMutation.mutate(params);
    };

    const handleBannerType = (e: ChangeEvent<HTMLSelectElement>) => {
        setBannerType(e.target.value as BannerType);
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        통합 광고 배너 관리
                    </Title>
                    <div style={{ float: 'right' }}>
                        <SelectBox
                            style={{
                                width: '200px',
                            }}
                            value={bannerType}
                            onChange={handleBannerType}
                        >
                            <option value="MAIN_TOP">
                                [메인화면] 상단배너A
                            </option>
                            <option value="MAIN_MIDDLE">
                                [메인화면] 중간배너B
                            </option>
                            <option value="MAIN_BOTTOM">
                                [메인화면] 하단배너C
                            </option>
                            <option value="RESERVATION_SEARCH">
                                [레저예약] 검색결과C
                            </option>
                            <option value="COMPANY_DETAIL">
                                [레저예약] 상세페이지B
                            </option>
                            <option value="LEPPA_MARKET_TOP">
                                [레빠마켓] 상단배너A
                            </option>
                            <option value="LEPPA_MARKET_MIDDLE">
                                [레빠마켓] 중간배너B
                            </option>
                            <option value="LEPPA_MARKET_DETAIL">
                                [레빠마켓] 상세페이지B
                            </option>
                            <option value="PLAY_TOP">
                                [레빠플레이] 상단배너D
                            </option>
                            <option value="PLAY_MIDDLE">
                                [레빠플레이] 중간배너B
                            </option>
                            <option value="PLAY_DETAIL">
                                [레빠플레이] 재생화면C
                            </option>
                        </SelectBox>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="integration-advertisement">
                            {(provided) => (
                                <TableArea
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <Table>
                                        <div style={{ display: 'flex' }}>
                                            <Th>회사명</Th>
                                            <Th>구분명</Th>
                                            <Th style={{ width: '30%' }}>
                                                <div style={{ width: '300px' }}>
                                                    배너이미지
                                                </div>
                                            </Th>
                                            <Th>수정자</Th>
                                            <Th>수정일</Th>
                                            <Th>배경색</Th>
                                            <Th>설명</Th>
                                            <Th>노출여부</Th>
                                            <Th>&nbsp;</Th>
                                        </div>
                                        <div>
                                            {data?.data.ResultData.content.map(
                                                (v) => (
                                                    <Draggable
                                                        draggableId={`ad-integration-${v.bannerId}`}
                                                        index={v.ordering}
                                                        key={`ad-integration-${v.bannerId}`}
                                                    >
                                                        {(
                                                            provided,
                                                            snapshot,
                                                        ) => {
                                                            return (
                                                                <div
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                >
                                                                    <div
                                                                        key={
                                                                            v.bannerId
                                                                        }
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Td>
                                                                            {
                                                                                v.company
                                                                            }
                                                                        </Td>
                                                                        <Td>
                                                                            {
                                                                                v.eventTitle
                                                                            }
                                                                        </Td>
                                                                        <Td
                                                                            style={{
                                                                                textAlign:
                                                                                    'left',
                                                                                width: '30%',
                                                                            }}
                                                                        >
                                                                            <a
                                                                                rel="noreferrer noopener nofollow"
                                                                                href={
                                                                                    v.url
                                                                                }
                                                                                target="_blank"
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        v.imageUrl
                                                                                    }
                                                                                    width="300px"
                                                                                    alt="배너 이미지"
                                                                                />
                                                                            </a>
                                                                        </Td>
                                                                        <Td>
                                                                            {
                                                                                v.modifiedId
                                                                            }
                                                                        </Td>
                                                                        <Td>
                                                                            {
                                                                                v.modifiedDate
                                                                            }
                                                                        </Td>
                                                                        <Td>
                                                                            {
                                                                                v.bgColor
                                                                            }
                                                                        </Td>
                                                                        <Td>
                                                                            {
                                                                                v.description
                                                                            }
                                                                        </Td>
                                                                        <Td>
                                                                            {v.showYn ===
                                                                                'Y' && (
                                                                                <VisibilityRounded
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setAdVisibility(
                                                                                            v,
                                                                                            'N',
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {v.showYn ===
                                                                                'N' && (
                                                                                <VisibilityOffRounded
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setAdVisibility(
                                                                                            v,
                                                                                            'Y',
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Td>
                                                                        <Td>
                                                                            <EditRounded
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => {
                                                                                    editClick(
                                                                                        v,
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <DeleteRounded
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => {
                                                                                    deleteClick(
                                                                                        v,
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Td>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }}
                                                    </Draggable>
                                                ),
                                            )}
                                        </div>
                                    </Table>
                                </TableArea>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Dialog
                        open={integrationAdvertisementDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            광고 배너
                            {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    id="standard-basic"
                                    label="회사명"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={company}
                                    onChange={(e) => {
                                        setCompany(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="eventTitle"
                                    label="클릭 이벤트 구분명"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={eventTitle}
                                    onChange={(e) => {
                                        setEventTitle(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="bgColor"
                                    label="배경색"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={bgColor}
                                    onChange={(e) => {
                                        setBgColor(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="description"
                                    label="설명"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="url"
                                    label="URL"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={url}
                                    onChange={(e) => {
                                        setUrl(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="fileName"
                                    label="이미지"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={fileName}
                                    onChange={(e) => {
                                        setFileName(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadRounded />}
                                >
                                    Upload
                                    <input
                                        id={'integration-ad-file-input'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="file"
                                        accept="image/gif,image/jpeg,image/png,image/jpg"
                                        onChange={handleChangeImage}
                                    />
                                </Button>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button
                                    onClick={onModifyIntegrationAdvertisement}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button
                                    onClick={onSubmitIntegrationAdvertisement}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
};
export default AdBanner;
