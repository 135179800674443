import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import { useQuery } from 'react-query';
import {
    AddNewsRequest,
    ModifyNewsRequest,
} from '../../components/typings/news/types';

const getNewsApi = (boardGbnCd: string, page: number, size: number) => {
    return axiosApiInstance.get(
        apiUrl +
            '/news?size=' +
            size +
            '&page=' +
            (page - 1) +
            '&boardGbnCd=' +
            boardGbnCd,
    );
};

export const useNewsListQuery = (
    boardGbnCd: string,
    page: number,
    size: number,
) =>
    useQuery(['useNewsListQuery'], () => getNewsApi(boardGbnCd, page, size), {
        retry: 3,
        keepPreviousData: true,
    });

export const deleteNews = (sn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/news',
        method: 'DELETE',
        data: {
            sn,
        },
    });

export const addNews = (params: AddNewsRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/news',
        method: 'POST',
        data: params,
    });

export const modNews = (params: ModifyNewsRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/news',
        method: 'PUT',
        data: params,
    });
