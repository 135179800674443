import React from 'react';
// @ts-ignore
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { LockOpenRounded, LockRounded } from '@mui/icons-material';
import { useParams } from 'react-router';
import {
    blockMember,
    useProductReportDetailQuery,
} from '../../hooks/product/useProduct';
import { getProductStatusCdNm } from '../../utils/util';
import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import {
    ProductReportDetailData,
    ProductReportImageData,
} from '../typings/product/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Table = styled.table`
    border-collapse: collapse;
    font-size: 16px;
    margin-bottom: 10px;
`;

const Th = styled.th`
    width: 140px;
    background: #398fce;
    padding: 10px;
    color: #fff;
    font-weight: 400;
`;

const Td = styled.td`
    width: 285px;
    background: #fff;
    padding: 10px 15px;
`;

const ProductDetail = () => {
    const { id } = useParams();

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useProductReportDetailQuery(
        Number(id),
    );

    const blockMemberMutation = useMutation(blockMember, {
        onSuccess: () => {
            queryClient.invalidateQueries('useProductReportDetailQuery');
        },
    });

    const lockClick = (v: ProductReportDetailData) => {
        let confirmMessage = '차단하시겠습니까?';
        if (data?.data.ResultData.memberStatus === '차단') {
            confirmMessage = '차단을 해제하시겠습니까?';
        }
        if (window.confirm(confirmMessage)) {
            blockMemberMutation.mutate(v.sellerMemberNo);
        }
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Table id="product-detail" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <Th>상품제목</Th>
                                <Td colSpan={5}>
                                    {data?.data.ResultData.productTitle}
                                </Td>
                            </tr>
                            <tr>
                                <Th>판매자 닉네임</Th>
                                <Td>{data?.data.ResultData.sellerNickName}</Td>
                                <Th>상품등록일</Th>
                                <Td>{data?.data.ResultData.productRegDt}</Td>
                            </tr>
                            <tr>
                                <Th>판매 회원 상태</Th>
                                <Td>
                                    {data?.data.ResultData.memberStatus}회원
                                </Td>
                                <Th>판매회원상태변경</Th>
                                <Td>
                                    {data?.data.ResultData.memberStatus ===
                                        '정상' && (
                                        <>
                                            <span>회원 차단 : </span>
                                            <LockRounded
                                                style={{
                                                    cursor: 'pointer',
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={() => {
                                                    lockClick(
                                                        data?.data.ResultData,
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                    {data?.data.ResultData.memberStatus ===
                                        '차단' && (
                                        <>
                                            <span>회원 차단 해제 : </span>
                                            <LockOpenRounded
                                                style={{
                                                    cursor: 'pointer',
                                                    verticalAlign: 'middle',
                                                }}
                                                onClick={() => {
                                                    lockClick(
                                                        data.data.ResultData,
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                </Td>
                            </tr>
                            <tr>
                                <Th>상품상태</Th>
                                <Td>
                                    {getProductStatusCdNm(
                                        data?.data.ResultData.status,
                                    )}
                                </Td>
                                <Th>상품노출상태</Th>
                                <Td>
                                    {getProductStatusCdNm(
                                        data?.data.ResultData.showStatus,
                                    )}
                                </Td>
                            </tr>
                            <tr>
                                <Th>상품 설명</Th>
                                <Td colSpan={5}>
                                    {data?.data.ResultData.description}
                                </Td>
                            </tr>
                            <tr>
                                <Th>신고자 닉네임</Th>
                                <Td>{data?.data.ResultData.reportNickName}</Td>
                                <Th>신고일</Th>
                                <Td>{data?.data.ResultData.regDt}</Td>
                            </tr>
                            <tr>
                                <Th>신고내용</Th>
                                <Td colSpan={5}>
                                    {data?.data.ResultData.message}
                                </Td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="slide-container">
                        {data?.data.ResultData.reportImages.length > 0 && (
                            <Slide autoplay={false}>
                                {data?.data.ResultData.reportImages.map(
                                    (v: ProductReportImageData) => (
                                        <img
                                            key={v.sn}
                                            style={{ width: '100%' }}
                                            src={v.imgPath}
                                            alt="신고이미지"
                                        />
                                    ),
                                )}
                            </Slide>
                        )}
                    </div>
                </Base>
            )}
        </>
    );
};

export default React.memo(ProductDetail);
