import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import { useQuery } from 'react-query';

const getReservationApi = (currListPage: number, pageSize: number) => {
    return axiosApiInstance.get(
        apiUrl +
            '/api/v1/web/reservation-list?size=' +
            pageSize +
            '&page=' +
            (currListPage - 1),
    );
};

export const useReservationListQuery = (
    currListPage: number,
    pageSize: number,
) =>
    useQuery(
        ['useReservationListQuery'],
        () => getReservationApi(currListPage, pageSize),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const pushAlarmManager = (bargSn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/web/manager/reservation-push',
        method: 'POST',
        data: {
            bargSn,
        },
    });
