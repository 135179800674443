import React, { useEffect, useState } from 'react';
import Pagination from '../common/Pagination';
import { DeleteRounded } from '@mui/icons-material';
import { useMutation, useQueryClient } from 'react-query';
import styled from '@emotion/styled';
import {
    deleteProductReportQuery,
    useProductReportListQuery,
} from '../../hooks/product/useProduct';
import { useNavigate } from 'react-router';
import { ProductReportData } from '../typings/product/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const ReportTitle = styled.div`
    cursor: pointer;
`;

const Product = () => {
    const pageSize = 10;
    const [currListPage, setCurrListPage] = useState(1);
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const { isLoading, isError, data, refetch } = useProductReportListQuery(
        currListPage,
        pageSize,
    );
    const deleteProductReportMutation = useMutation(deleteProductReportQuery, {
        onSuccess: () => {
            queryClient.invalidateQueries('useProductReportListQuery');
        },
    });

    useEffect(() => {
        refetch();
    }, [currListPage, refetch]);

    const setList = (page: number) => {
        setCurrListPage(page);
    };

    const deleteClick = (v: ProductReportData) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteProductReportMutation.mutate(v.reportSn);
        }
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title>
                        상품 신고 리스트(
                        <span className="list">
                            {data?.data.ResultData.totalElements}
                        </span>
                        )
                    </Title>
                    <TableArea className="table_area">
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '500px' }} />
                                <col style={{ width: '225px' }} />
                                <col style={{ width: '125px' }} />
                                <col style={{ width: '125px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>제목</Th>
                                    <Th>신고일</Th>
                                    <Th>판매자</Th>
                                    <Th>신고자</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.content.map(
                                    (v: ProductReportData) => {
                                        return (
                                            <tr key={v.reportSn}>
                                                <Td
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    <ReportTitle
                                                        onClick={() => {
                                                            navigate(
                                                                `/product/${v.reportSn}`,
                                                            );
                                                        }}
                                                    >
                                                        {v.productTitle}
                                                    </ReportTitle>
                                                </Td>
                                                <Td>{v.regDt}</Td>
                                                <Td>{v.sellerNickName}</Td>
                                                <Td>{v.reportNickName}</Td>
                                                <Td>
                                                    {v.showStatus ===
                                                        'sale' && (
                                                        <DeleteRounded
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                deleteClick(v);
                                                            }}
                                                        />
                                                    )}
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                        {data?.data.ResultData.totalElements > 0 && (
                            <Pagination
                                itemsCount={data?.data.ResultData.totalElements}
                                pageSize={pageSize}
                                currPage={currListPage}
                                setList={setList}
                            />
                        )}
                    </TableArea>
                </Base>
            )}
        </>
    );
};

export default React.memo(Product);
