import React, { ChangeEvent, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    CloudUploadRounded,
    DeleteRounded,
    EditRounded,
} from '@mui/icons-material';

import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteFreeMarketTopCategory,
    insertFreeMarketTopCategory,
    modifyFreeMarketTopCategory,
    setFreeMarketTopCategoryOrderChange,
    useFreeMarketCategoryQuery,
    useFreeMarketTopCategoryQuery,
} from '../../hooks/category/useCategory';
import { checkImageExt } from '../../utils/util';
import {
    FreeMarketCategory,
    FreeMarketTopCategoryData,
} from '../typings/category/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

function FreeMarketTopCategory() {
    const [
        freeMarketTopCategoryDialogOpen,
        setFreeMarketTopCategoryDialogOpen,
    ] = useState(false);
    const [categoryId, setCategoryId] = useState(0);
    const [editSn, setEditSn] = useState(0);
    const [fileName, setFileName] = useState('');

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useFreeMarketTopCategoryQuery();
    const {
        isLoading: freeMarketCategoryLoading,
        isError: freeMarketCategoryError,
        data: freeMarketCategoryData,
    } = useFreeMarketCategoryQuery();

    const insertFreeMarketTopCategoryMutation = useMutation(
        insertFreeMarketTopCategory,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useFreeMarketTopCategoryQuery');
            },
        },
    );

    const modifyFreeMarketTopCategoryMutation = useMutation(
        modifyFreeMarketTopCategory,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useFreeMarketTopCategoryQuery');
            },
        },
    );

    const deleteFreeMarketTopCategoryMutation = useMutation(
        deleteFreeMarketTopCategory,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useFreeMarketTopCategoryQuery');
            },
        },
    );

    const setFreeMarketTopCategoryOrderChangeMutation = useMutation(
        setFreeMarketTopCategoryOrderChange,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useFreeMarketTopCategoryQuery');
            },
        },
    );

    const handleClose = () => {
        setFreeMarketTopCategoryDialogOpen(false);
        setFileName('');
        setCategoryId(0);
        setEditSn(0);
    };

    const editClick = (v: FreeMarketTopCategoryData) => {
        setFreeMarketTopCategoryDialogOpen(true);
        setEditSn(v.categoryMappingId);
        setCategoryId(v.categoryId);
    };

    const handleClickOpen = () => {
        setFreeMarketTopCategoryDialogOpen(true);
    };
    const deleteClick = (v: FreeMarketTopCategoryData) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteFreeMarketTopCategoryMutation.mutate(v.categoryMappingId);
        }
    };
    const onSubmitFreeMarketTopCategory = () => {
        if (categoryId === 0) {
            alert('카테고리를 선택해 주세요.');
            return false;
        }
        if (fileName === '') {
            alert('이미지를 선택해 주세요.');
            return false;
        }
        insertFreeMarketTopCategoryMutation.mutate(categoryId);
        handleClose();
    };
    const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
        let fileName = e.target.value;
        if (!checkImageExt(fileName.substring(fileName.lastIndexOf('.') + 1))) {
            alert('이미지 파일만 첨부 가능합니다.');
            return false;
        }
        setFileName(fileName.substring(fileName.lastIndexOf('\\') + 1));
    };

    const orderChange = (v: FreeMarketTopCategoryData, arrow: string) => {
        let orgIndex = data?.data.ResultData.indexOf(v);
        let targetId;
        if (arrow === 'U') {
            if (orgIndex === 0) {
                return false;
            }
            targetId = data?.data.ResultData[orgIndex - 1].categoryMappingId;
        } else {
            if (orgIndex === data?.data.ResultData.length - 1) {
                return false;
            }
            targetId = data?.data.ResultData[orgIndex + 1].categoryMappingId;
        }
        if (
            window.confirm(
                '레저프리마켓 상단 카테고리 순서를 변경하시겠습니까?',
            )
        ) {
            let params = {
                categoryMappingId: v.categoryMappingId,
                targetId,
            };
            setFreeMarketTopCategoryOrderChangeMutation.mutate(params);
        }
    };

    const onModifyFreeMarketTopCategory = () => {
        let params = {
            categoryMappingId: editSn,
            categoryId,
            fileName,
        };
        modifyFreeMarketTopCategoryMutation.mutate(params);
        handleClose();
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        프리마켓 상단 카테고리 관리
                    </Title>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '400px' }} />
                                <col style={{ width: '250px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>카테고리 명</Th>
                                    <Th>카테고리이미지</Th>
                                    <Th>순서변경</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.map(
                                    (v: FreeMarketTopCategoryData) => {
                                        return (
                                            <tr key={v.categoryMappingId}>
                                                <Td>{v.name}</Td>
                                                <Td>
                                                    <img
                                                        src={v.imgPath}
                                                        alt="배너 이미지"
                                                        width="100"
                                                    />
                                                </Td>
                                                <Td>
                                                    <ArrowUpwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'U');
                                                        }}
                                                    />
                                                    <ArrowDownwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'D');
                                                        }}
                                                    />
                                                </Td>
                                                <Td>
                                                    <EditRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            editClick(v);
                                                        }}
                                                    />
                                                    <DeleteRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            deleteClick(v);
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                    <Dialog
                        open={freeMarketTopCategoryDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            프리마켓 상단 카테고리 {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <Select
                                    labelId="category"
                                    id="category"
                                    value={categoryId}
                                    label="카테고리"
                                    onChange={(e) => {
                                        setCategoryId(Number(e.target.value));
                                    }}
                                >
                                    {!freeMarketCategoryLoading &&
                                        !freeMarketCategoryError &&
                                        freeMarketCategoryData?.data.ResultData.map(
                                            (v: FreeMarketCategory) => {
                                                return (
                                                    <MenuItem
                                                        key={v.categoryId}
                                                        value={v.categoryId}
                                                    >
                                                        {v.name}
                                                    </MenuItem>
                                                );
                                            },
                                        )}
                                </Select>
                                <TextField
                                    id="fileName"
                                    label="이미지"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={fileName}
                                    onChange={(e) => {
                                        setFileName(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadRounded />}
                                >
                                    Upload
                                    <input
                                        id={'file-input'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="file"
                                        accept="image/gif,image/jpeg,image/png,image/jpg"
                                        onChange={handleChangeImage}
                                    />
                                </Button>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button
                                    onClick={onModifyFreeMarketTopCategory}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button
                                    onClick={onSubmitFreeMarketTopCategory}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
}

export default React.memo(FreeMarketTopCategory);
