import React, { useState } from 'react';
import { Button } from '@mui/material';
import { DeleteRounded } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteCompanyTopList,
    insertCompanyTopList,
    useCompanyNameListQuery,
    useCompanyTopQuery,
} from '../../hooks/ad/useAd';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { CompanyTypeList } from '../typings/ad/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const AutoCompleteArea = styled.div`
    width: 150px;
    margin-right: 10px;
`;

const SearchArea = styled.div`
    align-items: center;
    display: flex;
    float: right;
`;

function AdCompanyTopList() {
    const [companyId, setCompanyId] = useState(0);
    const [companyName, setCompanyName] = useState('');

    const queryClient = useQueryClient();
    const { data: nameList } = useCompanyNameListQuery();

    const { isLoading, isError, data } = useCompanyTopQuery();

    const deleteCompanyTopListMutation = useMutation(deleteCompanyTopList, {
        onSuccess: () => {
            queryClient.invalidateQueries('useCompanyTopQuery');
            queryClient.invalidateQueries('useCompanyNameListQuery');
        },
    });

    const insertCompanyTopListMutation = useMutation(insertCompanyTopList, {
        onSuccess: () => {
            queryClient.invalidateQueries('useCompanyTopQuery');
            queryClient.invalidateQueries('useCompanyNameListQuery');
        },
    });

    const deleteClick = (v: CompanyTypeList) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteCompanyTopListMutation.mutate(v.topListId);
        }
    };
    const handleInsert = () => {
        if (companyId === 0) {
            alert('사업장명을 입력해 주세요.');
            return false;
        }
        setCompanyId(0);
        setCompanyName('');
        insertCompanyTopListMutation.mutate(companyId);
    };

    const handleOnSelect = (v: CompanyTypeList) => {
        setCompanyId(v.id);
    };

    const handleOnSearch = (string: string) => {
        setCompanyName(string);
    };

    const handleOnClear = () => {
        setCompanyName('');
        setCompanyId(0);
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        핫 플레이스 관리
                    </Title>
                    <SearchArea>
                        <div>
                            {nameList && (
                                <AutoCompleteArea>
                                    <ReactSearchAutocomplete
                                        items={nameList.data.ResultData}
                                        onSelect={handleOnSelect}
                                        onSearch={handleOnSearch}
                                        onClear={handleOnClear}
                                        showIcon={false}
                                        inputSearchString={companyName}
                                        styling={{
                                            borderRadius: '0',
                                            height: '37px',
                                        }}
                                    />
                                </AutoCompleteArea>
                            )}
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleInsert}
                            >
                                등록
                            </Button>
                        </div>
                    </SearchArea>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '360px' }} />
                                <col style={{ width: '200px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '90px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>사업장명</Th>
                                    <Th>대표자 이름</Th>
                                    <Th>연락처</Th>
                                    <Th>사업장주소</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.map(
                                    (v: CompanyTypeList) => {
                                        return (
                                            <tr key={v.id}>
                                                <Td>{v.name}</Td>
                                                <Td>{v.mngNm}</Td>
                                                <Td>{v.mngTelNo}</Td>
                                                <Td>{v.addr}</Td>
                                                <Td>
                                                    <DeleteRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            deleteClick(v);
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                </Base>
            )}
        </>
    );
}

export default React.memo(AdCompanyTopList);
