import React, { ChangeEvent, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import {
    checkImageExt,
    parseNoticeType,
    parseStringDateTime,
} from '../../utils/util';
import {
    CloudUpload,
    DeleteRounded,
    EditRounded,
    VisibilityOffRounded,
    VisibilityRounded,
} from '@mui/icons-material';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteNotice,
    insertNotice,
    modifyNotice,
    setNoticeVisibility,
    useNoticeListQuery,
} from '../../hooks/notice/useNotice';
import styled from '@emotion/styled';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko';
import { format, parse } from 'date-fns';
import { NoticeData } from '../typings/notice/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
    float: left;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const Notice = () => {
    const [noticeDialogOpen, setNoticeDialogOpen] = useState(false);
    const [noticeSn, setNoticeSn] = useState(0);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [fileName, setFileName] = useState('');
    const [noticeType, setNoticeType] = useState('USER');
    const [url, setUrl] = useState('');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useNoticeListQuery();

    const insertNoticeMutation = useMutation(insertNotice, {
        onSuccess: () => {
            queryClient.invalidateQueries('useNoticeListQuery');
        },
    });

    const modifyNoticeMutation = useMutation(modifyNotice, {
        onSuccess: () => {
            queryClient.invalidateQueries('useNoticeListQuery');
        },
    });

    const deleteNoticeMutation = useMutation(deleteNotice, {
        onSuccess: () => {
            queryClient.invalidateQueries('useNoticeListQuery');
        },
    });

    const setNoticeVisibilityMutation = useMutation(setNoticeVisibility, {
        onSuccess: () => {
            queryClient.invalidateQueries('useNoticeListQuery');
        },
    });

    const handleClose = () => {
        setNoticeDialogOpen(false);
        setNoticeSn(0);
        setTitle('');
        setMessage('');
        setFileName('');
        setUrl('');
        setStartDate(null);
        setEndDate(null);
        setNoticeType('USER');
    };

    const handleClickOpen = () => {
        setNoticeDialogOpen(true);
    };

    const editClick = (v: NoticeData) => {
        setNoticeDialogOpen(true);
        setNoticeSn(v.sn);
        setTitle(v.title);
        setMessage(v.message);
        setUrl(v.url);
        setStartDate(
            v.startDate !== ''
                ? parse(v.startDate, 'yyyyMMddHHmmss', new Date())
                : null,
        );
        setEndDate(
            v.endDate !== ''
                ? parse(v.endDate, 'yyyyMMddHHmmss', new Date())
                : null,
        );
        setNoticeType(v.noticeType);
    };

    const deleteClick = (v: NoticeData) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteNoticeMutation.mutate(v.sn);
        }
    };
    const onModifyNotice = () => {
        if (title === '') {
            alert('제목을 입력해 주세요.');
            return false;
        }
        let params = {
            noticeSn,
            title,
            message,
            fileName,
            url,
            noticeType,
            startDate:
                startDate !== null ? format(startDate, 'yyyyMMddHH0000') : '',
            endDate: endDate !== null ? format(endDate, 'yyyyMMddHH0000') : '',
        };
        modifyNoticeMutation.mutate(params);
        handleClose();
    };
    const onSubmitNotice = () => {
        if (title === '') {
            alert('제목을 입력해 주세요.');
            return false;
        }
        if (fileName === '') {
            alert('이미지를 선택해 주세요.');
            return false;
        }
        let params = {
            title,
            message,
            url,
            noticeType,
            startDate:
                startDate !== null ? format(startDate, 'yyyyMMddHH0000') : '',
            endDate: endDate !== null ? format(endDate, 'yyyyMMddHH0000') : '',
        };
        insertNoticeMutation.mutate(params);

        handleClose();
    };
    const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
        let fileName = e.target.value;
        if (!checkImageExt(fileName.substring(fileName.lastIndexOf('.') + 1))) {
            alert('이미지 파일만 첨부 가능합니다.');
            return false;
        }
        setFileName(fileName.substring(fileName.lastIndexOf('\\') + 1));
    };

    const setAdVisibility = (v: NoticeData, isVisibility: string) => {
        let msg = '공지사항을 노출하시겠습니까?';
        if (isVisibility === 'N') {
            msg = '공지사항을 비노출하시겠습니까?';
        }
        if (window.confirm(msg)) {
            let params = {
                noticeSn: v.sn,
                showYn: isVisibility,
            };
            setNoticeVisibilityMutation.mutate(params);
        }
    };

    const noticeTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setNoticeType(e.target.value);
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title>공지사항 관리</Title>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '105px' }} />
                                <col style={{ width: '50px' }} />
                                <col style={{ width: '120px' }} />
                                <col style={{ width: '275px' }} />
                                <col style={{ width: '125px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '100px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>제목</Th>
                                    <Th>구분</Th>
                                    <Th>내용</Th>
                                    <Th>이미지</Th>
                                    <Th>등록자</Th>
                                    <Th>등록일</Th>
                                    <Th>노출일</Th>
                                    <Th>노출여부</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.length > 0 &&
                                    data?.data.ResultData.map(
                                        (v: NoticeData) => {
                                            return (
                                                <tr key={v.sn}>
                                                    <Td>{v.title}</Td>
                                                    <Td>
                                                        {parseNoticeType(
                                                            v.noticeType,
                                                        )}
                                                    </Td>
                                                    <Td>{v.message}</Td>
                                                    <Td>
                                                        <a
                                                            rel="noreferrer noopener nofollow"
                                                            href={v.url}
                                                            target="_blank"
                                                        >
                                                            <img
                                                                src={v.imgUrl}
                                                                width="300px"
                                                                alt="배너 이미지"
                                                            />
                                                        </a>
                                                    </Td>
                                                    <Td>{v.createdId}</Td>
                                                    <Td>{v.regDt}</Td>
                                                    <Td>
                                                        {v.startDate
                                                            ? `시작일:${parseStringDateTime(
                                                                  v.startDate,
                                                              )}
                                                          `
                                                            : ''}
                                                        {v.endDate
                                                            ? `종료일:${parseStringDateTime(
                                                                  v.endDate,
                                                              )}`
                                                            : ''}
                                                    </Td>
                                                    <Td>
                                                        {v.showYn === 'Y' && (
                                                            <VisibilityRounded
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    setAdVisibility(
                                                                        v,
                                                                        'N',
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                        {v.showYn === 'N' && (
                                                            <VisibilityOffRounded
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    setAdVisibility(
                                                                        v,
                                                                        'Y',
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </Td>
                                                    <Td>
                                                        <EditRounded
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                editClick(v);
                                                            }}
                                                        />
                                                        <DeleteRounded
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                deleteClick(v);
                                                            }}
                                                        />
                                                    </Td>
                                                </tr>
                                            );
                                        },
                                    )}
                            </tbody>
                        </Table>
                    </TableArea>
                    <Dialog
                        open={noticeDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            공지사항
                            {noticeSn === 0 && ' 등록'}
                            {noticeSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    id="standard-basic"
                                    label="제목"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="standard-basic"
                                    label="내용"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="standard-basic"
                                    label="URL"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={url}
                                    onChange={(e) => {
                                        setUrl(e.target.value);
                                    }}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={koLocale}
                                >
                                    <DateTimePicker
                                        renderInput={(props) => (
                                            <TextField {...props} />
                                        )}
                                        label="노출시작일"
                                        value={startDate}
                                        inputFormat={'yyyy-MM-dd HH:00'}
                                        onChange={(newValue) => {
                                            setStartDate(newValue);
                                        }}
                                        views={['year', 'day', 'hours']}
                                        minDate={new Date()}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={koLocale}
                                >
                                    <DateTimePicker
                                        renderInput={(props) => (
                                            <TextField {...props} />
                                        )}
                                        label="노출종료일"
                                        value={endDate}
                                        inputFormat={'yyyy-MM-dd HH:00'}
                                        onChange={(newValue) => {
                                            setEndDate(newValue);
                                        }}
                                        views={['year', 'day', 'hours']}
                                        minDate={new Date()}
                                    />
                                </LocalizationProvider>
                                <FormLabel component="legend">
                                    공지구분
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="공지 구분"
                                    name="row-radio-buttons-group"
                                    onChange={noticeTypeChange}
                                    value={noticeType}
                                >
                                    <FormControlLabel
                                        value="USER"
                                        control={<Radio />}
                                        label="사용자"
                                    />
                                    <FormControlLabel
                                        value="BIZ"
                                        control={<Radio />}
                                        label="사업자"
                                    />
                                </RadioGroup>
                                <TextField
                                    id="fileName"
                                    label="이미지"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={fileName}
                                    onChange={(e) => {
                                        setFileName(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUpload />}
                                >
                                    Upload
                                    <input
                                        id={'file-notice-input'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="file"
                                        accept="image/gif,image/jpeg,image/png,image/jpg"
                                        onChange={handleChangeImage}
                                    />
                                </Button>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {noticeSn > 0 && (
                                <Button
                                    onClick={onModifyNotice}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {noticeSn === 0 && (
                                <Button
                                    onClick={onSubmitNotice}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
};

export default React.memo(Notice);
