import React from 'react';
// @ts-ignore
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import { useReviewReportDetailQuery } from '../../hooks/leppa-market/useLeppaMarket';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Table = styled.table`
    border-collapse: collapse;
    font-size: 16px;
    margin-bottom: 10px;
`;

const Th = styled.th`
    width: 140px;
    background: #398fce;
    padding: 10px;
    color: #fff;
    font-weight: 400;
`;

const Td = styled.td`
    width: 285px;
    background: #fff;
    padding: 10px 15px;
`;

const ReviewReportDetail = () => {
    const { id } = useParams();

    const { isLoading, isError, data } = useReviewReportDetailQuery(Number(id));

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Table id="product-detail" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <Th>신고 내용</Th>
                                <Td colSpan={5}>
                                    {data?.data.ResultData.reportContent}
                                </Td>
                            </tr>
                            <tr>
                                <Th>신고자 닉네임</Th>
                                <Td>{data?.data.ResultData.reporterName}</Td>
                                <Th>신고일</Th>
                                <Td>{data?.data.ResultData.reportRegDt}</Td>
                            </tr>
                            <tr>
                                <Th>리뷰 내용</Th>
                                <Td colSpan={5}>
                                    {data?.data.ResultData.reviewContent}
                                </Td>
                            </tr>
                            <tr>
                                <Th>신고자 닉네임</Th>
                                <Td colSpan={5}>
                                    {data?.data.ResultData.writerName}
                                </Td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="slide-container">
                        {data?.data.ResultData.reportImages &&
                            data?.data.ResultData.reportImages.length > 0 && (
                                <Slide autoplay={false}>
                                    {data?.data.ResultData.reportImages.map(
                                        (v: string, i: number) => (
                                            <img
                                                key={i}
                                                style={{ width: '100%' }}
                                                src={v}
                                                alt="신고이미지"
                                            />
                                        ),
                                    )}
                                </Slide>
                            )}
                    </div>
                </Base>
            )}
        </>
    );
};

export default React.memo(ReviewReportDetail);
