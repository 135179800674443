import React, { ReactElement, useEffect } from 'react';
import { BrowserRouter, Route, useLocation } from 'react-router-dom';
import { Navigate, Routes } from 'react-router';
import { isAuth } from './utils/common';
import { useRecoilState } from 'recoil';
import { loginState } from './components/common/atom';
import Nav from './components/common/Nav';
import LoginForm from './components/common/LoginForm';
import Header from './components/common/Header';
import Enterprise from './components/enterprise/Enterprise';
import Reservation from './components/reservation/Reservation';
import News from './components/news/News';
import Notice from './components/notice/Notice';
import ManagerNotice from './components/notice/ManagerNotice';
import Product from './components/product/Product';
import ProductDetail from './components/product/ProductDetail';
import AdAlarm from './components/ad/AdAlarm';
import AdChat from './components/ad/AdChat';
import AdSmart from './components/ad/AdSmart';
import AdMarketTop from './components/ad/AdMarketTop';
import MainKeyword from './components/keyword/MainKeyword';
import ReservationKeyword from './components/keyword/ReservationKeyword';
import ReservationCompanyTagTop from './components/keyword/ReservationCompanyTagTop';
import Category from './components/category/Category';
import FreeMarketTopCategory from './components/category/FreeMarketTopCategory';
import CompanyRecommend from './components/keyword/CompanyRecommend';
import LeppaMarketOrders from './components/leppa-market/LeppaMarketOrders';
import EnterpriseDetail from './components/enterprise/EnterpriseDetail';
import LeppaMarketCategory from './components/category/LeppaMarketCategory';
import EnterpriseLog from './components/enterprise/EnterpriseLog';
import LeppaMarketItems from './components/leppa-market/LeppaMarketItems';
import LeppaMarketItemDetail from './components/leppa-market/LeppaMarketItemDetail';
import AdCompanyTopList from './components/ad/AdCompanyTopList';
import AdMarketingPush from './components/ad/AdMarketingPush';
import Users from './components/user/Users';
import ReservationMiddleClassification from './components/keyword/ReservationMiddleClassification';
import CompanyLargeCategory from './components/keyword/CompanyLargeCategory';
import ProductClassification from './components/keyword/ProductClassification';
import MappingProductClassification from './components/keyword/MappingProductClassification';
import LeppaPlayType from './components/leppa-play/LeppaPlayType';
import LeppaPlay from './components/leppa-play/LeppaPlay';
import LeppaPlayRecommendVideo from './components/leppa-play/LeppaPlayRecommendVideo';
import ToothJamContent from './components/toothJamContent/ToothJamContent';
import ReviewReport from './components/leppa-market/ReviewReport';
import ReviewReportDetail from './components/leppa-market/ReviewReportDetail';
import AdText from './components/ad/AdText';
import Point from './components/point/Point';
import CompanyPromotion from './components/ad/CompanyPromotion';
import AdBanner from './components/ad/AdBanner';

function App() {
    const [id, setId] = useRecoilState(loginState);
    useEffect(() => {
        const userInfoTxt = window.localStorage.getItem('userInfo');
        let userInfo = userInfoTxt ? JSON.parse(userInfoTxt) : null;
        if (!userInfo || !userInfo.accessToken) {
            return;
        }
        setId(userInfo.id);
    }, [setId]);
    return (
        <BrowserRouter>
            {id && (
                <>
                    <Header />
                    <Nav />
                </>
            )}
            <Routes>
                <Route
                    path="/login"
                    element={!id ? <LoginForm /> : <Navigate to="/" />}
                />
                <Route
                    path="/"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <Enterprise />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/reservation"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <Reservation />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/news"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <News />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/notice"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <Notice />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/manager_notice"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <ManagerNotice />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/product"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <Product />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/product/:id"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <ProductDetail />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/ad-alarm"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <AdAlarm />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/ad-banner"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <AdBanner />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/ad-chat"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <AdChat />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/ad-text"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <AdText />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/ad-smart"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <AdSmart />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/ad-market-top"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <AdMarketTop />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/main-keyword"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <MainKeyword />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/reservation-keyword"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <ReservationKeyword />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/reservation-keyword-top"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <ReservationCompanyTagTop />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/category"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <Category />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/free-market-top-category"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <FreeMarketTopCategory />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/company-recommend"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <CompanyRecommend />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/leppa-market-orders"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <LeppaMarketOrders />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/enterprise/:id"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <EnterpriseDetail />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/leppa-market-category"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <LeppaMarketCategory />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/company-log"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <EnterpriseLog />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/leppa-market-items"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <LeppaMarketItems />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/leppa-market-items/:id"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <LeppaMarketItemDetail />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/company-top-list"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <AdCompanyTopList />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/company-promotion-list"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <CompanyPromotion />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/ad-marketing-push"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <AdMarketingPush />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/users"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <Users />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/reservation-middle-classification"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <ReservationMiddleClassification />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/reservation-large-category"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <CompanyLargeCategory />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/product-classification"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <ProductClassification />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/product-classification-mapping"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <MappingProductClassification />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/leppa-play-type"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <LeppaPlayType />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/leppa-play"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <LeppaPlay />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/leppa-play-recommend"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <LeppaPlayRecommendVideo />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/tooth-jam-content"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <ToothJamContent />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/review-report"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <ReviewReport />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/review-report/:id"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <ReviewReportDetail />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/point"
                    index
                    element={
                        <RequireAuth setId={setId}>
                            <Point />
                        </RequireAuth>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

type Props = {
    children: ReactElement;
    setId: (id: string) => void;
};

function RequireAuth({ children, setId }: Props) {
    let auth = isAuth();
    let location = useLocation();
    useEffect(() => {
        if (!auth) {
            setId('');
        }
    }, [auth, setId]);
    if (!auth) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
}

export default App;
