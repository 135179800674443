import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import {
    setLeppaMarketItemAddImage,
    setLeppaMarketItemDeleteImage,
    setLeppaMarketItemImageOrderChange,
    setLeppaMarketItemOptionsOrderChange,
    updateItemInfo,
    useLeppaMarketItemDetailQuery,
} from '../../hooks/leppa-market/useLeppaMarket';
import { useParams } from 'react-router';
import ImageList from '../common/ImageList';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    CloudUploadRounded,
} from '@mui/icons-material';
import { checkImageExt } from '../../utils/util';
import { useLeppaMarketCategoryQuery } from '../../hooks/category/useCategory';
import { LeppaMarketItemOptionData } from '../typings/leppa-market/response';
import {
    ExistingOptionData,
    LeppaMarketImageType,
} from '../typings/leppa-market/types';
import { LeppaMarketCategoryData } from '../typings/category/response';

const Container = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TitleArea = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Row = styled.div`
    display: flex;
    border: 1px solid rgb(238, 238, 238);

    & + & {
        border-top: 0;
    }
`;
const Label = styled.div`
    width: 20%;
    background: rgb(251, 251, 251);
    padding: 10px;
    text-align: center;
`;
const Value = styled.div`
    width: 80%;
    padding: 10px;
`;
const OptionLabel = styled.div`
    width: 20%;
    padding: 10px;
    text-align: center;
`;
const OptionValue = styled.div`
    width: 30%;
    padding: 10px;
`;
const MenuTitleArea = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
`;
const MenuTitle = styled.h3``;
const ButtonArea = styled.div``;

const CustomButton = styled.div`
    background: #404040;
    padding: 10px 15px;
    border-radius: 3px;
    border: 1px solid #404040;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
    min-width: 28px;
`;

const ArrowArea = styled.div`
    display: flex;
    align-items: center;
`;

const OptionArea = styled.div`
    display: flex;
    align-items: center;
`;

const LeppaMarketItemDetail = () => {
    const { id } = useParams();
    const [fileName, setFileName] = useState('');
    const [topImageUploadPopup, setTopImageUploadPopup] = useState(false);
    const [detailImageUploadPopup, setDetailImageUploadPopup] = useState(false);
    const [basicInfoPopup, setBasicInfoPopup] = useState(false);
    const [optionInfoPopup, setOptionInfoPopup] = useState(false);
    const [inputFields, setInputFields] = useState<Array<ExistingOptionData>>(
        [],
    );
    const [existOptions, setExistOptions] = useState<Array<ExistingOptionData>>(
        [],
    );
    const [optionGroupTitle, setOptionGroupTitle] = useState('');
    const [optionGroupId, setOptionGroupId] = useState(0);
    const [newFieldId, setNewFieldId] = useState(1);

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useLeppaMarketItemDetailQuery(
        id as string,
    );
    const { data: categoryData } = useLeppaMarketCategoryQuery();

    const setLeppaMarketItemImageOrderChangeMutation = useMutation(
        setLeppaMarketItemImageOrderChange,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useLeppaMarketItemDetailQuery');
            },
        },
    );

    const setLeppaMarketItemDeleteImageMutation = useMutation(
        setLeppaMarketItemDeleteImage,
        {
            onSuccess: () => {
                setTimeout(() => {
                    queryClient.invalidateQueries(
                        'useLeppaMarketItemDetailQuery',
                    );
                }, 100);
            },
        },
    );

    const setLeppaMarketItemAddImageMutation = useMutation(
        setLeppaMarketItemAddImage,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useLeppaMarketItemDetailQuery');
            },
        },
    );

    const updateItemInfoMutation = useMutation(updateItemInfo, {
        onSuccess: () => {
            queryClient.invalidateQueries('useLeppaMarketItemDetailQuery');
        },
    });

    const setLeppaMarketItemOptionsOrderChangeMutation = useMutation(
        setLeppaMarketItemOptionsOrderChange,
        {
            onSuccess: () => {
                setTimeout(() => {
                    queryClient.invalidateQueries(
                        'useLeppaMarketItemDetailQuery',
                    );
                }, 100);
            },
        },
    );

    const orgTitle = data?.data.ResultData.title;
    const orgDescription = data?.data.ResultData.description;
    const orgPrice = data?.data.ResultData.price;
    const orgDiscount = data?.data.ResultData.discount;
    const orgCategoryId = data?.data.ResultData.categorySn;
    const optionGroups = data?.data.ResultData.optionGroups;

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [categoryId, setCategoryId] = useState(0);
    const [deleteOptionIds, setDeleteOptionIds] = useState<Array<string>>([]);
    const leppaMarketTopExtArr = [
        'png',
        'jpeg',
        'jpg',
        'gif',
        'PNG',
        'JPEG',
        'JPG',
        'GIF',
        'mp4',
        'MP4',
    ];

    useEffect(() => {
        if (orgTitle && orgDescription) {
            setTitle(orgTitle);
            setDescription(orgDescription);
            setPrice(orgPrice);
            setDiscount(orgDiscount);
            setCategoryId(orgCategoryId);
            setOptionGroupTitle(optionGroups[0].title);
            setOptionGroupId(optionGroups[0].optionGroupId);
            let existOptionArr = [];
            for (
                let i = 0, cnt = optionGroups[0].options.length;
                i < cnt;
                i++
            ) {
                existOptionArr.push({
                    optionId: optionGroups[0].options[i].optionId,
                    title: optionGroups[0].options[i].title,
                    price: optionGroups[0].options[i].addPrice,
                });
            }
            setExistOptions(existOptionArr);
        }
    }, [
        orgTitle,
        orgDescription,
        orgCategoryId,
        orgDiscount,
        orgPrice,
        optionGroups,
    ]);

    const handlePopupClose = () => {
        setTopImageUploadPopup(false);
        setDetailImageUploadPopup(false);
        setBasicInfoPopup(false);
        setOptionInfoPopup(false);
        setDeleteOptionIds([]);
        setInputFields([]);
        setFileName('');
    };

    const checkTopFileExt = (str: string) => {
        return leppaMarketTopExtArr.includes(str);
    };

    const handleChangeMultipleImage = () => {
        let fileInput = document.getElementById(
            'file-detail',
        ) as HTMLInputElement;
        if (fileInput && fileInput.files) {
            let files = fileInput.files;
            let fileName = [];

            for (let i = 0, cnt = files.length; i < cnt; i++) {
                if (
                    !checkTopFileExt(
                        files[i].name.substring(
                            files[i].name.lastIndexOf('.') + 1,
                        ),
                    )
                ) {
                    alert('이미지, mp4영상 파일만 첨부 가능합니다.');
                    setFileName('');
                    return false;
                }
                fileName.push(files[i].name);
            }

            setFileName(fileName.join());
        }
    };

    const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
        let fileName = e.target.value;
        if (!checkImageExt(fileName.substring(fileName.lastIndexOf('.') + 1))) {
            alert('이미지 파일만 첨부 가능합니다.');
            return false;
        }
        setFileName(fileName.substring(fileName.lastIndexOf('\\') + 1));
    };

    const handleTopDetailImageUpload = () => {
        if (fileName === '') {
            alert('파일을 선택해 주세요.');
            return false;
        }
        let params = {
            itemId: data?.data.ResultData.itemId,
            imageType: topImageUploadPopup
                ? 'TOP'
                : ('DETAIL' as LeppaMarketImageType),
        };
        setLeppaMarketItemAddImageMutation.mutate(params);
        handlePopupClose();
    };

    const handleBasicInfoModify = () => {
        let params = {
            itemId: data?.data.ResultData.itemId,
            fileName,
            title,
            description,
            price,
            discount,
            categorySn: categoryId,
        };
        updateItemInfoMutation.mutate(params);
        handlePopupClose();
    };

    const handleOptionModify = () => {
        let modifyOptions = {
            optionTitle: optionGroupTitle,
            optionGroupId,
            options: existOptions,
        };
        let params = {
            itemId: data?.data.ResultData.itemId,
            fileName,
            title,
            description,
            price,
            discount,
            categorySn: categoryId,
            modifyOptions: JSON.stringify(modifyOptions),
            deleteOptions: '',
            addOptions: '',
        };
        if (deleteOptionIds.length > 0) {
            params.deleteOptions = deleteOptionIds.join();
        }
        if (inputFields.length > 0) {
            let addOptions = {
                optionGroupId,
                options: inputFields,
            };
            params.addOptions = JSON.stringify(addOptions);
        }
        updateItemInfoMutation.mutate(params);
        handlePopupClose();
    };

    const handleExistOptionChange = (
        idx: number,
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        let existOption = [...existOptions];
        let key = e.target.name;
        let value: string | number = e.target.value;
        if (key === 'price') {
            if (isNaN(Number(value))) {
                value = 0;
            }
        }
        existOption[idx][key] = value;
        setExistOptions(existOption);
    };

    const handleNewOptionChange = (
        idx: number,
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        let newOption = [...inputFields];
        let key = e.target.name;
        let value: string | number = e.target.value;
        if (key === 'price') {
            if (isNaN(Number(value))) {
                value = 0;
            }
        }
        newOption[idx][key] = value;
        setInputFields(newOption);
    };

    const optionOrderChange = (v: LeppaMarketItemOptionData, arrow: string) => {
        let orgIndex = data?.data.ResultData.optionGroups[0].options.indexOf(v);
        let targetId;
        if (arrow === 'U') {
            if (orgIndex === 0) {
                return false;
            }
            targetId =
                data?.data.ResultData.optionGroups[0].options[orgIndex - 1]
                    .optionId;
        } else {
            if (
                orgIndex ===
                data?.data.ResultData.optionGroups[0].options.length - 1
            ) {
                return false;
            }
            targetId =
                data?.data.ResultData.optionGroups[0].options[orgIndex + 1]
                    .optionId;
        }
        if (window.confirm('옵션 순서를 변경하시겠습니까?')) {
            let params = {
                id: v.optionId,
                targetId,
            };
            setLeppaMarketItemOptionsOrderChangeMutation.mutate(params);
        }
    };

    const handleOptionDelete = (v: ExistingOptionData) => {
        let existOption = [...existOptions];
        let newOption = [...inputFields];
        let target = existOption;
        let deleteOptionIdArr: string[] = [...deleteOptionIds];
        let targetOptionId = v.optionId;

        if (isNaN(Number(targetOptionId))) {
            target = newOption;
        }

        const itemToFind = target.find(function (item) {
            return item.optionId === targetOptionId;
        });
        const idx = target.indexOf(itemToFind as ExistingOptionData);
        if (idx > -1) {
            target.splice(idx, 1);
        }
        if (isNaN(Number(targetOptionId))) {
            setInputFields(target);
        } else {
            deleteOptionIdArr.push(targetOptionId);
            setExistOptions(target);
            setDeleteOptionIds(deleteOptionIdArr);
        }
    };

    const handleAddOption = () => {
        let newField = {
            optionId: `newField_${newFieldId}`,
            title: '',
            price: 0,
        };
        setNewFieldId(newFieldId + 1);
        setInputFields([...inputFields, newField]);
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Container>
                    <TitleArea>
                        <Title>{data?.data.ResultData.title}</Title>
                    </TitleArea>
                    <MenuTitleArea>
                        <MenuTitle>기본정보</MenuTitle>
                        <ButtonArea>
                            <CustomButton
                                onClick={() => {
                                    setBasicInfoPopup(true);
                                }}
                            >
                                수정
                            </CustomButton>
                        </ButtonArea>
                    </MenuTitleArea>
                    <Row>
                        <Label>메인 이미지</Label>
                        <Value>
                            <img
                                style={{ width: '100%' }}
                                src={data?.data.ResultData.mainImage}
                                alt="메인이미지"
                            />
                        </Value>
                    </Row>
                    <Row>
                        <Label>상품 설명</Label>
                        <Value>{data?.data.ResultData.description}</Value>
                    </Row>
                    <Row>
                        <Label>상품가격</Label>
                        <Value>{`${data?.data.ResultData.price.toLocaleString()}원`}</Value>
                    </Row>
                    <Row>
                        <Label>할인율</Label>
                        <Value>{`${data?.data.ResultData.discount}%`}</Value>
                    </Row>
                    <MenuTitleArea>
                        <MenuTitle>상품옵션</MenuTitle>
                        <ButtonArea>
                            <CustomButton
                                onClick={() => {
                                    setOptionInfoPopup(true);
                                }}
                            >
                                수정
                            </CustomButton>
                        </ButtonArea>
                    </MenuTitleArea>
                    <Row>
                        <Label>옵션그룹명</Label>
                        <Value>
                            {data?.data.ResultData.optionGroups[0].title}
                        </Value>
                    </Row>
                    <Row>
                        <Label>옵션상세</Label>
                        <Value>
                            {data?.data.ResultData.optionGroups[0].options.map(
                                (v: LeppaMarketItemOptionData) => (
                                    <Row key={v.optionId}>
                                        <OptionLabel>옵션명</OptionLabel>
                                        <OptionValue>{v.title}</OptionValue>
                                        <OptionLabel>추가금액</OptionLabel>
                                        <OptionValue>{`${v.addPrice.toLocaleString()}원`}</OptionValue>
                                        <ArrowArea>
                                            <ArrowUpwardRounded
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    optionOrderChange(v, 'U');
                                                }}
                                            />
                                            <ArrowDownwardRounded
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    optionOrderChange(v, 'D');
                                                }}
                                            />
                                        </ArrowArea>
                                    </Row>
                                ),
                            )}
                        </Value>
                    </Row>
                    <MenuTitleArea>
                        <MenuTitle>상단이미지</MenuTitle>
                        <ButtonArea>
                            <CustomButton
                                onClick={() => {
                                    setTopImageUploadPopup(true);
                                }}
                            >
                                상단이미지 추가
                            </CustomButton>
                        </ButtonArea>
                    </MenuTitleArea>
                    <ImageList
                        itemId={data?.data.ResultData.itemId}
                        images={data?.data.ResultData.topImages}
                        orderChangeMutation={
                            setLeppaMarketItemImageOrderChangeMutation
                        }
                        deleteImageMutation={
                            setLeppaMarketItemDeleteImageMutation
                        }
                        imageType={'TOP'}
                    />
                    <MenuTitleArea>
                        <MenuTitle>상세이미지</MenuTitle>
                        <ButtonArea>
                            <CustomButton
                                onClick={() => setDetailImageUploadPopup(true)}
                            >
                                상세이미지 추가
                            </CustomButton>
                        </ButtonArea>
                    </MenuTitleArea>
                    <ImageList
                        itemId={data?.data.ResultData.itemId}
                        images={data?.data.ResultData.detailImages}
                        orderChangeMutation={
                            setLeppaMarketItemImageOrderChangeMutation
                        }
                        deleteImageMutation={
                            setLeppaMarketItemDeleteImageMutation
                        }
                        imageType={'DETAIL'}
                    />

                    <Dialog
                        open={topImageUploadPopup || detailImageUploadPopup}
                        onClose={handlePopupClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            {topImageUploadPopup && '상단이미지 업로드'}
                            {detailImageUploadPopup && '상세이미지 업로드'}
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                id="fileName"
                                label="이미지"
                                style={{ margin: '8px 0px 8px 0px' }}
                                InputLabelProps={{ shrink: true }}
                                disabled
                                fullWidth
                                value={fileName}
                                onChange={(e) => {
                                    setFileName(e.target.value);
                                }}
                            />
                            <Button
                                variant="contained"
                                component="label"
                                startIcon={<CloudUploadRounded />}
                            >
                                Upload
                                <input
                                    id={'file-detail'}
                                    style={{ display: 'none' }}
                                    type="file"
                                    name="file"
                                    accept={
                                        topImageUploadPopup
                                            ? 'image/gif,image/jpeg,image/png,image/jpg,video/mp4'
                                            : 'image/gif,image/jpeg,image/png,image/jpg'
                                    }
                                    onChange={handleChangeMultipleImage}
                                    multiple
                                />
                            </Button>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleTopDetailImageUpload}
                                color="primary"
                            >
                                등록
                            </Button>
                            <Button onClick={handlePopupClose} color="primary">
                                닫기
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={optionInfoPopup}
                        onClose={handlePopupClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            상품 옵션 수정
                        </DialogTitle>
                        <DialogContent>
                            <OptionArea>
                                <TextField
                                    id="fileName"
                                    label="옵션그룹명"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={optionGroupTitle}
                                    onChange={(e) => {
                                        setOptionGroupTitle(e.target.value);
                                    }}
                                />
                                <CustomButton onClick={handleAddOption}>
                                    추가
                                </CustomButton>
                            </OptionArea>
                            {existOptions.map((v, idx) => (
                                <OptionArea key={v.optionId}>
                                    <TextField
                                        id="title"
                                        name="title"
                                        label="옵션명"
                                        style={{ margin: '8px 0px 8px 0px' }}
                                        InputLabelProps={{ shrink: true }}
                                        value={v.title}
                                        onChange={(e) => {
                                            handleExistOptionChange(idx, e);
                                        }}
                                    />
                                    <TextField
                                        id="price"
                                        name="price"
                                        label="추가금액"
                                        style={{ margin: '8px 0px 8px 0px' }}
                                        InputLabelProps={{ shrink: true }}
                                        value={v.price}
                                        onChange={(e) => {
                                            handleExistOptionChange(idx, e);
                                        }}
                                    />
                                    <CustomButton
                                        onClick={() => {
                                            handleOptionDelete(v);
                                        }}
                                    >
                                        삭제
                                    </CustomButton>
                                </OptionArea>
                            ))}
                            {inputFields.map((v, idx) => (
                                <OptionArea key={idx}>
                                    <TextField
                                        id="title"
                                        name="title"
                                        label="옵션명"
                                        style={{ margin: '8px 0px 8px 0px' }}
                                        InputLabelProps={{ shrink: true }}
                                        value={v.title}
                                        onChange={(e) => {
                                            handleNewOptionChange(idx, e);
                                        }}
                                    />
                                    <TextField
                                        id="price"
                                        name="price"
                                        label="추가금액"
                                        style={{ margin: '8px 0px 8px 0px' }}
                                        InputLabelProps={{ shrink: true }}
                                        value={v.price}
                                        onChange={(e) => {
                                            handleNewOptionChange(idx, e);
                                        }}
                                    />
                                    <CustomButton
                                        onClick={() => {
                                            handleOptionDelete(v);
                                        }}
                                    >
                                        삭제
                                    </CustomButton>
                                </OptionArea>
                            ))}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleOptionModify}
                                color="primary"
                            >
                                수정
                            </Button>
                            <Button onClick={handlePopupClose} color="primary">
                                닫기
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={basicInfoPopup}
                        onClose={handlePopupClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            기본정보 수정
                        </DialogTitle>
                        <DialogContent>
                            <Select
                                labelId="category"
                                id="category"
                                value={categoryId}
                                label="카테고리"
                                onChange={(e) => {
                                    setCategoryId(Number(e.target.value));
                                }}
                            >
                                {categoryData &&
                                    categoryData.data.ResultData.content.map(
                                        (v: LeppaMarketCategoryData) => (
                                            <MenuItem key={v.sn} value={v.sn}>
                                                {v.name}
                                            </MenuItem>
                                        ),
                                    )}
                            </Select>
                            <TextField
                                id="standard-basic"
                                label="상품명"
                                fullWidth
                                style={{ margin: '8px 0px 8px 0px' }}
                                InputLabelProps={{ shrink: true }}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />
                            <TextField
                                id="standard-basic"
                                label="상품설명"
                                fullWidth
                                style={{ margin: '8px 0px 8px 0px' }}
                                InputLabelProps={{ shrink: true }}
                                value={description}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}
                            />
                            <TextField
                                id="standard-basic"
                                label="상품가격"
                                fullWidth
                                style={{ margin: '8px 0px 8px 0px' }}
                                InputLabelProps={{ shrink: true }}
                                value={price}
                                onChange={(e) => {
                                    setPrice(Number(e.target.value));
                                }}
                            />
                            <TextField
                                id="standard-basic"
                                label="할인율"
                                type="number"
                                fullWidth
                                style={{ margin: '8px 0px 8px 0px' }}
                                InputLabelProps={{ shrink: true }}
                                value={discount}
                                onChange={(e) => {
                                    setDiscount(Number(e.target.value));
                                }}
                            />
                            <TextField
                                id="fileName"
                                label="이미지"
                                style={{ margin: '8px 0px 8px 0px' }}
                                InputLabelProps={{ shrink: true }}
                                disabled
                                fullWidth
                                value={fileName}
                                onChange={(e) => {
                                    setFileName(e.target.value);
                                }}
                            />
                            <Button
                                variant="contained"
                                component="label"
                                startIcon={<CloudUploadRounded />}
                            >
                                Upload
                                <input
                                    id={'file-main'}
                                    style={{ display: 'none' }}
                                    type="file"
                                    name="file"
                                    accept="image/gif,image/jpeg,image/png,image/jpg"
                                    onChange={handleChangeImage}
                                    multiple
                                />
                            </Button>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleBasicInfoModify}
                                color="primary"
                            >
                                수정
                            </Button>
                            <Button onClick={handlePopupClose} color="primary">
                                닫기
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            )}
        </>
    );
};

export default React.memo(LeppaMarketItemDetail);
