import axios, { AxiosResponse } from 'axios';

export const apiUrl = process.env.REACT_APP_API_SERVER_URL;

export const onSilentRefresh = () => {
    axios({
        url: apiUrl + '/join/silent-refresh',
        method: 'POST',
    })
        .then(onLoginSuccess)
        .catch(() => {
            window.localStorage.removeItem('userInfo');
            window.location.href = '/login';
        });
};
export const onLoginSuccess = (response: AxiosResponse) => {
    if (response.data.ResultCode === 0) {
        window.localStorage.removeItem('userInfo');
        window.location.href = '/login';
        return;
    }
    const userInfoTxt = window.localStorage.getItem('userInfo');
    let userInfo = userInfoTxt ? JSON.parse(userInfoTxt) : null;
    if (userInfo && userInfo.id) {
        userInfo.accessToken = response.headers['access-token'];
        userInfo.accessTokenExpiresIn = parseInt(
            response.headers['access-token-expires-in'],
        );
        userInfo.authority = response.data.ResultData.authority;
    } else {
        userInfo = {
            id: response.data.ResultData.id,
            accessToken: response.headers['access-token'],
            accessTokenExpiresIn: parseInt(
                response.headers['access-token-expires-in'],
            ),
            authority: response.data.ResultData.authority,
        };
    }
    window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
};

export const isAuth = () => {
    const userInfoTxt = window.localStorage.getItem('userInfo');
    let userInfo = userInfoTxt ? JSON.parse(userInfoTxt) : null;
    if (!userInfo) {
        onSilentRefresh();
        return;
    }
    return !!userInfo.id;
};
