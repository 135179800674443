import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import { useQuery } from 'react-query';
import {
    InsertManagerNoticeRequest,
    InsertNoticeRequest,
    ModifyManagerNoticeRequest,
    ModifyNoticeRequest,
    NoticeVisibilityRequest,
} from '../../components/typings/notice/types';

const getNoticeApi = () => {
    return axiosApiInstance.get(apiUrl + '/api/notice');
};

export const useNoticeListQuery = () =>
    useQuery(['useNoticeListQuery'], () => getNoticeApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const insertNotice = (params: InsertNoticeRequest) => {
    let formData = new FormData();
    const file = document.getElementById(
        'file-notice-input',
    ) as HTMLInputElement;
    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    formData.append('title', params.title);
    formData.append('message', params.message);
    formData.append('url', params.url);
    formData.append('noticeType', params.noticeType);
    formData.append('startDate', params.startDate);
    formData.append('endDate', params.endDate);
    return axiosApiInstance.post(apiUrl + '/api/notice', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const modifyNotice = (params: ModifyNoticeRequest) => {
    let formData = new FormData();
    console.log(params);
    if (params.fileName !== '') {
        const file = document.getElementById(
            'file-notice-input',
        ) as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append('sn', params.noticeSn.toString());
    formData.append('title', params.title);
    formData.append('message', params.message);
    formData.append('url', params.url);
    formData.append('noticeType', params.noticeType);
    let startDate = '0';
    if (params.startDate !== '') {
        startDate = params.startDate;
    }
    let endDate = '0';
    if (params.endDate !== '') {
        endDate = params.endDate;
    }
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    return axiosApiInstance.put(apiUrl + '/api/notice', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const deleteNotice = (noticeSn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/notice',
        method: 'DELETE',
        data: {
            noticeSn,
        },
    });

export const setNoticeVisibility = (params: NoticeVisibilityRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/notice/visibility',
        method: 'PUT',
        data: params,
    });

const getManagerNoticeApi = () => {
    return axiosApiInstance.get(apiUrl + '/api/v1/biz/notice');
};

export const useManagerNoticeListQuery = () =>
    useQuery(['useManagerNoticeListQuery'], () => getManagerNoticeApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const insertManagerNotice = (params: InsertManagerNoticeRequest) => {
    let formData = new FormData();
    const file = document.getElementById(
        'file-notice-input',
    ) as HTMLInputElement;
    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    formData.append('title', params.title);
    formData.append('message', params.message);
    formData.append('url', params.url);
    formData.append('regId', params.userId);
    return axiosApiInstance.post(apiUrl + '/api/v1/biz/notice', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const modifyManagerNotice = (params: ModifyManagerNoticeRequest) => {
    let formData = new FormData();
    if (params.fileName !== '') {
        const file = document.getElementById(
            'file-notice-input',
        ) as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append('noticeId', params.noticeSn.toString());
    formData.append('title', params.title);
    formData.append('message', params.message);
    formData.append('url', params.url);
    formData.append('regId', params.userId);
    return axiosApiInstance.put(apiUrl + '/api/v1/biz/notice', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const deleteManagerNotice = (noticeId: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/notice',
        method: 'DELETE',
        data: {
            noticeId,
        },
    });

export const sendManagerNoticePush = (noticeId: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/notice/send',
        method: 'POST',
        data: {
            noticeId,
        },
    });
