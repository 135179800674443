import React from 'react';
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { useMutation, useQueryClient } from 'react-query';
import {
    setLargeCategoryOrderChange,
    useLargeCategoryQuery,
} from '../../hooks/keyword/useKeyword';

import styled from '@emotion/styled';
import { LargeCategoryData } from '../typings/keyword/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const CompanyLargeCategory = () => {
    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useLargeCategoryQuery();

    const setLargeCategoryOrderChangeMutation = useMutation(
        setLargeCategoryOrderChange,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useLargeCategoryQuery');
            },
        },
    );

    const orderChange = (v: LargeCategoryData, arrow: string) => {
        let orgIndex = data?.data.ResultData.indexOf(v);
        let targetId;
        if (arrow === 'U') {
            if (orgIndex === 0) {
                return false;
            }
            targetId = data?.data.ResultData[orgIndex - 1].id;
        } else {
            if (orgIndex === data?.data.ResultData.length - 1) {
                return false;
            }
            targetId = data?.data.ResultData[orgIndex + 1].id;
        }
        if (window.confirm('대분류 순서를 변경하시겠습니까?')) {
            let params = {
                id: v.id,
                targetId,
            };
            setLargeCategoryOrderChangeMutation.mutate(params);
        }
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        대분류 관리
                    </Title>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '800px' }} />
                                <col style={{ width: '200px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>대분류명</Th>
                                    <Th>순서변경</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.map(
                                    (v: LargeCategoryData) => {
                                        return (
                                            <tr key={v.id}>
                                                <Td>{v.name}</Td>
                                                <Td>
                                                    <ArrowUpwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'U');
                                                        }}
                                                    />
                                                    <ArrowDownwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'D');
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                </Base>
            )}
        </>
    );
};

export default React.memo(CompanyLargeCategory);
