import {
    companyPromotionVisibility,
    deleteCompanyPromotion,
    insertCompanyPromotion,
    modifyCompanyPromotion,
    setCompanyPromotionDragOrderChange,
    useCompanyPromotionNameListQuery,
    useCompanyPromotionQuery,
} from '../../hooks/ad/useAd';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd';
import { CompanyPromotionData, CompanyTypeList } from '../typings/ad/response';
import {
    CloudUploadRounded,
    DeleteRounded,
    EditRounded,
    VisibilityOffRounded,
    VisibilityRounded,
} from '@mui/icons-material';
import React, { ChangeEvent, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useMutation, useQueryClient } from 'react-query';
import { checkImageExt } from '../../utils/util';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div`
    clear: both;
`;

const Table = styled.div`
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.div`
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
    width: 10%;
`;

const Td = styled.div`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
    width: 10%;
`;

const AutoCompleteArea = styled.div`
    width: 80%;
`;

const CompanyArea = styled.div`
    display: flex;
    align-items: center;

    & > div:nth-of-type(1) {
        width: 20%;
    }
`;
const CompanyPromotion = () => {
    const [companyPromotionDialogOpen, setCompanyPromotionDialogOpen] =
        useState(false);
    const [description, setDescription] = useState('');
    const [editId, setEditId] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyId, setCompanyId] = useState(0);
    const [fileName, setFileName] = useState('');

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useCompanyPromotionQuery();
    const { data: nameList } = useCompanyPromotionNameListQuery();
    const setCompanyPromotionVisibilityMutation = useMutation(
        companyPromotionVisibility,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useCompanyPromotionQuery');
            },
        },
    );

    const deleteCompanyPromotionMutation = useMutation(deleteCompanyPromotion, {
        onSuccess: () => {
            queryClient.invalidateQueries('useCompanyPromotionQuery');
            queryClient.invalidateQueries('useCompanyPromotionNameListQuery');
        },
    });

    const insertCompanyPromotionMutation = useMutation(insertCompanyPromotion, {
        onSuccess: (data) => {
            if (data.data.ResultCode === 1) {
                handleClose();
                queryClient.invalidateQueries('useCompanyPromotionQuery');
                queryClient.invalidateQueries(
                    'useCompanyPromotionNameListQuery',
                );
            }
        },
    });

    const modifyCompanyPromotionMutation = useMutation(modifyCompanyPromotion, {
        onSuccess: (data) => {
            if (data.data.ResultCode === 1) {
                handleClose();
                queryClient.invalidateQueries('useCompanyPromotionQuery');
                queryClient.invalidateQueries(
                    'useCompanyPromotionNameListQuery',
                );
            }
        },
    });

    const setCompanyPromotionDragOrderChangeMutation = useMutation(
        setCompanyPromotionDragOrderChange,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useCompanyPromotionQuery');
            },
        },
    );

    const handleClose = () => {
        setCompanyPromotionDialogOpen(false);
        setEditId('');
        setFileName('');
        setCompanyName('');
        setDescription('');
        setCompanyId(0);
    };

    const handleClickOpen = () => {
        setCompanyPromotionDialogOpen(true);
    };

    const editClick = (v: CompanyPromotionData) => {
        setCompanyPromotionDialogOpen(true);
        setEditId(v.id);
        setCompanyId(v.companyId);
        setDescription(v.description);
        setCompanyName(v.companyName);
    };

    const deleteClick = (v: CompanyPromotionData) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteCompanyPromotionMutation.mutate(v.id);
        }
    };

    const setCompanyPromotionVisibility = (
        v: CompanyPromotionData,
        isVisibility: string,
    ) => {
        let msg = '레빠 단독 구성 상품을 노출하시겠습니까?';
        if (isVisibility === 'N') {
            msg = '레빠 단독 구성 상품을 비노출하시겠습니까?';
        }
        if (window.confirm(msg)) {
            let params = {
                id: v.id,
                isVisibility,
            };
            setCompanyPromotionVisibilityMutation.mutate(params);
        }
    };

    const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
        let fileName = e.target.value;
        if (!checkImageExt(fileName.substring(fileName.lastIndexOf('.') + 1))) {
            alert('이미지 파일만 첨부 가능합니다.');
            return false;
        }
        setFileName(fileName.substring(fileName.lastIndexOf('\\') + 1));
    };

    const onSubmitCompanyPromotion = () => {
        if (companyId === 0) {
            alert('사업장을 입력해 주세요.');
            return false;
        }
        if (description === '') {
            alert('구분명을 입력해 주세요.');
            return false;
        }
        if (fileName === '') {
            alert('이미지를 선택해 주세요.');
            return false;
        }
        let params = {
            companyId,
            description,
        };
        insertCompanyPromotionMutation.mutate(params);
    };

    const onModifyCompanyPromotion = () => {
        if (description === '') {
            alert('구분명을 입력해 주세요.');
            return false;
        }
        let params = {
            id: editId,
            companyId,
            description,
        };
        modifyCompanyPromotionMutation.mutate(params);
    };

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            const { source, destination, draggableId } = result;
            if (!destination || source.index === destination.index) {
                return;
            }
            let params = {
                id: draggableId.replace('company-promotion-', ''),
                targetOrdering: destination.index,
            };
            setCompanyPromotionDragOrderChangeMutation.mutate(params);
        },
        [setCompanyPromotionDragOrderChangeMutation],
    );
    const handleOnSelect = (v: CompanyTypeList) => {
        setCompanyId(v.id);
    };

    const handleOnSearch = (string: string) => {
        setCompanyName(string);
    };

    const handleOnClear = () => {
        setCompanyName('');
        setCompanyId(0);
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        레빠 단독 구성 상품 관리
                    </Title>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="companyPromotion">
                            {(provided) => (
                                <TableArea
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <Table>
                                        <div style={{ display: 'flex' }}>
                                            <Th>사업장이름</Th>
                                            <Th>구분명</Th>
                                            <Th style={{ width: '30%' }}>
                                                <div>이미지</div>
                                            </Th>
                                            <Th>등록자</Th>
                                            <Th>등록일</Th>
                                            <Th>노출여부</Th>
                                            <Th>&nbsp;</Th>
                                        </div>
                                        <div>
                                            {data?.data.ResultData.map(
                                                (v: CompanyPromotionData) => (
                                                    <Draggable
                                                        draggableId={`company-promotion-${v.id}`}
                                                        index={v.ordering}
                                                        key={`company-promotion-${v.id}`}
                                                    >
                                                        {(
                                                            provided,
                                                            snapshot,
                                                        ) => {
                                                            return (
                                                                <div
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                >
                                                                    <div
                                                                        key={
                                                                            v.id
                                                                        }
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Td>
                                                                            {
                                                                                v.companyName
                                                                            }
                                                                        </Td>
                                                                        <Td>
                                                                            {
                                                                                v.description
                                                                            }
                                                                        </Td>
                                                                        <Td
                                                                            style={{
                                                                                width: '30%',
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    v.imgUrl
                                                                                }
                                                                                width="100px"
                                                                                alt="배너 이미지"
                                                                            />
                                                                        </Td>
                                                                        <Td>
                                                                            {
                                                                                v.modId
                                                                            }
                                                                        </Td>
                                                                        <Td>
                                                                            {
                                                                                v.modDt
                                                                            }
                                                                        </Td>
                                                                        <Td>
                                                                            {v.showYn ===
                                                                                'Y' && (
                                                                                <VisibilityRounded
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setCompanyPromotionVisibility(
                                                                                            v,
                                                                                            'N',
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {v.showYn ===
                                                                                'N' && (
                                                                                <VisibilityOffRounded
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setCompanyPromotionVisibility(
                                                                                            v,
                                                                                            'Y',
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Td>
                                                                        <Td>
                                                                            <EditRounded
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => {
                                                                                    editClick(
                                                                                        v,
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <DeleteRounded
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => {
                                                                                    deleteClick(
                                                                                        v,
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Td>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }}
                                                    </Draggable>
                                                ),
                                            )}
                                        </div>
                                    </Table>
                                </TableArea>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Dialog
                        open={companyPromotionDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            레빠 단독 구성 상품
                            {editId === '' && ' 등록'}
                            {editId !== '' && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <CompanyArea>
                                    <div>사업장선택</div>
                                    {nameList && (
                                        <AutoCompleteArea>
                                            <ReactSearchAutocomplete
                                                items={nameList.data.ResultData}
                                                onSelect={handleOnSelect}
                                                onSearch={handleOnSearch}
                                                onClear={handleOnClear}
                                                showIcon={false}
                                                inputSearchString={companyName}
                                                styling={{
                                                    borderRadius: '0',
                                                    height: '37px',
                                                    zIndex: 3,
                                                }}
                                            />
                                        </AutoCompleteArea>
                                    )}
                                </CompanyArea>
                                <TextField
                                    id="description"
                                    label="구분명"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="fileName"
                                    label="이미지"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={fileName}
                                    onChange={(e) => {
                                        setFileName(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadRounded />}
                                >
                                    Upload
                                    <input
                                        id={'file-input'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="file"
                                        accept="image/gif,image/jpeg,image/png,image/jpg"
                                        onChange={handleChangeImage}
                                    />
                                </Button>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {editId !== '' && (
                                <Button
                                    onClick={onModifyCompanyPromotion}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {editId === '' && (
                                <Button
                                    onClick={onSubmitCompanyPromotion}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
};
export default CompanyPromotion;
