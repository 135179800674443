import React, { useState } from 'react';
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    EditRounded,
} from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import {
    insertFreeMarketCategory,
    modifyFreeMarketCategory,
    setFreeMarketCategoryOrderChange,
    useFreeMarketCategoryQuery,
} from '../../hooks/category/useCategory';

import styled from '@emotion/styled';
import { FreeMarketCategory } from '../typings/category/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

function Category() {
    const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
    const [editSn, setEditSn] = useState(0);
    const [name, setName] = useState('');

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useFreeMarketCategoryQuery();

    const insertFreeMarketCategoryMutation = useMutation(
        insertFreeMarketCategory,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useFreeMarketCategoryQuery');
            },
        },
    );

    const modifyFreeMarketCategoryMutation = useMutation(
        modifyFreeMarketCategory,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useFreeMarketCategoryQuery');
            },
        },
    );

    const setFreeMarketCategoryOrderChangeMutation = useMutation(
        setFreeMarketCategoryOrderChange,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useFreeMarketCategoryQuery');
            },
        },
    );

    const handleClose = () => {
        setCategoryDialogOpen(false);
        setEditSn(0);
        setName('');
    };

    const handleClickOpen = () => {
        setCategoryDialogOpen(true);
    };
    const editClick = (v: FreeMarketCategory) => {
        setCategoryDialogOpen(true);
        setEditSn(v.categoryId);
        setName(v.name);
    };
    const onModifyFreeMarketCategory = () => {
        if (name === '') {
            alert('카테고리명을 입력해 주세요.');
            return false;
        }
        let params = {
            categoryId: editSn,
            name,
        };
        modifyFreeMarketCategoryMutation.mutate(params);
        handleClose();
    };
    const onSubmitFreeMarketCategory = () => {
        if (name === '') {
            alert('카테고리명을 입력해 주세요.');
            return false;
        }
        insertFreeMarketCategoryMutation.mutate(name);
        handleClose();
    };

    const orderChange = (v: FreeMarketCategory, arrow: string) => {
        let orgIndex = data?.data.ResultData.indexOf(v);
        let targetId;
        if (arrow === 'U') {
            if (orgIndex === 0) {
                return false;
            }
            targetId = data?.data.ResultData[orgIndex - 1].categoryId;
        } else {
            if (orgIndex === data?.data.ResultData.length - 1) {
                return false;
            }
            targetId = data?.data.ResultData[orgIndex + 1].categoryId;
        }
        if (window.confirm('카테고리 순서를 변경하시겠습니까?')) {
            let params = {
                categoryId: v.categoryId,
                targetId,
            };
            setFreeMarketCategoryOrderChangeMutation.mutate(params);
        }
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        프리마켓 카테고리 관리
                    </Title>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '600px' }} />
                                <col style={{ width: '250px' }} />
                                <col style={{ width: '100px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>카테고리명</Th>
                                    <Th>순서변경</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.map(
                                    (v: FreeMarketCategory) => {
                                        return (
                                            <tr key={v.categoryId}>
                                                <Td>{v.name}</Td>
                                                <Td>
                                                    <ArrowUpwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'U');
                                                        }}
                                                    />
                                                    <ArrowDownwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'D');
                                                        }}
                                                    />
                                                </Td>
                                                <Td>
                                                    <EditRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            editClick(v);
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                    <Dialog
                        open={categoryDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            프리마켓 카테고리
                            {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    id="standard-basic"
                                    label="카테고리명"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button
                                    onClick={onModifyFreeMarketCategory}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button
                                    onClick={onSubmitFreeMarketCategory}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
}

export default React.memo(Category);
