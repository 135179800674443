import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import { useQuery } from 'react-query';

const getUsersApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/admin/users',
        method: 'GET',
    });
};

export const useUserQuery = () =>
    useQuery(['useUserQuery'], () => getUsersApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const insertUser = (params) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/admin/users',
        method: 'POST',
        data: params,
    });

export const changeUser = (params) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/admin/users/status',
        method: 'PUT',
        data: params,
    });

export const initPasswordUser = (params) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/admin/users/password',
        method: 'PUT',
        data: params,
    });
