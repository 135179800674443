import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    CloudUploadRounded,
    DeleteRounded,
    EditRounded,
} from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteReservationMiddleClassification,
    insertReservationMiddleClassification,
    modifyReservationMiddleClassification,
    setReservationMiddleClassificationOrderChange,
    useProductCodesQuery,
    useReservationMiddleClassificationQuery,
} from '../../hooks/keyword/useKeyword';

import styled from '@emotion/styled';
import { checkImageExt } from '../../utils/util';
import {
    MappingTargetProductClassificationData,
    ReservationMiddleClassificationData,
} from '../typings/keyword/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const RightDiv = styled.div`
    float: right;
    display: flex;
    align-items: center;
`;

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`;

const ReservationMiddleClassification = () => {
    const [
        reservationMiddleClassificationDialogOpen,
        setReservationMiddleClassificationDialogOpen,
    ] = useState(false);
    const [editSn, setEditSn] = useState(0);
    const [productCodeId, setProductCodeId] = useState(0);
    const [typeId, setTypeId] = useState(1);
    const [imgFileName, setImgFileName] = useState('');
    const [activeImgFileName, setActiveImgFileName] = useState('');

    const queryClient = useQueryClient();
    const { isLoading, isError, data, refetch } =
        useReservationMiddleClassificationQuery(typeId);

    const {
        isLoading: isProductCodeLoading,
        isError: isProductCodeError,
        data: productCodeData,
        refetch: productCodeRefetch,
    } = useProductCodesQuery(typeId);

    const deleteReservationMiddleClassificationMutation = useMutation(
        deleteReservationMiddleClassification,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    'useReservationMiddleClassificationQuery',
                );
            },
        },
    );

    const insertReservationMiddleClassificationMutation = useMutation(
        insertReservationMiddleClassification,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    'useReservationMiddleClassificationQuery',
                );
            },
        },
    );

    const modifyReservationMiddleClassificationMutation = useMutation(
        modifyReservationMiddleClassification,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    'useReservationMiddleClassificationQuery',
                );
            },
        },
    );

    const setReservationMiddleClassificationOrderChangeMutation = useMutation(
        setReservationMiddleClassificationOrderChange,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    'useReservationMiddleClassificationQuery',
                );
            },
        },
    );

    const handleClose = () => {
        setReservationMiddleClassificationDialogOpen(false);
        setEditSn(0);
        setProductCodeId(0);
        setImgFileName('');
        setActiveImgFileName('');
    };

    const handleClickOpen = () => {
        setReservationMiddleClassificationDialogOpen(true);
    };
    const editClick = (v: ReservationMiddleClassificationData) => {
        setReservationMiddleClassificationDialogOpen(true);
        setEditSn(v.id);
    };
    const deleteClick = (v: ReservationMiddleClassificationData) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteReservationMiddleClassificationMutation.mutate(v.id);
        }
    };
    const onModifyReservationMiddleClassification = () => {
        if (imgFileName === '' && activeImgFileName === '') {
            alert('이미지 파일을 선택해주세요.');
            return false;
        }
        let params = {
            imgFileName,
            activeImgFileName,
            id: editSn,
        };
        modifyReservationMiddleClassificationMutation.mutate(params);
        handleClose();
    };
    const onSubmitReservationMiddleClassification = () => {
        if (imgFileName === '' || activeImgFileName === '') {
            alert('이미지 파일을 선택해주세요.');
            return false;
        }
        if (productCodeId === 0) {
            alert('중분류를 선택해주세요.');
            return false;
        }
        let params = {
            productCodeId,
            typeId,
        };
        insertReservationMiddleClassificationMutation.mutate(params);
        handleClose();
    };

    const orderChange = (
        v: ReservationMiddleClassificationData,
        arrow: string,
    ) => {
        let orgIndex = data?.data.ResultData.indexOf(v);
        let targetSn;
        if (arrow === 'U') {
            if (orgIndex === 0) {
                return false;
            }
            targetSn = data?.data.ResultData[orgIndex - 1].id;
        } else {
            if (orgIndex === data?.data.ResultData.length - 1) {
                return false;
            }
            targetSn = data?.data.ResultData[orgIndex + 1].id;
        }
        if (window.confirm('중분류 순서를 변경하시겠습니까?')) {
            let params = {
                id: v.id,
                targetId: targetSn,
            };
            setReservationMiddleClassificationOrderChangeMutation.mutate(
                params,
            );
        }
    };

    const handleTypeChange = (e: SelectChangeEvent) => {
        setTypeId(Number(e.target.value));
    };

    useEffect(() => {
        refetch();
        productCodeRefetch();
    }, [typeId, refetch, productCodeRefetch]);

    const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
        let fileName = e.target.value;
        if (!checkImageExt(fileName.substring(fileName.lastIndexOf('.') + 1))) {
            alert('이미지 파일만 첨부 가능합니다.');
            return false;
        }
        setImgFileName(fileName.substring(fileName.lastIndexOf('\\') + 1));
    };

    const handleChangeActiveImage = (e: ChangeEvent<HTMLInputElement>) => {
        let fileName = e.target.value;
        if (!checkImageExt(fileName.substring(fileName.lastIndexOf('.') + 1))) {
            alert('이미지 파일만 첨부 가능합니다.');
            return false;
        }
        setActiveImgFileName(
            fileName.substring(fileName.lastIndexOf('\\') + 1),
        );
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        중분류 관리
                    </Title>
                    <RightDiv>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={typeId.toString()}
                            label="Age"
                            onChange={handleTypeChange}
                            size="small"
                        >
                            <MenuItem value={1}>강으로</MenuItem>
                            <MenuItem value={2}>바다로</MenuItem>
                            <MenuItem value={3}>숲으로</MenuItem>
                            <MenuItem value={4}>도시로</MenuItem>
                        </Select>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </RightDiv>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '450px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '100px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>중분류명</Th>
                                    <Th>기본 이미지</Th>
                                    <Th>선택 이미지</Th>
                                    <Th>순서변경</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.map(
                                    (
                                        v: ReservationMiddleClassificationData,
                                    ) => {
                                        return (
                                            <tr key={v.id}>
                                                <Td>{v.name}</Td>
                                                <Td>
                                                    <img
                                                        width="50px"
                                                        src={v.imgPath}
                                                        alt="기본이미지"
                                                    />
                                                </Td>
                                                <Td>
                                                    <img
                                                        width="50px"
                                                        src={v.activeImgPath}
                                                        alt="선택이미지"
                                                    />
                                                </Td>
                                                <Td>
                                                    <ArrowUpwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'U');
                                                        }}
                                                    />
                                                    <ArrowDownwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'D');
                                                        }}
                                                    />
                                                </Td>
                                                <Td>
                                                    <EditRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            editClick(v);
                                                        }}
                                                    />
                                                    <DeleteRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            deleteClick(v);
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                    <Dialog
                        open={reservationMiddleClassificationDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            중분류
                            {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            {editSn === 0 && (
                                <FlexDiv>
                                    <Select
                                        labelId="productCodes"
                                        id="productCodes"
                                        value={productCodeId}
                                        label="중분류명"
                                        onChange={(e) => {
                                            setProductCodeId(
                                                Number(e.target.value),
                                            );
                                        }}
                                    >
                                        {!isProductCodeLoading &&
                                            !isProductCodeError &&
                                            productCodeData?.data.ResultData.map(
                                                (
                                                    v: MappingTargetProductClassificationData,
                                                ) => {
                                                    return (
                                                        <MenuItem
                                                            key={v.id}
                                                            value={v.id}
                                                        >
                                                            {v.name}
                                                        </MenuItem>
                                                    );
                                                },
                                            )}
                                    </Select>
                                </FlexDiv>
                            )}
                            <FlexDiv>
                                <TextField
                                    id="fileName"
                                    label="기본 이미지"
                                    style={{
                                        margin: '8px 0px 8px 0px',
                                        paddingRight: '5px',
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={imgFileName}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadRounded />}
                                >
                                    Upload
                                    <input
                                        id={'img-file-input'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="file"
                                        accept="image/gif,image/jpeg,image/png,image/jpg,image/svg+xml"
                                        onChange={handleChangeImage}
                                    />
                                </Button>
                            </FlexDiv>
                            <FlexDiv>
                                <TextField
                                    id="fileName"
                                    label="선택 이미지"
                                    style={{
                                        margin: '8px 0px 8px 0px',
                                        paddingRight: '5px',
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={activeImgFileName}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadRounded />}
                                >
                                    Upload
                                    <input
                                        id={'active-img-file-input'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="file"
                                        accept="image/gif,image/jpeg,image/png,image/jpg,image/svg+xml"
                                        onChange={handleChangeActiveImage}
                                    />
                                </Button>
                            </FlexDiv>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button
                                    onClick={
                                        onModifyReservationMiddleClassification
                                    }
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button
                                    onClick={
                                        onSubmitReservationMiddleClassification
                                    }
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
};

export default React.memo(ReservationMiddleClassification);
