import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import 'tui-grid/dist/tui-grid.css';
import TuiGrid from 'tui-grid';
import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import {
    getDeliveryReadyExcel,
    setManualCancel,
    setManualDeliveryComplete,
    setManualReturnComplete,
    uploadInvoiceFile,
    useDeliveryApplyQuery,
    useDeliveryCompleteQuery,
    useDeliveryProgressQuery,
    useDeliveryReadyQuery,
    useDeliveryReturnQuery,
    useGetCategoryQuery,
    useLeppaMarketOrderListQuery,
} from '../../hooks/leppa-market/useLeppaMarket';
import { checkExcelExt } from '../../utils/util';
import Pagination from '../common/Pagination';
import { CloudUploadRounded } from '@mui/icons-material';
import {
    LeppaMarketOrdersData,
    OrderInvoiceUploadErrorData,
} from '../typings/leppa-market/response';
import {
    LeppaCategoryStatus,
    LeppaGiftStatus,
    LeppaOrderStatus,
} from '../typings/leppa-market/types';
import Excel from '../../assets/IcExcel.png';
import Items from '../../assets/Vector.png';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { orderDataState, orderDetailState } from '../common/atom';
import LeppaMarketOrderDetail from './LeppaMarketOrderDetail';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import { format } from 'date-fns';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
    font-size: 14px;
    font-family: 'SUIT';
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const OrderTable = styled.table`
    table-layout: fixed;
    word-break: break-all;
    th {
        text-align: left;
        padding: 10px 12px;
        border-bottom: 1px solid rgba(37, 40, 43, 1);
    }
    th:nth-of-type(1) {
        width: 28px;
        text-align: center;
    }
    th:nth-of-type(2) {
        width: 61px;
    }
    th:nth-of-type(3),
    th:nth-of-type(5),
    th:nth-of-type(7),
    th:nth-of-type(9),
    th:nth-of-type(10) {
        width: 96px;
    }
    th:nth-of-type(4) {
        width: 256px;
    }
    th:nth-of-type(6) {
        width: 200px;
    }
    th:nth-of-type(8) {
        width: 76px;
    }
    td {
        padding: 10px 12px;
    }
    td:nth-of-type(1),
    td:nth-of-type(2) {
        text-align: center;
    }
`;

const TableArea = styled.div`
    width: 954px;
    height: 696px;
    overflow: scroll;
`;

const Line = styled.tr``;

const Right = styled.td`
    text-align: right;
`;

const Btn = styled.button`
    font-size: 14px;
    font-weight: 400;
    padding: 10px 8px;
    background: #fff;
    border: 1px solid rgba(235, 238, 240, 1);
    border-radius: 4px;
    cursor: pointer;
`;

const Btn2 = styled.button`
    font-size: 14px;
    font-weight: 400;
    padding: 10px 8px;
    background: rgba(242, 249, 255, 1);
    border: 1px solid rgba(205, 230, 248, 1);
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    color: rgba(4, 130, 222, 1);
    :hover {
        background: rgba(205, 230, 248, 1);
    }
`;

const Eicon = styled.img`
    vertical-align: middle;
    padding-right: 8px;
`;

const DetailBtn = styled.button`
    text-align: left;
    text-decoration: underline;
    border: 0;
    background: #fff0;
    cursor: pointer;
`;

const Type01 = styled.span`
    padding: 7px 10px;
    background: #f8f9fa;
    border: 1px solid #ebeef0;
    border-radius: 16px;
`;
const Type02 = styled.span`
    color: #47b50b;
    padding: 7px 10px;
    background: #eaf9e1;
    border-radius: 16px;
`;
const Type03 = styled.span`
    color: #ea2c4e;
    padding: 7px 10px;
    background: #f8e3e7;
    border-radius: 16px;
`;
const Type04 = styled.span`
    color: #ea2c4e;
    padding: 7px 10px;
    background: #f8f9fa;
    border: 1px solid #ebeef0;
    border-radius: 16px;
`;

const StatusBar = styled.div`
    display: flex;
    width: 100%;
    height: 52px;
    padding: 14px 0;
    background: #f8f9fa;
    border-radius: 16px;
    margin: 24px 0 40px;
    label {
        width: 25%;
        text-align: center;
    }
    li {
        list-style: none;
    }
    li:nth-of-type(1) {
        color: #75808a;
    }
`;

const SelectTable = styled.table`
    width: 100%;
    th {
        width: 95px;
        padding: 10px 0;
        text-align: left;
    }
    td {
        padding: 10px 0;
    }
`;

const Choice = styled.td`
    display: flex;
    gap: 20px;
    label {
        font-size: 15px;
    }

    span {
        color: #3f464e;
        vertical-align: middle;
    }

    [type='radio'] {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        appearance: none;
        border: 1px solid #ebeef0;
        border-radius: 50%;
    }

    [type='radio']:checked {
        border: 0.4em solid #0482de;
    }

    [type='radio']:focus-visible {
        outline-offset: max(2px, 0.1em);
        outline: max(2px, 0.1em) dotted tomato;
    }

    [type='radio']:disabled {
        background-color: lightgray;
        box-shadow: none;
        opacity: 0.7;
        cursor: not-allowed;
    }

    [type='radio']:disabled + span {
        opacity: 0.7;
        cursor: not-allowed;
    }

    /* Global CSS */
    fieldset {
        display: flex;
        justify-content: center;
        border: none;
        margin: 0;
        padding: 40px 20px;
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
`;

const StyleDatePicker = styled(DatePicker)`
    width: 116px;
    height: 26px;
    padding: 8px 12px;
    border: 1px solid #ebeef0;
    border-radius: 8px;
    cursor: pointer;
`;

const Calendar = styled.td`
    display: flex;
    gap: 12px;
`;

const Ing = styled.span`
    padding-top: 12px;
`;

const Num = styled.li`
    padding-top: 3px;
    font-weight: 700;
    font-size: 24px;
    color: #25282b;
    text-decoration: underline;
`;

const PageNum = styled.div`
    float: left;
    line-height: 36px;
    color: #75808a;
    margin: 30px 0 40px;
`;

const SelectBox = styled.select`
    height: 42px;
    padding: 10px 12px;
    border: 1px solid #ebeef0;
    border-radius: 8px;
    -webkit-appearance: none; /* 크롬 화살표 없애기 */
    -moz-appearance: none; /* 파이어폭스 화살표 없애기 */
    appearance: none; /* 화살표 없애기 */
    background: url(${Items}) no-repeat right 15px center;
    option {
        padding: 10px 12px;
    }
`;

const LeppaMarketOrders = () => {
    const pageSize = 20;

    const [orderStatus, setOrderStatus] = useState<LeppaOrderStatus>('ALL');
    const [currListPage, setCurrListPage] = useState(1);
    const [checkOrderId, setCheckOrderId] = useState<Array<string>>([]);

    const [giftStatus, setGiftStatus] = useState<LeppaGiftStatus>('ALL');

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const category = useGetCategoryQuery();
    const [categoryStatus, setCategoryStatus] =
        useState<LeppaCategoryStatus>('');

    const queryClient = useQueryClient();
    const { isLoading, isError, data, refetch } = useLeppaMarketOrderListQuery(
        currListPage - 1,
        pageSize,
        orderStatus,
        giftStatus,
        categoryStatus,
        startDate === null ? '' : format(startDate, 'yyyyMMdd'),
        endDate === null ? '' : format(endDate, 'yyyyMMdd'),
    );

    const ReadyStatus = 'DELIVERY_READY';
    const deliveryReady = useDeliveryReadyQuery(ReadyStatus);
    const ProgressStatus = 'DELIVERY_IN_PROGRESS';
    const deliveryProgress = useDeliveryProgressQuery(ProgressStatus);
    const CompleteStatus = 'DELIVERY_COMPLETE';
    const deliveryComplete = useDeliveryCompleteQuery(CompleteStatus);
    const ApplyStatus = 'RETURN_APPLY';
    const deliveryApply = useDeliveryApplyQuery(ApplyStatus);
    const ReturnStatus = 'RETURN';
    const deliveryReturn = useDeliveryReturnQuery(ReturnStatus);

    const getDeliveryReadyExcelMutation = useMutation(getDeliveryReadyExcel, {
        onSuccess: (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            const contentDisposition = res.headers['content-disposition']; // 파일 이름
            let fileName = 'unknown';
            if (contentDisposition) {
                const [fileNameMatch] = contentDisposition
                    .split(';')
                    .filter((str) => str.includes('filename'));
                if (fileNameMatch) [, fileName] = fileNameMatch.split('=');
                fileName = decodeURI(fileName);
                link.href = url;
                link.setAttribute('download', `${fileName}`);
                link.style.cssText = 'display:none';
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        },
    });
    const uploadInvoiceFileMutation = useMutation(uploadInvoiceFile, {
        onSuccess: (res) => {
            if (res.data.ResultData.length > 0) {
                setUploadInvoiceErrorData(res.data.ResultData);
                setUploadInvoiceErrorDialogOpen(true);
            }
            queryClient.invalidateQueries('useLeppaMarketOrderListQuery');
        },
    });
    const setManualDeliveryCompleteMutation = useMutation(
        setManualDeliveryComplete,
        {
            onSuccess: (res) => {
                if (res.data.ResultData.length > 0) {
                    alert('업로드 에러\n주문아이디:' + res.data.ResultData);
                }
                queryClient.invalidateQueries('useLeppaMarketOrderListQuery');
            },
        },
    );
    const setManualCancelMutation = useMutation(setManualCancel, {
        onSuccess: (res) => {
            if (res.data.ResultData.length > 0) {
                alert('업로드 에러\n주문아이디:' + res.data.ResultData);
            }
            queryClient.invalidateQueries('useLeppaMarketOrderListQuery');
        },
    });
    const setManualReturnCompleteMutation = useMutation(
        setManualReturnComplete,
        {
            onSuccess: (res) => {
                if (res.data.ResultData.length > 0) {
                    alert('업로드 에러\n주문아이디:' + res.data.ResultData);
                }
                queryClient.invalidateQueries('useLeppaMarketOrderListQuery');
            },
        },
    );

    TuiGrid.applyTheme('striped');
    const [uploadInvoiceDialogOpen, setUploadInvoiceDialogOpen] =
        useState(false);
    const [uploadInvoiceErrorDialogOpen, setUploadInvoiceErrorDialogOpen] =
        useState(false);
    const [uploadInvoiceErrorData, setUploadInvoiceErrorData] = useState([]);
    const [fileName, setFileName] = useState('');
    const handleClose = () => {
        setUploadInvoiceDialogOpen(false);
        setFileName('');
    };
    const [orderDetailOpen, setOrderDetailOpen] =
        useRecoilState(orderDetailState);
    const setOrdersData = useSetRecoilState(orderDataState);

    const setList = (page: number) => {
        setCurrListPage(page);
    };

    const handleChange = (e: SelectChangeEvent) => {
        setOrderStatus(e.target.value as LeppaOrderStatus);
    };

    const handleCategory = (e: any) => {
        setCategoryStatus(e.target.value as LeppaCategoryStatus);
    };

    const handleGiftChange = (e: SelectChangeEvent) => {
        setGiftStatus(e.target.value as LeppaGiftStatus);
    };

    useEffect(() => {
        refetch();
    }, [orderStatus, refetch]);

    useEffect(() => {
        refetch();
    }, [giftStatus, refetch]);

    useEffect(() => {
        refetch();
    }, [startDate, endDate, refetch]);

    useEffect(() => {
        refetch();
    }, [categoryStatus, refetch]);

    useEffect(() => {
        refetch();
    }, [currListPage, refetch]);

    const excelDownload = () => {
        getDeliveryReadyExcelMutation.mutate();
    };

    const uploadInvoice = () => {
        setUploadInvoiceDialogOpen(true);
    };

    const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
        let fileName = e.target.value;
        if (!checkExcelExt(fileName.substring(fileName.lastIndexOf('.') + 1))) {
            alert('엑셀 파일만 첨부 가능합니다.');
            return false;
        }
        setFileName(fileName.substring(fileName.lastIndexOf('\\') + 1));
    };

    const onSubmitUploadInvoice = () => {
        if (fileName === '') {
            alert('엑셀파일을 선택해 주세요.');
            return false;
        }
        uploadInvoiceFileMutation.mutate();
        handleClose();
    };

    const handleUploadErrorClose = () => {
        setUploadInvoiceErrorDialogOpen(false);
    };

    const manualDeliveryComplete = () => {
        if (checkOrderId.length > 0) {
            const uniqueArr = checkOrderId.filter((element, index) => {
                return checkOrderId.indexOf(element) === index;
            });
            let params = {
                orderIds: uniqueArr.toString(),
            };
            setManualDeliveryCompleteMutation.mutate(params);
        }
    };

    const manualCancel = () => {
        if (checkOrderId.length > 0) {
            const uniqueArr = checkOrderId.filter((element, index) => {
                return checkOrderId.indexOf(element) === index;
            });
            let params = {
                orderIds: uniqueArr.toString(),
            };
            setManualCancelMutation.mutate(params);
            window.location.href = './leppa-market-orders';
        }
    };

    const manualReturnComplete = () => {
        if (checkOrderId.length > 0) {
            const uniqueArr = checkOrderId.filter((element, index) => {
                return checkOrderId.indexOf(element) === index;
            });
            let params = {
                orderIds: uniqueArr.toString(),
            };
            setManualReturnCompleteMutation.mutate(params);
            window.location.href = './leppa-market-orders';
        }
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title>주문 관리</Title>
                    <StatusBar>
                        <label>
                            <ul>
                                <li>배송 준비 중</li>
                                <Num>
                                    {
                                        deliveryReady.data?.data.ResultData
                                            .totalElements
                                    }
                                </Num>
                            </ul>
                        </label>
                        <label>
                            <ul>
                                <li>배송 중</li>
                                <Num>
                                    {
                                        deliveryProgress.data?.data.ResultData
                                            .totalElements
                                    }
                                </Num>
                            </ul>
                        </label>
                        <label>
                            <ul>
                                <li>배송 완료</li>
                                <Num>
                                    {
                                        deliveryComplete.data?.data.ResultData
                                            .totalElements
                                    }
                                </Num>
                            </ul>
                        </label>
                        <label>
                            <ul>
                                <li>반품 신청</li>
                                <Num>
                                    {
                                        deliveryApply.data?.data.ResultData
                                            .totalElements
                                    }
                                </Num>
                            </ul>
                        </label>
                        <label>
                            <ul>
                                <li>반품 완료</li>
                                <Num>
                                    {
                                        deliveryReturn.data?.data.ResultData
                                            .totalElements
                                    }
                                </Num>
                            </ul>
                        </label>
                    </StatusBar>
                    <SelectTable cellPadding={0} cellSpacing={0} border={0}>
                        <tbody>
                            <tr>
                                <th>주문 종류</th>
                                <Choice colSpan={3}>
                                    <label htmlFor="total">
                                        <input
                                            type="radio"
                                            id="total"
                                            name="choice"
                                            value="ALL"
                                            onChange={handleGiftChange}
                                            checked={
                                                giftStatus === 'ALL'
                                                    ? true
                                                    : false
                                            }
                                        />{' '}
                                        <span>전체</span>
                                    </label>
                                    <label htmlFor="basic">
                                        <input
                                            type="radio"
                                            id="basic"
                                            name="choice"
                                            value="N"
                                            onChange={handleGiftChange}
                                        />{' '}
                                        일반주문
                                    </label>
                                    <label htmlFor="gift">
                                        <input
                                            type="radio"
                                            id="gift"
                                            name="choice"
                                            value="Y"
                                            onChange={handleGiftChange}
                                        />{' '}
                                        선물하기
                                    </label>
                                </Choice>
                            </tr>
                            <tr>
                                <th>상품 종류</th>
                                <td
                                    style={{
                                        width: '340px',
                                    }}
                                >
                                    <SelectBox
                                        style={{
                                            width: '260px',
                                        }}
                                        value={categoryStatus}
                                        onChange={handleCategory}
                                    >
                                        <option value="">전체</option>
                                        {category.data?.data.ResultData.map(
                                            (v, id) => (
                                                <option value={v.id} key={id}>
                                                    {v.CdNm}
                                                </option>
                                            ),
                                        )}
                                    </SelectBox>
                                </td>
                                <th>주문 상태</th>
                                <td>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={orderStatus}
                                        label="Age"
                                        onChange={handleChange}
                                        size="small"
                                        style={{ width: '260px' }}
                                    >
                                        <MenuItem value={'ALL'}>전체</MenuItem>
                                        <MenuItem value={'DELIVERY_READY'}>
                                            배송준비중
                                        </MenuItem>
                                        <MenuItem
                                            value={'DELIVERY_IN_PROGRESS'}
                                        >
                                            배송중
                                        </MenuItem>
                                        <MenuItem value={'DELIVERY_COMPLETE'}>
                                            배송완료
                                        </MenuItem>
                                        <MenuItem value={'CANCEL'}>
                                            사용자취소
                                        </MenuItem>
                                        <MenuItem value={'RETURN_APPLY'}>
                                            반품신청
                                        </MenuItem>
                                        <MenuItem value={'RETURN_PROGRESS'}>
                                            반품처리중
                                        </MenuItem>
                                        <MenuItem value={'RETURN'}>
                                            반품완료
                                        </MenuItem>
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <th>배송 시작일</th>
                                <Calendar colSpan={3}>
                                    <StyleDatePicker
                                        className="inputStyle"
                                        selected={startDate}
                                        onChange={(date: any) => {
                                            setStartDate(date);
                                        }}
                                        locale={ko}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                    {/* <img src={CalendarImg} alt="달력아이콘" /> */}
                                    <Ing>~</Ing>
                                    <StyleDatePicker
                                        className="inputStyle"
                                        onChange={(date: any) => {
                                            setEndDate(date);
                                        }}
                                        selected={endDate}
                                        locale={ko}
                                        dateFormat="yyyy-MM-dd"
                                    />
                                </Calendar>
                            </tr>
                        </tbody>
                    </SelectTable>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            padding: '15px 0',
                            borderTop: '2px solid #25282B',
                            marginTop: '30px',
                        }}
                    >
                        <Btn2 onClick={manualDeliveryComplete}>
                            배송완료 처리
                        </Btn2>
                        <Btn2 onClick={manualReturnComplete}>
                            반품완료 처리
                        </Btn2>
                        <Btn2 onClick={manualCancel}>주문취소 처리</Btn2>
                        <Btn
                            style={{ marginLeft: 'auto' }}
                            onClick={excelDownload}
                        >
                            <Eicon src={Excel} alt="엑셀 아이콘" />
                            배송준비중 엑셀 다운로드
                        </Btn>
                        <Btn
                            style={{ marginLeft: '10px' }}
                            onClick={uploadInvoice}
                        >
                            송장번호 일괄 업로드
                        </Btn>
                    </div>
                    <TableArea>
                        <OrderTable
                            cellSpacing={0}
                            cellPadding={0}
                            width="1228px"
                        >
                            <thead>
                                <tr>
                                    <th>선택</th>
                                    <th>주문 유형</th>
                                    <th>주문번호</th>
                                    <th>상품명</th>
                                    <th>수령인</th>
                                    <th>배송지</th>
                                    <th>상태</th>
                                    <th>총 결제금액</th>
                                    <th>배송 시작일</th>
                                    <th>송장번호</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.content.map(
                                    (v: LeppaMarketOrdersData, idx: number) => (
                                        <Line key={idx}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    onChange={(e: any) => {
                                                        if (e.target.checked) {
                                                            let orderId =
                                                                v.orderId;
                                                            if (
                                                                !checkOrderId.includes(
                                                                    orderId,
                                                                )
                                                            ) {
                                                                setCheckOrderId(
                                                                    [
                                                                        ...checkOrderId,
                                                                        v.orderId,
                                                                    ],
                                                                );
                                                            }
                                                        } else {
                                                            setCheckOrderId(
                                                                checkOrderId.filter(
                                                                    (s) => {
                                                                        return (
                                                                            s !==
                                                                            v.orderId
                                                                        );
                                                                    },
                                                                ),
                                                            );
                                                        }
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {
                                                    {
                                                        N: '일반',
                                                        Y: '선물',
                                                    }[v.giftYn]
                                                }
                                            </td>
                                            <td>
                                                <DetailBtn
                                                    onClick={() => {
                                                        setOrderDetailOpen(
                                                            true,
                                                        );
                                                        setOrdersData(v);
                                                    }}
                                                >
                                                    {v.orderId}
                                                </DetailBtn>
                                            </td>
                                            <td
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setOrderDetailOpen(true);
                                                    setOrdersData(v);
                                                }}
                                            >
                                                {v.items}{' '}
                                                {v.restCnt === 0 ? (
                                                    ''
                                                ) : (
                                                    <>
                                                        ·{' '}
                                                        <span
                                                            style={{
                                                                color: 'rgba(89, 166, 245, 1)',
                                                            }}
                                                        >
                                                            {v.restCnt}개
                                                        </span>
                                                    </>
                                                )}
                                            </td>
                                            <td>
                                                {v.receiverName === ''
                                                    ? v.name
                                                    : v.receiverName}
                                            </td>
                                            <td>
                                                {v.address === 'null null'
                                                    ? '-'
                                                    : v.address}
                                            </td>
                                            <td>
                                                {
                                                    {
                                                        DELIVERY_INPUT_READY: (
                                                            <Type01>
                                                                미등록
                                                            </Type01>
                                                        ),
                                                        REFUSAL: (
                                                            <Type04>
                                                                선물 거절
                                                            </Type04>
                                                        ),
                                                        DELIVERY_READY: (
                                                            <Type02>
                                                                배송 준비중
                                                            </Type02>
                                                        ),
                                                        DELIVERY_IN_PROGRESS: (
                                                            <Type01>
                                                                배송 중
                                                            </Type01>
                                                        ),
                                                        DELIVERY_COMPLETE: (
                                                            <Type01>
                                                                배송 완료
                                                            </Type01>
                                                        ),
                                                        RETURN_APPLY: (
                                                            <Type03>
                                                                반품 신청
                                                            </Type03>
                                                        ),
                                                        RETURN: (
                                                            <Type04>
                                                                반품 완료
                                                            </Type04>
                                                        ),
                                                        CANCEL: (
                                                            <Type04>
                                                                선물 취소
                                                            </Type04>
                                                        ),
                                                        PAYMENT_WAIT:
                                                            '결제취소',
                                                        EXPIRATION:
                                                            '선물수락만료',
                                                        PAYMENT_ERROR:
                                                            '결제오류',
                                                    }[v.status]
                                                }
                                            </td>
                                            <Right>{v.totalPrice}</Right>
                                            <td>
                                                {v.deliveryStartDate === ''
                                                    ? '-'
                                                    : v.deliveryStartDate}
                                            </td>
                                            <td>
                                                {v.invoiceNumber === null
                                                    ? '-'
                                                    : v.invoiceNumber === ''
                                                    ? '-'
                                                    : v.invoiceNumber}
                                            </td>
                                        </Line>
                                    ),
                                )}
                            </tbody>
                        </OrderTable>
                    </TableArea>
                    <PageNum>
                        총 {data?.data.ResultData.totalElements}개중{' '}
                        <span style={{ color: '#59A6F5' }}>
                            {data?.data.ResultData.numberOfElements === 20
                                ? data?.data.ResultData.numberOfElements *
                                  (data?.data.ResultData.number + 1)
                                : data?.data.ResultData.number * 20 +
                                  data?.data.ResultData.numberOfElements}
                            개
                        </span>
                    </PageNum>
                    {data?.data.ResultData.totalElements > 0 && (
                        <Pagination
                            // items={data?.data.ResultData.numberOfElements}
                            itemsCount={data?.data.ResultData.totalElements}
                            pageSize={pageSize}
                            currPage={currListPage}
                            setList={setList}
                        />
                    )}
                    <Dialog
                        open={uploadInvoiceDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            송장번호 업로드
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    id="fileName"
                                    label="업로드파일"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={fileName}
                                    onChange={(e) => {
                                        setFileName(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadRounded />}
                                >
                                    Upload
                                    <input
                                        id={'file-input'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="file"
                                        accept="xlsx"
                                        onChange={handleChangeImage}
                                    />
                                </Button>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={onSubmitUploadInvoice}
                                color="primary"
                            >
                                업로드
                            </Button>

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={uploadInvoiceErrorDialogOpen}
                        onClose={handleUploadErrorClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            송장번호 업로드 에러내역
                        </DialogTitle>
                        <DialogContent>
                            <Table>
                                <thead>
                                    <tr>
                                        <Th>주문번호</Th>
                                        <Th>송장번호</Th>
                                        <Th>에러사유</Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {uploadInvoiceErrorData.length > 0 &&
                                        uploadInvoiceErrorData.map(
                                            (
                                                v: OrderInvoiceUploadErrorData,
                                            ) => (
                                                <tr key={v.orderId}>
                                                    <Td>{v.orderId}</Td>
                                                    <Td>{v.invoiceNumber}</Td>
                                                    <Td>{v.reason}</Td>
                                                </tr>
                                            ),
                                        )}
                                </tbody>
                            </Table>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleUploadErrorClose}
                                color="primary"
                            >
                                닫기
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {orderDetailOpen && <LeppaMarketOrderDetail />}
                </Base>
            )}
        </>
    );
};

export default React.memo(LeppaMarketOrders);
