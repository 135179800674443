import React, { ChangeEvent, useEffect, useState } from 'react';
import { DeleteRounded } from '@mui/icons-material';
import styled from '@emotion/styled';
import {
    deleteEnterpriseInfo,
    useEnterpriseListQuery,
} from '../../hooks/enterprise/useEnterprise';
import searchImage from '../../assets/search.png';
import Pagination from '../common/Pagination';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { EnterpriseResponse } from '../typings/enterprise/response';

const Container = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div`
    margin: 0;
    padding: 0;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const SearchArea = styled.div``;

const SearchInput = styled.input`
    width: 256px;
    height: 46px;
    padding: 0 15px;
    box-sizing: border-box;
    border: 1px solid #398fce;
    vertical-align: top;
`;

const SearchBtn = styled.span`
    float: right;
    width: 46px;
    height: 46px;
    line-height: 46px;
    background: #398fce url(${searchImage}) no-repeat 13px 13px;
    font-size: 0;
    text-indent: -9999px;
    cursor: pointer;
`;

const TitleArea = styled.div`
    display: flex;
    justify-content: space-between;
`;

const EnterpriseTitle = styled.div`
    cursor: pointer;
`;

function Enterprise() {
    const pageSize = 20;
    const [currListPage, setCurrListPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const { isLoading, isError, data, refetch } = useEnterpriseListQuery(
        currListPage,
        pageSize,
        searchText,
    );
    const deleteMutation = useMutation(deleteEnterpriseInfo, {
        onSuccess: () => {
            queryClient.invalidateQueries('useEnterpriseListQuery');
        },
    });

    const deleteClick = (v: EnterpriseResponse) => {
        if (window.confirm('삭제하시겠습니까?')) {
            const params = {
                no: v.ENTP_NO,
                delYn: 'Y',
            };
            deleteMutation.mutate(params);
        }
    };

    const setList = (page: number) => {
        setCurrListPage(page);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (currListPage > 1) {
                setCurrListPage(1);
            } else {
                refetch();
            }
        }
    };

    const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const onSearch = () => {
        if (currListPage > 1) {
            setCurrListPage(1);
        } else {
            refetch();
        }
    };

    useEffect(() => {
        refetch();
    }, [currListPage, refetch]);

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Container>
                    <TitleArea>
                        <Title>
                            사업장리스트({data?.data.ResultData.totalElements})
                        </Title>
                        <SearchArea>
                            <SearchInput
                                placeholder="검색어를 입력하세요."
                                onKeyDown={onKeyDown}
                                value={searchText}
                                onChange={onSearchChange}
                            />
                            <SearchBtn onClick={onSearch} />
                        </SearchArea>
                    </TitleArea>
                    <TableArea>
                        <Table>
                            <colgroup>
                                <col style={{ width: '192px' }} />
                                <col style={{ width: '146px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '432px' }} />
                                <col style={{ width: '80px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>사업장명</Th>
                                    <Th>연락처</Th>
                                    <Th>대표자 이름</Th>
                                    <Th>사업장주소</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.content.map(
                                    (v: EnterpriseResponse) => {
                                        return (
                                            <tr key={v.ENTP_NO}>
                                                <Td>
                                                    <EnterpriseTitle
                                                        onClick={() => {
                                                            navigate(
                                                                `/enterprise/${v.bargSn}`,
                                                            );
                                                        }}
                                                    >
                                                        {v.ENTP_NM}
                                                    </EnterpriseTitle>
                                                </Td>
                                                <Td>{v.REP_TEL_NO}</Td>
                                                <Td>{v.REP_NM}</Td>
                                                <Td>{v.ADDR}</Td>
                                                <Td>
                                                    <DeleteRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            deleteClick(v);
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                    {data?.data.ResultData.totalElements > 0 && (
                        <Pagination
                            itemsCount={data?.data.ResultData.totalElements}
                            pageSize={pageSize}
                            currPage={currListPage}
                            setList={setList}
                        />
                    )}
                </Container>
            )}
        </>
    );
}

export default React.memo(Enterprise);
