import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import { useQuery } from 'react-query';

const getProductReportApi = (page: number, size: number) => {
    return axiosApiInstance.get(
        apiUrl +
            '/market/v1/products/report?size=' +
            size +
            '&page=' +
            (page - 1),
    );
};

export const useProductReportListQuery = (
    currListPage: number,
    pageSize: number,
) =>
    useQuery(
        ['useProductReportListQuery'],
        () => getProductReportApi(currListPage, pageSize),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const deleteProductReportQuery = (sn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/products/review',
        method: 'DELETE',
        data: {
            sn,
        },
    });

const getProductReportDetailApi = (reportSn: number) => {
    return axiosApiInstance({
        url: apiUrl + '/market/v1/products/report/detail',
        method: 'GET',
        params: {
            reportSn,
        },
    });
};

export const useProductReportDetailQuery = (reportSn: number) =>
    useQuery(
        ['useProductReportDetailQuery'],
        () => getProductReportDetailApi(reportSn),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const blockMember = (memberNo: string) =>
    axiosApiInstance({
        url: apiUrl + '/api/block-member',
        method: 'POST',
        data: {
            memberNo,
        },
    });
