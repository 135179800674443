import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    CloudUploadRounded,
    DeleteRounded,
    EditRounded,
    VisibilityOffRounded,
    VisibilityRounded,
} from '@mui/icons-material';
import styled from '@emotion/styled';
import { checkImageExt } from '../../utils/util';
import { useRecoilValue } from 'recoil';
import { loginState } from '../common/atom';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteAdSmart,
    insertAdSmart,
    modifyAdSmart,
    setAdSmartOrderChange,
    setAdSmartVisibility,
    useAdSmartQuery,
} from '../../hooks/ad/useAd';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

function AdSmart() {
    const userId = useRecoilValue(loginState);
    const [adSmartDialogOpen, setAdSmartDialogOpen] = useState(false);
    const [editSn, setEditSn] = useState(0);
    const [company, setCompany] = useState('');
    const [url, setUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const [description, setDescription] = useState('');

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useAdSmartQuery();

    const deleteAdSmartMutation = useMutation(deleteAdSmart, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdSmartQuery');
        },
    });

    const insertAdSmartMutation = useMutation(insertAdSmart, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdSmartQuery');
        },
    });

    const modifyAdSmartMutation = useMutation(modifyAdSmart, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdSmartQuery');
        },
    });

    const setAdSmartVisibilityMutation = useMutation(setAdSmartVisibility, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdSmartQuery');
        },
    });

    const setAdSmartOrderChangeMutation = useMutation(setAdSmartOrderChange, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdSmartQuery');
        },
    });

    const handleClose = () => {
        setAdSmartDialogOpen(false);
        setEditSn(0);
        setCompany('');
        setFileName('');
        setUrl('');
        setDescription('');
    };

    const handleClickOpen = () => {
        setAdSmartDialogOpen(true);
    };
    const editClick = (v) => {
        setAdSmartDialogOpen(true);
        setEditSn(v.sn);
        setCompany(v.company);
        setUrl(v.url);
        setDescription(v.description);
    };
    const deleteClick = (v) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteAdSmartMutation.mutate(v.sn);
        }
    };
    const onModifyAdSmart = () => {
        if (company === '') {
            alert('회사명을 입력해 주세요.');
            return false;
        }
        if (description === '') {
            alert('구분명을 입력해 주세요.');
            return false;
        }
        let params = {
            adSmartSn: editSn,
            company,
            url,
            adminId: userId,
            fileName,
            description,
        };
        modifyAdSmartMutation.mutate(params);
        handleClose();
    };
    const onSubmitAdSmart = () => {
        if (company === '') {
            alert('회사명을 입력해 주세요.');
            return false;
        }
        if (description === '') {
            alert('구분명을 입력해 주세요.');
            return false;
        }
        if (fileName === '') {
            alert('이미지를 선택해 주세요.');
            return false;
        }
        let params = {
            company,
            url,
            adminId: userId,
            fileName,
            description,
        };
        insertAdSmartMutation.mutate(params);
        handleClose();
    };
    const handleChangeImage = (v) => {
        let fileName = v.target.value;
        if (!checkImageExt(fileName.substring(fileName.lastIndexOf('.') + 1))) {
            alert('이미지 파일만 첨부 가능합니다.');
            return false;
        }
        setFileName(fileName.substring(fileName.lastIndexOf('\\') + 1));
    };

    const setAdVisibility = (v, isVisibility) => {
        let msg = '상단배너를 노출하시겠습니까?';
        if (isVisibility === 'N') {
            msg = '상단배너를 비노출하시겠습니까?';
        }
        if (window.confirm(msg)) {
            let params = {
                adSmartSn: v.sn,
                isVisibility,
            };
            setAdSmartVisibilityMutation.mutate(params);
        }
    };

    const orderChange = (v, arrow) => {
        let text = '아래로';
        if (arrow === 'U') {
            text = '위로';
            if (v.orderSn === 1) {
                return false;
            }
        } else {
            if (v.orderSn === data.data.ResultData.length) {
                return false;
            }
        }
        if (window.confirm('상단배너 순서를 ' + text + ' 변경하시겠습니까?')) {
            let params = {
                adSmartSn: v.sn,
                arrow,
            };
            setAdSmartOrderChangeMutation.mutate(params);
        }
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        스마트 줄서기 보드 배너 관리
                    </Title>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '325px' }} />
                                <col style={{ width: '125px' }} />
                                <col style={{ width: '125px' }} />
                                <col style={{ width: '80px' }} />
                                <col style={{ width: '160px' }} />
                                <col style={{ width: '90px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>회사명</Th>
                                    <Th>구분명</Th>
                                    <Th>배너이미지</Th>
                                    <Th>등록자</Th>
                                    <Th>등록일</Th>
                                    <Th>노출여부</Th>
                                    <Th>순서변경</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.data.ResultData.map((v, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <Td>{v.company}</Td>
                                            <Td>{v.description}</Td>
                                            <Td
                                                style={{
                                                    textAlign: 'left',
                                                }}
                                            >
                                                <a
                                                    rel="noreferrer noopener nofollow"
                                                    href={v.url}
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={v.imageUrl}
                                                        width="300px"
                                                        alt="배너 이미지"
                                                    />
                                                </a>
                                            </Td>
                                            <Td>{v.createdId}</Td>
                                            <Td>{v.regDt}</Td>
                                            <Td>
                                                {v.showYn === 'Y' && (
                                                    <VisibilityRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            setAdVisibility(
                                                                v,
                                                                'N',
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {v.showYn === 'N' && (
                                                    <VisibilityOffRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            setAdVisibility(
                                                                v,
                                                                'Y',
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </Td>
                                            <Td>
                                                <ArrowUpwardRounded
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        orderChange(v, 'U');
                                                    }}
                                                />
                                                <ArrowDownwardRounded
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        orderChange(v, 'D');
                                                    }}
                                                />
                                            </Td>
                                            <Td>
                                                <EditRounded
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        editClick(v);
                                                    }}
                                                />
                                                <DeleteRounded
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        deleteClick(v);
                                                    }}
                                                />
                                            </Td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </TableArea>
                    <Dialog
                        open={adSmartDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            상단 배너
                            {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    id="standard-basic"
                                    label="회사명"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={company}
                                    onChange={(e) => {
                                        setCompany(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="description"
                                    label="구분명"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="url"
                                    label="URL"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={url}
                                    onChange={(e) => {
                                        setUrl(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="fileName"
                                    label="이미지"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={fileName}
                                    onChange={(e) => {
                                        setFileName(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadRounded />}
                                >
                                    Upload
                                    <input
                                        id={'file-input'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="file"
                                        accept="image/gif,image/jpeg,image/png,image/jpg"
                                        onChange={handleChangeImage}
                                    />
                                </Button>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button
                                    onClick={onModifyAdSmart}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button
                                    onClick={onSubmitAdSmart}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
}

export default React.memo(AdSmart);
