import React, { ChangeEvent, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import {
    CloudUpload,
    DeleteRounded,
    EditRounded,
    SendRounded,
} from '@mui/icons-material';
import { checkImageExt } from '../../utils/util';
import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteManagerNotice,
    insertManagerNotice,
    modifyManagerNotice,
    sendManagerNoticePush,
    useManagerNoticeListQuery,
} from '../../hooks/notice/useNotice';
import { useRecoilValue } from 'recoil';
import { loginState } from '../common/atom';
import { ManagerNoticeData } from '../typings/notice/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
    float: left;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const ManagerNotice = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [noticeSn, setNoticeSn] = useState(0);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [url, setUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const userId = useRecoilValue(loginState);

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useManagerNoticeListQuery();

    const insertManagerNoticeMutation = useMutation(insertManagerNotice, {
        onSuccess: () => {
            queryClient.invalidateQueries('useManagerNoticeListQuery');
        },
    });

    const modifyManagerNoticeMutation = useMutation(modifyManagerNotice, {
        onSuccess: () => {
            queryClient.invalidateQueries('useManagerNoticeListQuery');
        },
    });

    const deleteManagerNoticeMutation = useMutation(deleteManagerNotice, {
        onSuccess: () => {
            queryClient.invalidateQueries('useManagerNoticeListQuery');
        },
    });

    const sendManagerNoticePushMutation = useMutation(sendManagerNoticePush, {
        onSuccess: () => {
            queryClient.invalidateQueries('useManagerNoticeListQuery');
        },
    });

    const handleClose = () => {
        setDialogOpen(false);
        setNoticeSn(0);
        setTitle('');
        setMessage('');
        setUrl('');
        setFileName('');
    };

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const editClick = (v: ManagerNoticeData) => {
        setDialogOpen(true);
        setNoticeSn(v.noticeId);
        setTitle(v.title);
        setMessage(v.message);
        setUrl(v.url);
    };

    const deleteClick = (v: ManagerNoticeData) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteManagerNoticeMutation.mutate(v.noticeId);
        }
    };

    const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
        let fileName = e.target.value;
        if (!checkImageExt(fileName.substring(fileName.lastIndexOf('.') + 1))) {
            alert('이미지 파일만 첨부 가능합니다.');
            return false;
        }
        setFileName(fileName.substring(fileName.lastIndexOf('\\') + 1));
    };
    const onModifyNotice = () => {
        if (title === '') {
            alert('제목을 입력해 주세요.');
            return false;
        }
        let params = {
            noticeSn,
            title,
            message,
            fileName,
            url,
            userId,
        };
        modifyManagerNoticeMutation.mutate(params);
        handleClose();
    };
    const onSubmitNotice = () => {
        if (title === '') {
            alert('제목을 입력해 주세요.');
            return false;
        }
        let params = {
            title,
            message,
            url,
            userId,
        };
        insertManagerNoticeMutation.mutate(params);
        handleClose();
    };

    const sendManagerPush = (v: ManagerNoticeData) => {
        if (window.confirm('사업자에게 공지사항 알림을 전송하시겠습니까?')) {
            sendManagerNoticePushMutation.mutate(v.noticeId);
        }
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        사업자 공지사항 게시판
                    </Title>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '105px' }} />
                                <col style={{ width: '170px' }} />
                                <col style={{ width: '275px' }} />
                                <col style={{ width: '125px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '100px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>제목</Th>
                                    <Th>내용</Th>
                                    <Th>이미지</Th>
                                    <Th>등록자</Th>
                                    <Th>등록일</Th>
                                    <Th>알림발송</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.content.length > 0 &&
                                    data?.data.ResultData.content.map(
                                        (v: ManagerNoticeData) => {
                                            return (
                                                <tr key={v.noticeId}>
                                                    <Td>{v.title}</Td>
                                                    <Td>{v.message}</Td>
                                                    <Td>
                                                        {v.imgUrl && (
                                                            <a
                                                                rel="noreferrer noopener nofollow"
                                                                href={v.url}
                                                                target="_blank"
                                                            >
                                                                <img
                                                                    src={
                                                                        v.imgUrl
                                                                    }
                                                                    width="300px"
                                                                    alt="배너 이미지"
                                                                />
                                                            </a>
                                                        )}
                                                    </Td>
                                                    <Td>{v.createdId}</Td>
                                                    <Td>{v.regDt}</Td>
                                                    <Td>
                                                        {v.sendYn === 'Y' &&
                                                            '발송됨'}
                                                        {v.sendYn === 'N' && (
                                                            <SendRounded
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    sendManagerPush(
                                                                        v,
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </Td>
                                                    <Td>
                                                        <EditRounded
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                editClick(v);
                                                            }}
                                                        />
                                                        <DeleteRounded
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                deleteClick(v);
                                                            }}
                                                        />
                                                    </Td>
                                                </tr>
                                            );
                                        },
                                    )}
                            </tbody>
                        </Table>
                    </TableArea>
                    <Dialog
                        open={dialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            사업자 공지사항 게시판
                            {noticeSn === 0 && ' 등록'}
                            {noticeSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    id="standard-basic"
                                    label="제목"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="standard-basic"
                                    label="내용"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="standard-basic"
                                    label="URL"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={url}
                                    onChange={(e) => {
                                        setUrl(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="fileName"
                                    label="이미지"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={fileName}
                                    onChange={(e) => {
                                        setFileName(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUpload />}
                                >
                                    Upload
                                    <input
                                        id={'file-notice-input'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="file"
                                        accept="image/gif,image/jpeg,image/png,image/jpg"
                                        onChange={handleChangeImage}
                                    />
                                </Button>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {noticeSn > 0 && (
                                <Button
                                    onClick={onModifyNotice}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {noticeSn === 0 && (
                                <Button
                                    onClick={onSubmitNotice}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
};

export default React.memo(ManagerNotice);
