import React, { useState } from 'react';
import styled from '@emotion/styled';
import profileImage from '../../assets/profile.png';
import { useRecoilState } from 'recoil';
import { loginState } from './atom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { apiUrl } from '../../utils/common';
import axiosApiInstance from '../../utils/axiosInterceptor';
import { AutorenewRounded } from '@mui/icons-material';
import HoverMenu from './HoverMenu';
import { getUserInfoValue } from '../../utils/util';

const Container = styled.div`
    width: 1200px;
    margin: 0 auto;
`;
const Wrapper = styled.div`
    float: left;
    width: 220px;
`;

const LoginInfo = styled.div`
    width: 100%;
    height: 200px;
    background: #2d518e;
`;

const UserId = styled.span`
    cursor: pointer;
`;

const Ul = styled.ul`
    list-style: none;
    text-align: center;
    padding-top: 35px;
`;
const Li = styled.li`
    &:first-of-type {
        display: inline-block;
        width: 60px;
        height: 60px;
    }
    &:nth-of-type(2) {
        font-size: 14px;
        color: #fff;
        padding: 15px 0;
    }
`;

const SubMenu = styled.div`
    position: relative;
`;

const Logout = styled.button`
    width: 66px;
    height: 18px;
    line-height: 18px;
    font-size: 11px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(221, 221, 221);
    cursor: pointer;
`;

function Nav() {
    const [userId, setUserId] = useRecoilState(loginState);
    const [popOpen, setPopOpen] = useState(false);
    const [currPassword, setCurrPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordChk, setNewPasswordChk] = useState('');

    const authority = getUserInfoValue('authority');

    const handleOpen = () => {
        setPopOpen(true);
    };

    const handleClick = () => {
        window.localStorage.removeItem('userInfo');
        setUserId('');
    };

    const handleClose = () => {
        setPopOpen(false);
        setCurrPassword('');
        setNewPassword('');
        setNewPasswordChk('');
    };

    const onPasswordChange = async () => {
        if (
            currPassword === '' ||
            newPassword === '' ||
            newPasswordChk === ''
        ) {
            alert('비밀번호를 입력해 주세요.');
            return false;
        }
        if (newPassword.length < 8) {
            alert('8자리 이상 입력해 주세요.');
            return false;
        }
        if (newPassword === newPasswordChk) {
            await axiosApiInstance
                .put(apiUrl + '/api/manager/pwd', {
                    id: userId,
                    password: currPassword,
                    newPassword,
                })
                .then((res) => {
                    if (res.data.ResultCode === 1) {
                        alert('비밀번호가 변경되었습니다.');
                        handleClose();
                    } else {
                        if (res.data.ResultData === 'SAME PASSWORD') {
                            alert(
                                '현재 비밀번호와 같은 비밀번호로는 변경할 수 없습니다.',
                            );
                        }
                        if (res.data.ResultData === 'PASSWORD ERROR') {
                            alert('현재 비밀번호가 일치하지 않습니다.');
                        }
                    }
                });
        } else {
            alert('비밀번호를 확인해 주세요.');
        }
    };

    const reloadLeppaMarketList = async () => {
        try {
            const response = await axiosApiInstance({
                url: apiUrl + '/market/v1/leppa-market-list/reload',
                method: 'POST',
            });
            if (response.data.ResultCode === 1) {
                alert('레빠마켓 상품리스트 업데이트 성공');
            } else {
                alert('레빠마켓 상품리스트 업데이트에러');
            }
        } catch (e) {
            alert('레빠마켓 상품리스트 업데이트에러');
            console.error(e);
        }
    };

    return (
        <>
            <Container>
                <Wrapper>
                    <LoginInfo>
                        <Ul>
                            <Li>
                                <img src={profileImage} alt="프로필이미지" />
                            </Li>
                            <Li>
                                <UserId onClick={handleOpen}>{userId}</UserId>
                            </Li>
                            <Li>
                                <Logout onClick={handleClick}>로그아웃</Logout>
                            </Li>
                        </Ul>
                    </LoginInfo>
                    <SubMenu>
                        <HoverMenu
                            title={'사업장 관리'}
                            items={[
                                {
                                    title: '사업장리스트',
                                    url: '/',
                                },
                                {
                                    title: '예약 리스트',
                                    url: '/reservation',
                                },
                                {
                                    title: '사업장 로그 조회',
                                    url: '/company-log',
                                },
                            ]}
                            idx={0}
                        />
                        <HoverMenu
                            title={'홈페이지 관리'}
                            items={[
                                {
                                    title: '뉴스 & 이벤트 관리',
                                    url: '/news',
                                },
                                {
                                    title: '투스잼 콘텐츠 관리',
                                    url: '/tooth-jam-content',
                                },
                            ]}
                            idx={1}
                        />
                        <HoverMenu
                            title={'공지사항 관리'}
                            items={[
                                {
                                    title: '공지사항 관리',
                                    url: '/notice',
                                },
                                {
                                    title: '사업자 공지사항 관리',
                                    url: '/manager_notice',
                                },
                            ]}
                            idx={2}
                        />
                        <HoverMenu
                            title={'신고 관리'}
                            items={[
                                {
                                    title: '상품 신고 관리',
                                    url: '/product',
                                },
                                {
                                    title: '리뷰 신고 관리',
                                    url: '/review-report',
                                },
                            ]}
                            idx={3}
                            addBtn={
                                <AutorenewRounded
                                    style={{
                                        cursor: 'pointer',
                                        verticalAlign: 'middle',
                                    }}
                                    titleAccess="레빠마켓상품 업데이트"
                                    onClick={() => {
                                        reloadLeppaMarketList();
                                    }}
                                />
                            }
                        />
                        <HoverMenu
                            title={'광고 관리'}
                            items={[
                                {
                                    title: '통합 광고 배너 관리',
                                    url: '/ad-banner',
                                },
                                {
                                    title: '알림 관리',
                                    url: '/ad-alarm',
                                },
                                {
                                    title: '채팅목록 광고 관리',
                                    url: '/ad-chat',
                                },
                                {
                                    title: '메인 광고 문구 관리',
                                    url: '/ad-text',
                                },
                                {
                                    title: '스마트 줄서기 광고 관리',
                                    url: '/ad-smart',
                                },
                                {
                                    title: '프리마켓 상단 광고 관리',
                                    url: '/ad-market-top',
                                },
                                {
                                    title: '핫 플레이스 관리',
                                    url: '/company-top-list',
                                },
                                {
                                    title: '레빠 단독 구성 상품 관리',
                                    url: '/company-promotion-list',
                                },
                                {
                                    title: '마케팅 PUSH알림 관리',
                                    url: '/ad-marketing-push',
                                },
                            ]}
                            idx={4}
                        />
                        <HoverMenu
                            title={'키워드 관리'}
                            items={[
                                {
                                    title: '메인화면 키워드 관리',
                                    url: '/main-keyword',
                                },
                                {
                                    title: '연관검색어 관리',
                                    url: '/reservation-keyword',
                                },
                                {
                                    title: '연관검색어 노출 관리',
                                    url: '/reservation-keyword-top',
                                },
                                {
                                    title: '사업장 추천 키워드 관리',
                                    url: '/company-recommend',
                                },
                                {
                                    title: '대분류 관리',
                                    url: '/reservation-large-category',
                                },
                                {
                                    title: '중분류 관리',
                                    url: '/reservation-middle-classification',
                                },
                                {
                                    title: '일반상품 종목 관리',
                                    url: '/product-classification',
                                },
                                {
                                    title: '일반상품 종목 매핑 관리',
                                    url: '/product-classification-mapping',
                                },
                            ]}
                            idx={5}
                        />
                        <HoverMenu
                            title={'카테고리 관리'}
                            items={[
                                {
                                    title: '프리마켓 카테고리 관리',
                                    url: '/category',
                                },
                                {
                                    title: '프리마켓 상단 카테고리 관리',
                                    url: '/free-market-top-category',
                                },
                                {
                                    title: '레빠마켓 카테고리 관리',
                                    url: '/leppa-market-category',
                                },
                            ]}
                            idx={6}
                        />
                        <HoverMenu
                            title={'레빠마켓 관리'}
                            items={[
                                {
                                    title: '주문 관리',
                                    url: '/leppa-market-orders',
                                },
                                {
                                    title: '상품 관리',
                                    url: '/leppa-market-items',
                                },
                            ]}
                            idx={7}
                        />
                        <HoverMenu
                            title={'레빠플레이 관리'}
                            items={[
                                {
                                    title: '레빠플레이 관리',
                                    url: '/leppa-play',
                                },
                                {
                                    title: '레빠플레이 타입 관리',
                                    url: '/leppa-play-type',
                                },
                                {
                                    title: '추천 영상 관리',
                                    url: '/leppa-play-recommend',
                                },
                            ]}
                            idx={8}
                        />
                        {authority !== 'ROLE_USER' && (
                            <HoverMenu
                                title={'포인트 관리'}
                                items={[
                                    {
                                        title: '포인트 관리',
                                        url: '/point',
                                    },
                                ]}
                                idx={9}
                            />
                        )}
                        {authority !== 'ROLE_USER' && (
                            <HoverMenu
                                title={'사용자 관리'}
                                items={[
                                    {
                                        title: '사용자 관리',
                                        url: '/users',
                                    },
                                ]}
                                idx={10}
                            />
                        )}
                    </SubMenu>
                </Wrapper>
            </Container>
            <Dialog
                open={popOpen}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
            >
                <DialogTitle id="form-dialog-title">비밀번호 변경</DialogTitle>
                <DialogContent>
                    <TextField
                        id="password-current"
                        type="password"
                        label="현재 비밀번호"
                        value={currPassword}
                        onChange={(e) => {
                            setCurrPassword(e.target.value);
                        }}
                        fullWidth
                        style={{ margin: '8px 0px 8px 0px' }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        id="password-new"
                        type="password"
                        label="비밀번호"
                        value={newPassword}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                        }}
                        fullWidth
                        style={{ margin: '8px 0px 8px 0px' }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        id="password-new-chk"
                        type="password"
                        label="비밀번호 확인"
                        value={newPasswordChk}
                        onChange={(e) => {
                            setNewPasswordChk(e.target.value);
                        }}
                        fullWidth
                        style={{ margin: '8px 0px 8px 0px' }}
                        InputLabelProps={{ shrink: true }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onPasswordChange} color="primary">
                        변경
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        취소
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default React.memo(Nav);
