import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import { UseQueryResult, useQuery } from 'react-query';
import {
    InsertLeppaMarketItemRequest,
    InsertLeppaMarketTopAdvertisementRequest,
    LeppaGiftStatus,
    LeppaMarketAdTopDragOrderChangeRequest,
    LeppaMarketAdTopOrderChangeRequest,
    LeppaMarketAdTopVisibilityRequest,
    LeppaMarketItemAddImageRequest,
    LeppaMarketItemDeleteImageRequest,
    LeppaMarketItemImageOrderChangeRequest,
    LeppaMarketItemOptionsOrderChangeRequest,
    LeppaMarketItemVisibilityRequest,
    LeppaOrderStatus,
    ManualCancelRequest,
    ManualCompleteRequest,
    ModifyLeppaMarketTopAdvertisementRequest,
    UpdateItemInfoRequest,
} from '../../components/typings/leppa-market/types';
import { ReviewReportDetailData } from '../../components/typings/leppa-market/response';
import { AxiosResponse } from 'axios';
import {
    CategoryResponse,
    OrderDetailResponse,
} from '../../components/typings/leppa-market/ordersDetail';

const getLeppaMarketTopApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/top',
        method: 'GET',
    });
};

export const useLeppaMarketTopQuery = () =>
    useQuery(['useLeppaMarketTopQuery'], () => getLeppaMarketTopApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const insertLeppaMarketAdTop = (
    params: InsertLeppaMarketTopAdvertisementRequest,
) => {
    let formData = new FormData();
    const file = document.getElementById('file-input') as HTMLInputElement;
    if (file && file.files) {
        formData.append('file', file.files[0]);
    }

    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('description', params.description);

    return axiosApiInstance.post(
        apiUrl + '/api/v1/biz/leppa-market/top',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const modifyLeppaMarketAdTop = (
    params: ModifyLeppaMarketTopAdvertisementRequest,
) => {
    let formData = new FormData();
    if (params.fileName !== '') {
        const file = document.getElementById('file-input') as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append('adTopSn', params.adTopSn.toString());
    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('description', params.description);

    return axiosApiInstance.put(
        apiUrl + '/api/v1/biz/leppa-market/top',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const deleteLeppaMarketAdTop = (adTopSn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/top',
        method: 'DELETE',
        data: {
            adTopSn,
        },
    });

export const setLeppaMarketAdTopVisibility = (
    params: LeppaMarketAdTopVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/top/visibility',
        method: 'PUT',
        data: params,
    });

export const setLeppaMarketAdTopOrderChange = (
    params: LeppaMarketAdTopOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/top/order-change',
        method: 'PUT',
        data: params,
    });

export const setLeppaMarketAdTopDragOrderChange = (
    params: LeppaMarketAdTopDragOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/top/order-change/drag',
        method: 'PUT',
        data: params,
    });

const getLeppaMarketOrderListApi = (
    page: number,
    size: number,
    status: LeppaOrderStatus,
    giftYn: LeppaGiftStatus,
    category: any,
    deliveryDateStart: string,
    deliveryDateEnd: string,
) => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/orders',
        method: 'GET',
        params: {
            page,
            size,
            status,
            giftYn,
            category,
            deliveryDateStart,
            deliveryDateEnd,
        },
    });
};

export const useLeppaMarketOrderListQuery = (
    page: number,
    size: number,
    status: LeppaOrderStatus,
    giftYn: LeppaGiftStatus,
    category: any,
    deliveryDateStart: string,
    deliveryDateEnd: string,
) =>
    useQuery(
        ['useLeppaMarketOrderListQuery'],
        () =>
            getLeppaMarketOrderListApi(
                page,
                size,
                status,
                giftYn,
                category,
                deliveryDateStart,
                deliveryDateEnd,
            ),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

const getDeliveryReadyApi = (status: LeppaOrderStatus) => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/orders',
        method: 'GET',
        params: {
            status,
        },
    });
};

export const useDeliveryReadyQuery = (status: LeppaOrderStatus) =>
    useQuery(['useDeliveryReadyQuery'], () => getDeliveryReadyApi(status), {
        retry: 3,
        keepPreviousData: true,
    });

const getDeliveryProgressApi = (status: LeppaOrderStatus) => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/orders',
        method: 'GET',
        params: {
            status,
        },
    });
};

export const useDeliveryProgressQuery = (status: LeppaOrderStatus) =>
    useQuery(
        ['useDeliveryProgressQuery'],
        () => getDeliveryProgressApi(status),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

const getDeliveryCompleteApi = (status: LeppaOrderStatus) => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/orders',
        method: 'GET',
        params: {
            status,
        },
    });
};

export const useDeliveryCompleteQuery = (status: LeppaOrderStatus) =>
    useQuery(
        ['useDeliveryCompleteQuery'],
        () => getDeliveryCompleteApi(status),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

const getDeliveryApplyApi = (status: LeppaOrderStatus) => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/orders',
        method: 'GET',
        params: {
            status,
        },
    });
};

export const useDeliveryApplyQuery = (status: LeppaOrderStatus) =>
    useQuery(['useDeliveryApplyQuery'], () => getDeliveryApplyApi(status), {
        retry: 3,
        keepPreviousData: true,
    });

const getDelivetyReturnApi = (status: LeppaOrderStatus) => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/orders',
        method: 'GET',
        params: {
            status,
        },
    });
};

export const useDeliveryReturnQuery = (status: LeppaOrderStatus) =>
    useQuery(['useDeliveryReturnQuery'], () => getDelivetyReturnApi(status), {
        retry: 3,
        keepPreviousData: true,
    });

export const uploadInvoiceFile = () => {
    let formData = new FormData();
    const file = document.getElementById('file-input') as HTMLInputElement;
    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    return axiosApiInstance.post(
        apiUrl + '/api/v1/biz/leppa-market/invoice-number/update',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const getDeliveryReadyExcel = () =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/orders/delivery-ready/down',
        method: 'GET',
        responseType: 'blob',
        headers: { responseType: 'arraybuffer' },
    });

const getLeppaMarketItemsApi = (
    currListPage: number,
    pageSize: number,
    searchValue: string,
) => {
    return axiosApiInstance.get(
        apiUrl +
            '/api/v1/biz/leppa-market/items?size=' +
            pageSize +
            '&page=' +
            (currListPage - 1) +
            (!!searchValue ? '&search=' + searchValue : ''),
    );
};

export const useLeppaMarketItemsQuery = (
    currListPage: number,
    pageSize: number,
    searchValue: string,
) =>
    useQuery(
        ['useLeppaMarketItemsQuery'],
        () => getLeppaMarketItemsApi(currListPage, pageSize, searchValue),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const setLeppaMarketItemVisibility = (
    params: LeppaMarketItemVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/item/visibility',
        method: 'POST',
        data: params,
    });

const getLeppaMarketItemDetailApi = (itemId: string) => {
    return axiosApiInstance.get(
        apiUrl + `/api/v1/biz/leppa-market/items/${itemId}`,
    );
};

export const useLeppaMarketItemDetailQuery = (itemId: string) =>
    useQuery(
        ['useLeppaMarketItemDetailQuery'],
        () => getLeppaMarketItemDetailApi(itemId),
        {
            retry: 3,
        },
    );

export const setLeppaMarketItemImageOrderChange = (
    params: LeppaMarketItemImageOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/item/images/change-order',
        method: 'POST',
        data: params,
    });

export const setLeppaMarketItemDeleteImage = (
    params: LeppaMarketItemDeleteImageRequest,
) => {
    let formData = new FormData();

    formData.append('itemId', params.itemId);
    let key = 'deleteDetailImages';
    if (params.imageType === 'TOP') {
        key = 'deleteTopImages';
    }
    formData.append(key, params.imageId.toString());
    return axiosApiInstance.post(
        apiUrl + '/api/v1/biz/leppa-market/item/image',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const setLeppaMarketItemAddImage = (
    params: LeppaMarketItemAddImageRequest,
) => {
    let formData = new FormData();
    let fileInput = document.getElementById('file-detail') as HTMLInputElement;
    if (fileInput && fileInput.files) {
        let files = fileInput.files;
        let key = 'addDetailImages';
        if (params.imageType === 'TOP') {
            key = 'addTopImages';
        }
        for (let i = 0, cnt = files.length; i < cnt; i++) {
            formData.append(key, files[i]);
        }
    }
    formData.append('itemId', params.itemId);
    return axiosApiInstance.post(
        apiUrl + '/api/v1/biz/leppa-market/item/image',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

const getLeppaMarketCategoryApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/main/category',
        method: 'GET',
    });
};

export const useLeppaMarketCategoryQuery = () =>
    useQuery(
        ['useLeppaMarketCategoryQuery'],
        () => getLeppaMarketCategoryApi(),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const updateItemInfo = (params: UpdateItemInfoRequest) => {
    let formData = new FormData();
    if (params.fileName !== '') {
        const file = document.getElementById('file-main') as HTMLInputElement;
        if (file && file.files) {
            formData.append('mainImage', file.files[0]);
        }
    }
    formData.append('itemId', params.itemId);
    formData.append('title', params.title);
    formData.append('description', params.description);
    formData.append('price', params.price.toString());
    formData.append('discount', params.discount.toString());
    formData.append('categorySn', params.categorySn.toString());
    if (params.modifyOptions) {
        formData.append('modifyOptions', params.modifyOptions);
    }
    if (params.addOptions) {
        formData.append('addOptions', params.addOptions);
    }
    if (params.deleteOptions) {
        formData.append('deleteOptions', params.deleteOptions);
    }

    return axiosApiInstance.put(
        apiUrl + '/api/v1/biz/leppa-market/item',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const setLeppaMarketItemOptionsOrderChange = (
    params: LeppaMarketItemOptionsOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/item/options/change-order',
        method: 'POST',
        data: params,
    });

export const insertItem = (params: InsertLeppaMarketItemRequest) => {
    let formData = new FormData();
    const file = document.getElementById('file-main') as HTMLInputElement;
    if (file && file.files) {
        formData.append('mainImage', file.files[0]);
    }
    formData.append('title', params.title);
    formData.append('description', params.description);
    formData.append('price', params.price.toString());
    formData.append('discount', params.discount.toString());
    formData.append('categorySn', params.categorySn.toString());
    formData.append('options', params.options);

    let fileInput = document.getElementById('file-detail') as HTMLInputElement;
    if (fileInput && fileInput.files) {
        let files = fileInput.files;
        for (let i = 0, cnt = files.length; i < cnt; i++) {
            formData.append('detailImages', files[i]);
        }
    }

    let topFileInput = document.getElementById('file-top') as HTMLInputElement;
    if (topFileInput && topFileInput.files) {
        let topFiles = topFileInput.files;
        for (let i = 0, cnt = topFiles.length; i < cnt; i++) {
            formData.append('topImages', topFiles[i]);
        }
    }

    return axiosApiInstance.post(
        apiUrl + '/api/v1/biz/leppa-market/item',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const setManualDeliveryComplete = (params: ManualCompleteRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/manual-delivery-complete',
        method: 'PUT',
        data: params,
    });

export const setManualCancel = (params: ManualCancelRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/manual-cancel',
        method: 'PUT',
        data: params,
    });

export const setManualReturnComplete = (params: ManualCompleteRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/manual-return-complete',
        method: 'PUT',
        data: params,
    });

const getReviewReportApi = (page: number, size: number) => {
    return axiosApiInstance.get(
        apiUrl +
            `/api/v1/biz/market-review/report?size=${size}&page=${page - 1}`,
    );
};

export const useReviewReportListQuery = (
    currListPage: number,
    pageSize: number,
) =>
    useQuery(
        ['useReviewReportListQuery'],
        () => getReviewReportApi(currListPage, pageSize),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const deleteReviewReportQuery = (reviewId: string) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/market-review/delete',
        method: 'POST',
        data: {
            reviewId,
        },
    });

const getReviewReportDetailApi = (
    reportId: number,
): Promise<
    AxiosResponse<{
        ResultData: ReviewReportDetailData;
        ResultCode: number;
    }>
> => {
    return axiosApiInstance({
        url: apiUrl + `/api/v1/biz/market-review/report/${reportId}`,
        method: 'GET',
    });
};

export const useReviewReportDetailQuery = (reportId: number) =>
    useQuery(
        ['useReviewReportDetailQuery'],
        () => getReviewReportDetailApi(reportId),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

const ordersDetailApi = (orderId: string) => {
    return axiosApiInstance({
        url: apiUrl + `/api/v1/biz/leppa-market/orders/${orderId}`,
        method: 'GET',
    });
};

export const useOrdersDetailQuery = (
    orderId: string,
): UseQueryResult<AxiosResponse<OrderDetailResponse>, Error> =>
    useQuery(['useOrdersDetailQuery'], () => ordersDetailApi(orderId), {
        retry: 3,
        keepPreviousData: true,
    });

const getCategoryApi = () => {
    return axiosApiInstance.get(
        apiUrl + `/api/barg/common/cd/list?cd_grp_no=CD020`,
    );
};

export const useGetCategoryQuery = (): UseQueryResult<
    AxiosResponse<CategoryResponse>,
    Error
> =>
    useQuery([useGetCategoryQuery], () => getCategoryApi(), {
        retry: 3,
        keepPreviousData: true,
    });
