import styled from '@emotion/styled';
import icClose from '../../assets/point/icClose.png';
import React, { useCallback, useEffect, useState } from 'react';
import {
    memberPointControl,
    usePointDetailQuery,
    usePointPopupListQuery,
} from '../../hooks/point/usePoint';
import Pagination from '../common/Pagination';
import { LeppaPointResponse } from '../typings/point/response';
import { useMutation, useQueryClient } from 'react-query';

const Base = styled.div`
    position: fixed;
    z-index: 30;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.52);
    width: 100%;
    height: 100vh;
    font-family: SUIT, serif;
`;

const PopUp = styled.div`
    position: absolute;
    width: 50%;
    height: 90%;
    border-radius: 8px;
    background: #fff;
    left: 30%;
    top: 2.5%;
    padding: 40px 40px 0px 40px;
    & > img {
        width: 28px;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
`;

const Header = styled.div`
    & > div {
        color: #25282b;
        font-size: 28px;
        font-weight: 700;
        border-bottom: 2px solid #25282b;
        padding-bottom: 20px;
    }
`;

const BasicInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 15px;
    justify-content: space-between;
`;

const MemberInfo = styled.div`
    & > div:nth-of-type(1) {
        color: #3f464e;
        font-size: 24px;
        font-weight: 700;
    }
    & > div:nth-of-type(2) {
        color: #75808a;
        font-size: 15px;
    }
    & > div:nth-of-type(3) {
        padding-top: 40px;
        display: flex;
        align-items: center;
        & > div:nth-of-type(1) {
            width: 100px;
            color: #75808a;
            font-size: 14px;
            font-weight: 600;
        }
        & > div:nth-of-type(2) {
            color: #3f464e;
            font-size: 14px;
        }
    }
    & > div:nth-of-type(4) {
        padding-top: 11px;
        display: flex;
        align-items: center;
        & > div:nth-of-type(1) {
            width: 100px;
            color: #75808a;
            font-size: 14px;
            font-weight: 600;
        }
        & > div:nth-of-type(2) {
            color: #3f464e;
            font-size: 14px;
        }
    }
`;

const PointArea = styled.div`
    border-top: 1px solid #ebeef0;
    border-bottom: 1px solid #ebeef0;
    & > div {
        display: flex;
        align-items: center;

        & > div:nth-of-type(1) {
            width: 100px;
            background: #f8f9fa;
            color: #3f464e;
            font-size: 14px;
            font-weight: 600;
            padding: 10px 12px;
        }

        & > div:nth-of-type(2) {
            width: 70px;
            background: #f8f9fa;
            color: #59a6f5;
            text-align: right;
            font-size: 14px;
            font-weight: 600;
            padding: 10px 12px;
        }

        & > div:nth-of-type(3) {
            width: 150px;
            color: #3f464e;
            text-align: right;
            font-size: 14px;
            padding: 10px 12px;
        }
    }
`;

const DetailArea = styled.div`
    padding-top: 20px;
    & > div {
        font-size: 16px;
        color: #25282b;
        font-weight: 700;
    }
`;

const PointStatus = styled.div`
    display: flex;
    align-items: center;
    border-radius: 16px;
    background: #f8f9fa;
    height: 80px;
    & > div {
        width: 33%;
        text-align: center;
        position: relative;
        &:nth-of-type(1):after,
        &:nth-of-type(2):after {
            content: '';
            position: absolute;
            top: 30%;
            right: 0;
            height: 50%;
            width: 2px;
            border-right: 2px solid #ebeef0;
        }

        & > div:nth-of-type(1) {
            color: #75808a;
            font-size: 14px;
        }

        & > div:nth-of-type(2) {
            color: #25282b;
            font-size: 24px;
            font-weight: 700;

            & > button {
                border-radius: 4px;
                border: 1px solid #ebeef0;
                background: #ffffff;
                font-size: 14px;
                color: #25282b;
                margin-left: 10px;
                padding: 0 8px;
                cursor: pointer;
            }
        }
    }
`;

const DataContent = styled.div`
    & > div {
        display: flex;
        align-items: center;
    }
`;

const DataArea = styled.div`
    padding-top: 16px;
    display: flex;
    align-items: center;
`;

const ButtonArea = styled.div`
    & > button {
        position: absolute;
        right: 30px;
        bottom: 30px;
        width: 144px;
        height: 46px;
        padding: 0 8px;
        border-radius: 8px;
        cursor: pointer;
        border: 0;
        color: #ffffff;
        background: #0482de;
        font-size: 16px;
        font-weight: 700;
    }
`;

interface DataHeaderProps {
    width?: number;
}
const DataHeader = styled.div<DataHeaderProps>`
    color: #3f464e;
    border-top: 2px solid #25282b;
    border-bottom: 1px solid #25282b;
    width: ${({ width }) => `${!!width ? width : 80}px`};
    height: 35px;
    font-weight: 400;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
`;

interface DataValueProps {
    width?: number;
    type?: string;
}

const DataValue = styled.div<DataValueProps>`
    color: ${({ type }) =>
        `${type === 'CANCEL' || type === 'MOD' ? '#EA2C4E' : '#3f464e'}`};
    border-bottom: 1px solid #ebeef0;
    width: ${({ width }) => `${!!width ? width : 80}px`};
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
    padding: 10px 0;
    font-weight: 400;

    & > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
`;

interface StatusValueProps {
    bgColor?: string;
    color?: string;
    borderColor?: string;
}

const StatusValue = styled.div<StatusValueProps>`
    padding: 7px 10px;
    align-items: center;
    border-radius: 16px;
    border: 1px solid
        ${({ borderColor }) => `${!!borderColor ? borderColor : 'none'}`};
    background: ${({ bgColor }) => `${!!bgColor ? bgColor : '#F8F9FA'}`};
    color: ${({ color }) => `${!!color ? color : '#3F464E'}`};
`;

const PointControlBase = styled.div`
    position: absolute;
    background: #fff;
    top: 60px;
    left: 140px;
    padding: 28px 24px;
    width: 440px;
    border-radius: 8px;
    border: 1px solid #25282b;

    box-shadow: 0 12px 16px 4px rgba(37, 40, 43, 0.06),
        0 4px 16px 4px rgba(37, 40, 43, 0.08);

    & > div:nth-of-type(1) {
        font-size: 16px;
        font-weight: 700;
        color: #3f464e;
        text-align: left;
        padding-bottom: 20px;
        border-bottom: 1px solid #ebeef0;
    }
    & > div:nth-of-type(2) {
        display: flex;
        align-items: center;
        padding-top: 20px;
        & > div:nth-of-type(1) {
            font-size: 14px;
            font-weight: 600;
            color: #3f464e;
            width: 35%;
            text-align: left;
        }

        & > div:nth-of-type(2) {
            width: 65%;
            & > input {
                padding: 5px 12px;
                border-radius: 4px;
                border: 1px solid #25282b;
                text-align: right;
                width: calc(100% - 24px);
            }

            & > div {
                color: #adb3ba;
                text-align: left;
                font-size: 14px;
                font-weight: 300;
                margin-top: 10px;
            }
        }
    }
    & > div:nth-of-type(3) {
        display: flex;
        padding-top: 20px;

        & > div:nth-of-type(1) {
            font-size: 14px;
            font-weight: 600;
            color: #3f464e;
            width: 35%;
            text-align: left;
        }

        & > div:nth-of-type(2) {
            width: 65%;
        }
    }

    & > div:nth-of-type(4) {
        display: flex;
        padding-top: 20px;
        align-items: center;
        justify-content: right;

        & > button:nth-of-type(1) {
            border-radius: 8px;
            border: 1px solid #ebeef0;
            background: #fff;
            padding: 10px 16px;
            color: #25282b;
            font-size: 14px;
            margin-right: 15px;
            cursor: pointer;
        }

        & > button:nth-of-type(2) {
            border-radius: 8px;
            border: 0;
            background: #0482de;
            padding: 10px 16px;
            color: #ffffff;
            font-weight: 700;
            font-size: 16px;
            cursor: pointer;
        }
    }
`;

interface Props {
    setDetailPopupShow: (v: boolean) => void;
    setEventId: (v: string) => void;
    eventId: string;
    memberNo: string;
}
const PointPopup = ({
    setDetailPopupShow,
    eventId,
    setEventId,
    memberNo,
}: Props) => {
    const closeClick = useCallback(() => {
        setDetailPopupShow(false);
        setEventId('');
    }, [setDetailPopupShow, setEventId]);

    const { isError, isLoading, data } = usePointDetailQuery(eventId);
    const [pointControlPopupShow, setPointControlPopupShow] = useState(false);
    const [amount, setAmount] = useState(0);
    const [description, setDescription] = useState('');

    const pageSize = 5;
    const [currListPage, setCurrListPage] = useState(1);

    const setList = (page: number) => {
        setCurrListPage(page);
    };

    const queryClient = useQueryClient();
    const { data: listData, refetch } = usePointPopupListQuery(
        memberNo,
        currListPage,
        pageSize,
    );

    const memberPointControlMutation = useMutation(memberPointControl, {
        onSuccess: () => {
            queryClient.invalidateQueries('usePointDetailQuery');
            queryClient.invalidateQueries('usePointPopupListQuery');
            queryClient.invalidateQueries('usePointQuery');
            pointControlCancel();
        },
    });

    useEffect(() => {
        refetch();
    }, [currListPage, refetch]);

    const parseUsage = (v: string) => {
        switch (v) {
            case 'RESERVATION':
                return '레저예약';
            case 'LEPPA_MARKET':
            case 'LEPPA_MARKET_GIFT':
                return '레빠마켓';
            default:
                return '';
        }
    };

    const parseType = (v: string) => {
        switch (v) {
            case 'PAYMENT':
                return '결제';
            case 'EVENT':
                return '이벤트';
            case 'REVIEW':
                return '리뷰작성';
            case 'REVIEW_REMOVE':
                return '리뷰삭제';
            case 'MOD':
                return '직접처리';
            case 'CANCEL':
                return '환불';
            default:
                return '';
        }
    };

    const parseStatus = (v: string) => {
        switch (v) {
            case 'RETURN':
                return (
                    <StatusValue color={'#EA2C4E'} bgColor={'#F8E3E7'}>
                        반환
                    </StatusValue>
                );
            case 'CANCEL':
                return (
                    <StatusValue color={'#EA2C4E'} bgColor={'#F8E3E7'}>
                        적립취소
                    </StatusValue>
                );
            case 'SCHEDULE_SAVE':
                return (
                    <StatusValue color={'#47B50B'} bgColor={'#EAF9E1'}>
                        적립예정
                    </StatusValue>
                );
            case 'USE':
                return <StatusValue borderColor={'#EBEEF0'}>사용</StatusValue>;
            case 'SAVE':
                return <StatusValue borderColor={'#EBEEF0'}>적립</StatusValue>;
            case 'EXTINCTION':
                return <StatusValue borderColor={'#EBEEF0'}>소멸</StatusValue>;
            default:
                return <StatusValue borderColor={'#EBEEF0'}>-</StatusValue>;
        }
    };

    const onClickPointControl = useCallback(() => {
        setPointControlPopupShow(true);
    }, []);

    const pointControlCancel = useCallback(() => {
        setAmount(0);
        setDescription('');
        setPointControlPopupShow(false);
    }, []);

    const pointControlApply = useCallback(() => {
        if (amount === 0) {
            alert('포인트를 입력해 주세요.');
            return false;
        }
        if (description === '') {
            alert('포인트 변경 내용 설명을 입력해 주세요.');
            return false;
        }
        if (window.confirm('보유 포인트를 수정하시겠습니까?')) {
            let params = {
                memberNo,
                amount,
                description,
            };
            memberPointControlMutation.mutate(params);
        }
    }, [amount, description, memberNo, memberPointControlMutation]);

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <PopUp>
                        <img src={icClose} alt={'닫기'} onClick={closeClick} />
                        <Header>
                            <div>
                                <span>{data?.data.ResultData.nickName}</span>
                                님의 상세정보
                            </div>
                        </Header>
                        <BasicInfo>
                            <div>
                                <MemberInfo>
                                    <div>{data?.data.ResultData.name}</div>
                                    <div>{data?.data.ResultData.nickName}</div>
                                    <div>
                                        <div>연락처</div>
                                        <div>
                                            {data?.data.ResultData.mobileNo.replace(
                                                /(\d{3})(\d{4})(\d{4})/,
                                                '$1-$2-$3',
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <div>회원가입 일시</div>
                                        <div>{data?.data.ResultData.regDt}</div>
                                    </div>
                                </MemberInfo>
                            </div>
                            <div>
                                <PointArea>
                                    <div>
                                        <div>총 적립 포인트</div>
                                        <div>
                                            {`${data?.data.ResultData.totalPointCount.toLocaleString()}건`}
                                        </div>
                                        <div>
                                            {`${data?.data.ResultData.totalPoint.toLocaleString()}Lp`}
                                        </div>
                                    </div>
                                    <div>
                                        <div>총 사용 포인트</div>
                                        <div>
                                            {`${data?.data.ResultData.totalUsePointCount.toLocaleString()}건`}
                                        </div>
                                        <div>
                                            {`${data?.data.ResultData.totalUsePoint.toLocaleString()}Lp`}
                                        </div>
                                    </div>
                                    <div>
                                        <div>적립 취소 포인트</div>
                                        <div>
                                            {`${data?.data.ResultData.totalCancelPointCount.toLocaleString()}건`}
                                        </div>
                                        <div>
                                            {`${data?.data.ResultData.totalCancelPoint.toLocaleString()}Lp`}
                                        </div>
                                    </div>
                                    <div>
                                        <div>환불 포인트</div>
                                        <div>
                                            {`${data?.data.ResultData.totalReturnPointCount.toLocaleString()}건`}
                                        </div>
                                        <div>
                                            {`${data?.data.ResultData.totalReturnPoint.toLocaleString()}Lp`}
                                        </div>
                                    </div>
                                </PointArea>
                            </div>
                        </BasicInfo>
                        <PointStatus>
                            <div>
                                <div>현재 보유 포인트</div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div>
                                        {`${data?.data.ResultData.point.toLocaleString()}Lp`}
                                    </div>
                                    <button onClick={onClickPointControl}>
                                        포인트 수정
                                    </button>
                                    {pointControlPopupShow && (
                                        <PointControlBase>
                                            <div>보유 포인트 수정하기</div>
                                            <div>
                                                <div>현재 보유 포인트 기준</div>
                                                <div>
                                                    <input
                                                        type="number"
                                                        value={amount}
                                                        onChange={(e) => {
                                                            setAmount(
                                                                Number(
                                                                    e.target
                                                                        .value,
                                                                ),
                                                            );
                                                        }}
                                                    />
                                                    <div>{`수정 후 포인트: ${(
                                                        (data?.data.ResultData
                                                            .point
                                                            ? data?.data
                                                                  .ResultData
                                                                  .point
                                                            : 0) + amount
                                                    ).toLocaleString()}LP`}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>포인트 변경 내용 설명</div>
                                                <div>
                                                    <textarea
                                                        id="description"
                                                        placeholder="포인트를 수정한 이유에 대해 간략하게 작성해주세요. 해당 내용은 사용자에게 보여집니다. "
                                                        style={{
                                                            margin: '8px 0px 8px 0px',
                                                            width: '100%',
                                                            height: '70px',
                                                        }}
                                                        value={description}
                                                        onChange={(e) => {
                                                            setDescription(
                                                                e.target.value,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={pointControlCancel}
                                                >
                                                    취소
                                                </button>
                                                <button
                                                    onClick={pointControlApply}
                                                >
                                                    적용하기
                                                </button>
                                            </div>
                                        </PointControlBase>
                                    )}
                                </div>
                            </div>
                            <div>
                                <div>소멸 예정 포인트</div>
                                <div>{`${data?.data.ResultData.extinctionPoint.toLocaleString()}Lp`}</div>
                            </div>
                            <div>
                                <div>적립 예정 포인트</div>
                                <div>{`${data?.data.ResultData.schedulePoint.toLocaleString()}Lp`}</div>
                            </div>
                        </PointStatus>
                        <DetailArea>
                            <div>{`상세내역 (${listData?.data.ResultData.totalElements}건)`}</div>
                            <DataArea>
                                <DataHeader>날짜</DataHeader>
                                <DataHeader>사용처</DataHeader>
                                <DataHeader>유형</DataHeader>
                                <DataHeader>상태</DataHeader>
                                <DataHeader width={120}>포인트</DataHeader>
                                <DataHeader width={250}>내용</DataHeader>
                                <DataHeader width={150}>구매번호</DataHeader>
                                <DataHeader width={110}>만료일</DataHeader>
                            </DataArea>
                            <DataContent>
                                {listData?.data.ResultData.content.map(
                                    (v: LeppaPointResponse) => (
                                        <div key={v.id}>
                                            <DataValue>{v.regDt}</DataValue>
                                            <DataValue>
                                                {parseUsage(v.usePlace)}
                                            </DataValue>
                                            <DataValue type={v.type}>
                                                {parseType(v.type)}
                                            </DataValue>
                                            <DataValue>
                                                {parseStatus(v.status)}
                                            </DataValue>
                                            <DataValue
                                                width={120}
                                            >{`${v.amount.toLocaleString()}Lp`}</DataValue>
                                            <DataValue width={250}>
                                                {v.description}
                                            </DataValue>
                                            <DataValue
                                                width={150}
                                                title={`${v.purchaseId}`}
                                            >
                                                <div>{v.purchaseId}</div>
                                            </DataValue>
                                            <DataValue width={110}>
                                                {v.expiredDate}
                                            </DataValue>
                                        </div>
                                    ),
                                )}
                            </DataContent>
                            {listData?.data.ResultData.totalElements > 0 && (
                                <Pagination
                                    itemsCount={
                                        listData?.data.ResultData.totalElements
                                    }
                                    pageSize={pageSize}
                                    currPage={currListPage}
                                    setList={setList}
                                />
                            )}
                        </DetailArea>
                        <ButtonArea>
                            <button onClick={closeClick}>확인</button>
                        </ButtonArea>
                    </PopUp>
                </Base>
            )}
        </>
    );
};
export default PointPopup;
