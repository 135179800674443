import React, { useEffect, useState } from 'react';
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    DeleteRounded,
    EditRounded,
} from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteReservationKeyword,
    insertReservationKeyword,
    modifyReservationKeyword,
    setReservationKeywordOrderChange,
    useReservationKeywordQuery,
} from '../../hooks/keyword/useKeyword';

import styled from '@emotion/styled';
import { ReservationKeywordData } from '../typings/keyword/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const RightDiv = styled.div`
    float: right;
    display: flex;
    align-items: center;
`;

const ReservationKeyword = () => {
    const [reservationKeywordDialogOpen, setReservationKeywordDialogOpen] =
        useState(false);
    const [editSn, setEditSn] = useState(0);
    const [name, setName] = useState('');
    const [typeId, setTypeId] = useState(1);

    const queryClient = useQueryClient();
    const { isLoading, isError, data, refetch } =
        useReservationKeywordQuery(typeId);

    const deleteReservationKeywordMutation = useMutation(
        deleteReservationKeyword,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useReservationKeywordQuery');
            },
        },
    );

    const insertReservationKeywordMutation = useMutation(
        insertReservationKeyword,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useReservationKeywordQuery');
            },
        },
    );

    const modifyReservationKeywordMutation = useMutation(
        modifyReservationKeyword,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useReservationKeywordQuery');
            },
        },
    );

    const setReservationKeywordOrderChangeMutation = useMutation(
        setReservationKeywordOrderChange,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useReservationKeywordQuery');
            },
        },
    );

    const handleClose = () => {
        setReservationKeywordDialogOpen(false);
        setEditSn(0);
        setName('');
    };

    const handleClickOpen = () => {
        setReservationKeywordDialogOpen(true);
    };
    const editClick = (v: ReservationKeywordData) => {
        setReservationKeywordDialogOpen(true);
        setEditSn(v.keywordId);
        setName(v.name);
    };
    const deleteClick = (v: ReservationKeywordData) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteReservationKeywordMutation.mutate(v.keywordId);
        }
    };
    const onModifyReservationKeyword = () => {
        if (name === '') {
            alert('키워드명을 입력해 주세요.');
            return false;
        }
        let params = {
            keywordId: editSn,
            name,
        };
        modifyReservationKeywordMutation.mutate(params);
        handleClose();
    };
    const onSubmitReservationKeyword = () => {
        if (name === '') {
            alert('키워드명을 입력해 주세요.');
            return false;
        }
        let params = {
            name,
            typeId,
        };
        insertReservationKeywordMutation.mutate(params);
        handleClose();
    };

    const orderChange = (v: ReservationKeywordData, arrow: string) => {
        let orgIndex = data?.data.ResultData.indexOf(v);
        let targetKeywordId;
        if (arrow === 'U') {
            if (orgIndex === 0) {
                return false;
            }
            targetKeywordId = data?.data.ResultData[orgIndex - 1].keywordId;
        } else {
            if (orgIndex === data?.data.ResultData.length - 1) {
                return false;
            }
            targetKeywordId = data?.data.ResultData[orgIndex + 1].keywordId;
        }
        if (window.confirm('키워드 순서를 변경하시겠습니까?')) {
            let params = {
                orgKeywordId: v.keywordId,
                targetKeywordId,
            };
            setReservationKeywordOrderChangeMutation.mutate(params);
        }
    };

    const handleTypeChange = (e: SelectChangeEvent) => {
        setTypeId(Number(e.target.value));
    };

    useEffect(() => {
        refetch();
    }, [typeId, refetch]);

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        연관검색어 관리
                    </Title>
                    <RightDiv>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={typeId.toString()}
                            label="Age"
                            onChange={handleTypeChange}
                            size="small"
                        >
                            <MenuItem value={1}>강으로</MenuItem>
                            <MenuItem value={2}>바다로</MenuItem>
                            <MenuItem value={3}>숲으로</MenuItem>
                            <MenuItem value={4}>도시로</MenuItem>
                        </Select>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </RightDiv>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '450px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '100px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>키워드명</Th>
                                    <Th>등록자</Th>
                                    <Th>등록일</Th>
                                    <Th>순서변경</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.map(
                                    (v: ReservationKeywordData) => {
                                        return (
                                            <tr key={v.keywordId}>
                                                <Td>{v.name}</Td>
                                                <Td>{v.createdId}</Td>
                                                <Td>{v.regDt}</Td>
                                                <Td>
                                                    <ArrowUpwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'U');
                                                        }}
                                                    />
                                                    <ArrowDownwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'D');
                                                        }}
                                                    />
                                                </Td>
                                                <Td>
                                                    <EditRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            editClick(v);
                                                        }}
                                                    />
                                                    <DeleteRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            deleteClick(v);
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                    <Dialog
                        open={reservationKeywordDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            연관검색어
                            {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    id="standard-basic"
                                    label="키워드명"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button
                                    onClick={onModifyReservationKeyword}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button
                                    onClick={onSubmitReservationKeyword}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
};

export default React.memo(ReservationKeyword);
