import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    EditRounded,
    VisibilityOffRounded,
    VisibilityRounded,
} from '@mui/icons-material';

import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import {
    insertLeppaMarketCategory,
    modifyLeppaMarketCategory,
    setLeppaMarketCategoryOrderChange,
    setLeppaMarketCategoryVisibility,
    useLeppaMarketCategoryQuery,
} from '../../hooks/category/useCategory';
import { LeppaMarketCategoryData } from '../typings/category/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

function LeppaMarketAdCategory() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editSn, setEditSn] = useState(0);
    const [categoryName, setCategoryName] = useState('');

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useLeppaMarketCategoryQuery();

    const insertLeppaMarketCategoryMutation = useMutation(
        insertLeppaMarketCategory,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useLeppaMarketCategoryQuery');
            },
        },
    );

    const modifyLeppaMarketCategorypMutation = useMutation(
        modifyLeppaMarketCategory,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useLeppaMarketCategoryQuery');
            },
        },
    );

    const setLeppaMarketCategoryVisibilityMutation = useMutation(
        setLeppaMarketCategoryVisibility,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useLeppaMarketCategoryQuery');
            },
        },
    );

    const setLeppaMarketCategoryOrderChangeMutation = useMutation(
        setLeppaMarketCategoryOrderChange,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useLeppaMarketCategoryQuery');
            },
        },
    );

    const handleClose = () => {
        setDialogOpen(false);
        setEditSn(0);
        setCategoryName('');
    };

    const handleClickOpen = () => {
        setDialogOpen(true);
    };
    const editClick = (v: LeppaMarketCategoryData) => {
        setDialogOpen(true);
        setEditSn(v.sn);
        setCategoryName(v.name);
    };

    const onModifyAdTop = () => {
        if (categoryName === '') {
            alert('카테고리명을 입력해 주세요.');
            return false;
        }
        let params = {
            sn: editSn,
            name: categoryName,
        };
        modifyLeppaMarketCategorypMutation.mutate(params);
        handleClose();
    };
    const onSubmitAdTop = () => {
        if (categoryName === '') {
            alert('카테고리명을 입력해 주세요.');
            return false;
        }
        insertLeppaMarketCategoryMutation.mutate(categoryName);
        handleClose();
    };

    const setAdVisibility = (
        v: LeppaMarketCategoryData,
        isVisibility: string,
    ) => {
        let msg = '카테고리를 노출하시겠습니까?';
        if (isVisibility === 'N') {
            msg = '카테고리를 비노출하시겠습니까?';
        }
        if (window.confirm(msg)) {
            let params = {
                sn: v.sn,
                showYn: isVisibility,
            };
            setLeppaMarketCategoryVisibilityMutation.mutate(params);
        }
    };

    const orderChange = (v: LeppaMarketCategoryData, arrow: string) => {
        let orgIndex = data?.data.ResultData.content.indexOf(v);
        let targetSn;
        if (arrow === 'U') {
            if (orgIndex === 0) {
                return false;
            }
            targetSn = data?.data.ResultData.content[orgIndex - 1].sn;
        } else {
            if (orgIndex === data?.data.ResultData.content.length - 1) {
                return false;
            }
            targetSn = data?.data.ResultData.content[orgIndex + 1].sn;
        }
        if (window.confirm('카테고리 순서를 변경하시겠습니까?')) {
            let params = {
                sn: v.sn,
                targetSn,
            };
            setLeppaMarketCategoryOrderChangeMutation.mutate(params);
        }
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        레빠마켓 카테고리 관리
                    </Title>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '550px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '100px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>카테고리명</Th>
                                    <Th>노출여부</Th>
                                    <Th>순서변경</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.content.map(
                                    (v: LeppaMarketCategoryData) => {
                                        return (
                                            <tr key={v.sn}>
                                                <Td>{v.name}</Td>
                                                <Td>
                                                    {v.showYn === 'Y' && (
                                                        <VisibilityRounded
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                setAdVisibility(
                                                                    v,
                                                                    'N',
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                    {v.showYn === 'N' && (
                                                        <VisibilityOffRounded
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                setAdVisibility(
                                                                    v,
                                                                    'Y',
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </Td>
                                                <Td>
                                                    <ArrowUpwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'U');
                                                        }}
                                                    />
                                                    <ArrowDownwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'D');
                                                        }}
                                                    />
                                                </Td>
                                                <Td>
                                                    <EditRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            editClick(v);
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                    <Dialog
                        open={dialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            레빠마켓 카테고리
                            {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    id="description"
                                    label="카테고리 명"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={categoryName}
                                    onChange={(e) => {
                                        setCategoryName(e.target.value);
                                    }}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button onClick={onModifyAdTop} color="primary">
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button onClick={onSubmitAdTop} color="primary">
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
}

export default React.memo(LeppaMarketAdCategory);
