import React, { useState } from 'react';
import { DeleteRounded, EditRounded, SendRounded } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import Pagination from '../common/Pagination';
import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteAdAlarm,
    insertAdAlarm,
    modifyAdAlarm,
    sendAdAlarm,
    useAdAlarm,
} from '../../hooks/ad/useAd';
import { AdAlarmData } from '../typings/ad/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

function AdAlarm() {
    const pageSize = 10;

    const [alarmDialogOpen, setAlarmDialogOpen] = useState(false);
    const [editSn, setEditSn] = useState(0);
    const [company, setCompany] = useState('');
    const [message, setMessage] = useState('');
    const [url, setUrl] = useState('');
    const [currListPage, setCurrListPage] = useState(1);

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useAdAlarm(currListPage, pageSize);

    const sendAdAlarmMutation = useMutation(sendAdAlarm, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdAlarm');
        },
    });

    const insertAdAlarmMutation = useMutation(insertAdAlarm, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdAlarm');
        },
    });

    const modifyAdAlarmMutation = useMutation(modifyAdAlarm, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdAlarm');
        },
    });
    const deleteAdAlarmMutation = useMutation(deleteAdAlarm, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdAlarm');
        },
    });

    const setList = (page: number) => {
        setCurrListPage(page);
    };

    const deleteClick = (v: AdAlarmData) => {
        let confirmMessage = '삭제하시겠습니까?';
        if (v.sendYn === 'Y') {
            confirmMessage = '이미 발송된 광고 알림입니다.\n' + confirmMessage;
        }
        if (window.confirm(confirmMessage)) {
            deleteAdAlarmMutation.mutate(v.adSn);
        }
    };

    const editClick = (v: AdAlarmData) => {
        setEditSn(v.adSn);
        setCompany(v.company);
        setMessage(v.message.substring(4));
        setUrl(v.url);
        setAlarmDialogOpen(true);
    };

    const handleClickOpen = () => {
        setAlarmDialogOpen(true);
    };

    const handleClose = () => {
        setAlarmDialogOpen(false);
        setEditSn(0);
        setCompany('');
        setMessage('');
        setUrl('');
    };

    const onModifyAlarm = () => {
        let params = {
            adSn: editSn,
            company,
            message: '(광고)' + message,
            url,
        };
        modifyAdAlarmMutation.mutate(params);
        handleClose();
    };
    const onSubmitAlarm = () => {
        let params = {
            company,
            message: '(광고)' + message,
            url,
        };
        insertAdAlarmMutation.mutate(params);
        handleClose();
    };

    const sendClick = (v: AdAlarmData) => {
        if (window.confirm('회원에게 알림을 전송하시겠습니까?')) {
            let params = {
                adSn: v.adSn,
            };
            sendAdAlarmMutation.mutate(params);
        }
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        광고 알림 리스트(
                        <span className="list">
                            {data?.data.ResultData.totalElements}
                        </span>
                        )
                    </Title>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '200px' }} />
                                <col style={{ width: '425px' }} />
                                <col style={{ width: '125px' }} />
                                <col style={{ width: '125px' }} />
                                <col style={{ width: '95px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>회사명</Th>
                                    <Th>광고문구</Th>
                                    <Th>등록자</Th>
                                    <Th>등록일</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.content.map(
                                    (v: AdAlarmData) => {
                                        return (
                                            <tr key={v.adSn}>
                                                <Td>{v.company}</Td>
                                                <Td
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    <a
                                                        rel="noreferrer noopener nofollow"
                                                        href={v.url}
                                                        target="_blank"
                                                    >
                                                        {v.message}
                                                    </a>
                                                </Td>
                                                <Td>{v.createdId}</Td>
                                                <Td>{v.regDt}</Td>
                                                <Td>
                                                    {v.sendYn === 'N' && (
                                                        <>
                                                            <SendRounded
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    sendClick(
                                                                        v,
                                                                    );
                                                                }}
                                                            />
                                                            <EditRounded
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    editClick(
                                                                        v,
                                                                    );
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                    <DeleteRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            deleteClick(v);
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                        {data?.data.ResultData.totalElements > 0 && (
                            <Pagination
                                itemsCount={data?.data.ResultData.totalElements}
                                pageSize={pageSize}
                                currPage={currListPage}
                                setList={setList}
                            />
                        )}
                    </TableArea>
                    <Dialog
                        open={alarmDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            광고 알림
                            {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    id="standard-basic"
                                    label="회사명"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={company}
                                    onChange={(e) => {
                                        setCompany(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="message"
                                    label="광고문구"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="url"
                                    label="URL"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={url}
                                    onChange={(e) => {
                                        setUrl(e.target.value);
                                    }}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button onClick={onModifyAlarm} color="primary">
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button onClick={onSubmitAlarm} color="primary">
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
}

export default React.memo(AdAlarm);
