import { useQuery } from 'react-query';
import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import {
    InsertToothJamContentRequest,
    ModifyToothJamContentRequest,
    ToothJamContentDeleteRequest,
    ToothJamContentDragOrderChangeRequest,
    ToothJamContentOrderChangeRequest,
    ToothJamContentVisibilityRequest,
} from '../../components/typings/toothJamContent/types';

const getToothJamContentApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/tooth-jam-content',
        method: 'GET',
    });
};

export const useToothJamContentQuery = () =>
    useQuery(['useToothJamContentQuery'], () => getToothJamContentApi(), {
        retry: 3,
    });

export const insertToothJamContent = (params: InsertToothJamContentRequest) => {
    let formData = new FormData();
    const file = document.getElementById('file-input') as HTMLInputElement;
    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    const mobileFile = document.getElementById(
        'mobile-file-input',
    ) as HTMLInputElement;
    if (mobileFile && mobileFile.files) {
        formData.append('mobileImage', mobileFile.files[0]);
    }

    formData.append('message', params.message);
    return axiosApiInstance.post(
        apiUrl + '/api/v1/join/tooth-jam-content',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const modifyToothJamContent = (params: ModifyToothJamContentRequest) => {
    let formData = new FormData();

    if (params.fileName !== '') {
        const file = document.getElementById('file-input') as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    if (params.mobileFileName !== '') {
        const file = document.getElementById(
            'mobile-file-input',
        ) as HTMLInputElement;
        if (file && file.files) {
            formData.append('mobileImage', file.files[0]);
        }
    }
    formData.append('message', params.message);
    formData.append('id', params.id);
    return axiosApiInstance.put(
        apiUrl + '/api/v1/join/tooth-jam-content',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const setToothJamContentVisibility = (
    params: ToothJamContentVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/tooth-jam-content/visibility',
        method: 'PUT',
        data: params,
    });

export const setToothJamContentOrderChange = (
    params: ToothJamContentOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/tooth-jam-content/order-change',
        method: 'PUT',
        data: params,
    });

export const setToothJamContentDragOrderChange = (
    params: ToothJamContentDragOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/tooth-jam-content/order-change/drag',
        method: 'PUT',
        data: params,
    });

export const deleteToothJamContent = (params: ToothJamContentDeleteRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/tooth-jam-content',
        method: 'DELETE',
        data: params,
    });
