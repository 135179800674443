import axios from 'axios';
import { onSilentRefresh } from './common';
import { apiUrl } from './common';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = apiUrl;
const axiosApiInstance = axios.create();
axiosApiInstance.interceptors.request.use(
    async (config) => {
        const userInfo = window.localStorage.getItem('userInfo');
        const accessToken =
            userInfo && JSON.parse(userInfo).accessToken
                ? JSON.parse(userInfo).accessToken
                : '';
        config.headers = {
            Authorization: 'Bearer ' + accessToken,
            Accept: 'application/json',
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

axiosApiInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;

        async function refreshAccessToken() {
            onSilentRefresh();
        }

        if (error.response.status === 401 && !originalRequest._retry) {
            console.log('토큰 만료');
            originalRequest._retry = true;
            await refreshAccessToken();
            return axios(originalRequest);
        }
        return Promise.reject(error);
    },
);
export default axiosApiInstance;
