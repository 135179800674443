import React, { useState } from 'react';
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    EditRounded,
} from '@mui/icons-material';
import { useMutation, useQueryClient } from 'react-query';
import {
    insertProductClassification,
    modifyProductClassification,
    setProductClassificationOrderChange,
    useProductClassificationQuery,
} from '../../hooks/keyword/useKeyword';

import styled from '@emotion/styled';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { ProductClassificationData } from '../typings/keyword/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const RightDiv = styled.div`
    float: right;
    display: flex;
    align-items: center;
`;

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`;

function ProductClassification() {
    const [popupOpen, setPopupOpen] = useState(false);
    const [name, setName] = useState('');
    const [editSn, setEditSn] = useState(0);

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useProductClassificationQuery();

    const setProductClassificationOrderChangeMutation = useMutation(
        setProductClassificationOrderChange,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useProductClassificationQuery');
            },
        },
    );

    const insertProductClassificationMutation = useMutation(
        insertProductClassification,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useProductClassificationQuery');
            },
        },
    );

    const modifyProductClassificationMutation = useMutation(
        modifyProductClassification,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useProductClassificationQuery');
            },
        },
    );

    const orderChange = (v: ProductClassificationData, arrow: string) => {
        let orgIndex = data?.data.ResultData.indexOf(v);
        let targetSn;
        if (arrow === 'U') {
            if (orgIndex === 0) {
                return false;
            }
            targetSn = data?.data.ResultData[orgIndex - 1].sn;
        } else {
            if (orgIndex === data?.data.ResultData.length - 1) {
                return false;
            }
            targetSn = data?.data.ResultData[orgIndex + 1].sn;
        }
        if (window.confirm('일반상품 종목 순서를 변경하시겠습니까?')) {
            let params = {
                sn: v.sn,
                targetSn,
            };
            setProductClassificationOrderChangeMutation.mutate(params);
        }
    };

    const handleClickOpen = () => {
        setPopupOpen(true);
    };

    const handleClose = () => {
        setPopupOpen(false);
        setName('');
        setEditSn(0);
    };

    const onSubmitProductClassification = () => {
        if (name === '') {
            alert('종목명을 입력해주세요.');
            return false;
        }
        let params = {
            name,
        };
        insertProductClassificationMutation.mutate(params);
        handleClose();
    };

    const onModifyProductClassification = () => {
        if (name === '') {
            alert('종목명을 입력해주세요.');
            return false;
        }
        let params = {
            sn: editSn,
            name,
        };
        modifyProductClassificationMutation.mutate(params);
        handleClose();
    };

    const editClick = (v: ProductClassificationData) => {
        setPopupOpen(true);
        setEditSn(v.sn);
        setName(v.cdNm);
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        일반상품 종목 관리
                    </Title>
                    <RightDiv>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </RightDiv>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '400px' }} />
                                <col style={{ width: '300px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '100px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>종목명</Th>
                                    <Th>종목코드</Th>
                                    <Th>순서변경</Th>
                                    <Th></Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.map(
                                    (v: ProductClassificationData) => {
                                        return (
                                            <tr key={v.sn}>
                                                <Td>{v.cdNm}</Td>
                                                <Td>{v.cd}</Td>
                                                <Td>
                                                    <ArrowUpwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'U');
                                                        }}
                                                    />
                                                    <ArrowDownwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'D');
                                                        }}
                                                    />
                                                </Td>
                                                <Td>
                                                    <EditRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            editClick(v);
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                    <Dialog
                        open={popupOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            일반상품 종목
                            {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <FlexDiv>
                                <TextField
                                    id="fileName"
                                    label="종목명"
                                    style={{
                                        margin: '8px 0px 8px 0px',
                                        paddingRight: '5px',
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </FlexDiv>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button
                                    onClick={onModifyProductClassification}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button
                                    onClick={onSubmitProductClassification}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
}

export default React.memo(ProductClassification);
