import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import {
    changeUser,
    initPasswordUser,
    insertUser,
    useUserQuery,
} from '../../hooks/user/useUser';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const CustomButtonArea = styled.div`
    display: flex;
    align-items: center;
`;

const CustomButton = styled.div`
    background: #404040;
    padding: 10px 15px;
    border-radius: 3px;
    border: 1px solid #404040;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
    min-width: 28px;
`;

const ModifyDialogDiv = styled.div`
    display: flex;
    align-items: center;
`;

const ModifyDialogLabel = styled.div`
    width: 100px;
`;

function Users() {
    const [addUserDialogOpen, setAddDialogOpen] = useState(false);
    const [modifyDialogOpen, setModifyDialogOpen] = useState(false);
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [authority, setAuthority] = useState('');
    const [editId, setEditId] = useState('');
    const [state, setState] = useState('');
    const [id, setId] = useState('');
    const [pwd, setPwd] = useState('');
    const [initPwd, setInitPwd] = useState('');

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useUserQuery();

    const insertUserMutation = useMutation(insertUser, {
        onSuccess: () => {
            queryClient.invalidateQueries('useUserQuery');
        },
    });

    const changeUserMutation = useMutation(changeUser, {
        onSuccess: () => {
            queryClient.invalidateQueries('useUserQuery');
        },
    });

    const initPasswordUserMutation = useMutation(initPasswordUser, {
        onSuccess: () => {
            queryClient.invalidateQueries('useUserQuery');
        },
    });

    const handleModifyClose = () => {
        setModifyDialogOpen(false);
        setState('');
        setAuthority('');
        setEditId('');
    };

    const handleAddClose = () => {
        setAddDialogOpen(false);
        setId('');
        setPwd('');
    };

    const handlePasswordPopupClose = () => {
        setPasswordDialogOpen(false);
        setEditId('');
        setInitPwd('');
    };

    const handleClickOpen = () => {
        setAddDialogOpen(true);
        setEditId('');
        setInitPwd('');
    };

    const onSubmitAddUser = () => {
        if (id.includes('operation')) {
            alert('아이디에 operation은 사용할 수 없습니다.');
            return false;
        }
        if (id === '') {
            alert('아이디를 입력해주세요.');
            return false;
        }
        if (pwd === '') {
            alert('비밀번호를 입력해주세요.');
            return false;
        }
        if (pwd.length < 8) {
            alert('비밀번호는 8자리 이상 입력해 주세요.');
            return false;
        }
        let params = {
            id,
            pwd,
        };
        insertUserMutation.mutate(params);
        handleAddClose();
    };

    const onModifyUser = () => {
        let params = {
            id: editId,
            authority,
            state,
        };
        changeUserMutation.mutate(params);
        handleModifyClose();
    };

    const onSubmitInitPwd = () => {
        let params = {
            id: editId,
            pwd: initPwd,
        };
        initPasswordUserMutation.mutate(params);
        handlePasswordPopupClose();
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        사용자 관리
                    </Title>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '350px' }} />
                                <col style={{ width: '250px' }} />
                                <col style={{ width: '180px' }} />
                                <col style={{ width: '200px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>아이디</Th>
                                    <Th>권한</Th>
                                    <Th>활성화여부</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.data.ResultData.content.map((v) => {
                                    return (
                                        <tr key={v.id}>
                                            <Td>{v.id}</Td>
                                            <Td>
                                                {v.authority ===
                                                'ROLE_JOIN_ADMIN'
                                                    ? '관리자'
                                                    : v.authority ===
                                                      'ROLE_USER'
                                                    ? '일반사용자'
                                                    : ''}
                                            </Td>
                                            <Td>
                                                {v.userState === 'ACTIVE'
                                                    ? '활성화'
                                                    : '비활성화'}
                                            </Td>
                                            <Td>
                                                <CustomButtonArea>
                                                    <CustomButton
                                                        onClick={() => {
                                                            setEditId(v.id);
                                                            setAuthority(
                                                                v.authority,
                                                            );
                                                            setState(
                                                                v.userState,
                                                            );
                                                            setModifyDialogOpen(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        수정
                                                    </CustomButton>
                                                    <CustomButton
                                                        onClick={() => {
                                                            setEditId(v.id);
                                                            setPasswordDialogOpen(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        암호변경
                                                    </CustomButton>
                                                </CustomButtonArea>
                                            </Td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </TableArea>
                    {/*사용자 수정*/}
                    <Dialog
                        open={modifyDialogOpen}
                        onClose={handleModifyClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            사용자 수정
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <ModifyDialogDiv>
                                    <ModifyDialogLabel>권한</ModifyDialogLabel>
                                    <Select
                                        labelId="authority"
                                        id="authority"
                                        value={authority}
                                        fullWidth
                                        label="권한"
                                        onChange={(e) => {
                                            setAuthority(e.target.value);
                                        }}
                                    >
                                        <MenuItem value="ROLE_JOIN_ADMIN">
                                            관리자
                                        </MenuItem>
                                        <MenuItem value="ROLE_USER">
                                            사용자
                                        </MenuItem>
                                    </Select>
                                </ModifyDialogDiv>
                                <ModifyDialogDiv>
                                    <ModifyDialogLabel>
                                        활성여부
                                    </ModifyDialogLabel>
                                    <Select
                                        labelId="state"
                                        id="state"
                                        fullWidth
                                        value={state}
                                        label="활성화여부"
                                        onChange={(e) => {
                                            setState(e.target.value);
                                        }}
                                    >
                                        <MenuItem value="ACTIVE">
                                            활성화
                                        </MenuItem>
                                        <MenuItem value="INACTIVE">
                                            비활성화
                                        </MenuItem>
                                    </Select>
                                </ModifyDialogDiv>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onModifyUser} color="primary">
                                수정
                            </Button>

                            <Button onClick={handleModifyClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*사용자 등록*/}
                    <Dialog
                        open={addUserDialogOpen}
                        onClose={handleAddClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            사용자 등록
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    id="id"
                                    label="아이디"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={id}
                                    onChange={(e) => {
                                        setId(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="password"
                                    type="password"
                                    label="비밀번호"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={pwd}
                                    onChange={(e) => {
                                        setPwd(e.target.value);
                                    }}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onSubmitAddUser} color="primary">
                                등록
                            </Button>

                            <Button onClick={handleAddClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*비밀번호 초기화*/}
                    <Dialog
                        open={passwordDialogOpen}
                        onClose={handlePasswordPopupClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            사용자 비밀번호 변경
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    id="id"
                                    label="아이디"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    disabled
                                    value={editId}
                                />
                                <TextField
                                    id="initPwd"
                                    type="password"
                                    label="비밀번호"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={initPwd}
                                    onChange={(e) => {
                                        setInitPwd(e.target.value);
                                    }}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onSubmitInitPwd} color="primary">
                                변경
                            </Button>

                            <Button
                                onClick={handlePasswordPopupClose}
                                color="primary"
                            >
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
}

export default React.memo(Users);
