import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import ImageResize from '@looop/quill-image-resize-module-react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Pagination from '../common/Pagination';
import {
    Button,
    createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    TextField,
    ThemeProvider,
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { DeleteRounded, EditRounded } from '@mui/icons-material';
import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import {
    addNews,
    deleteNews,
    modNews,
    useNewsListQuery,
} from '../../hooks/news/useNews';
import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { loginState } from '../common/atom';
import { NewsData } from '../typings/news/response';

Quill.register('modules/imageResize', ImageResize);

const theme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    width: '1280px',
                },
            },
        },
    },
});

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.div`
    font-weight: 700;
    margin: 15px;
    font-size: 26px;
    height: 38px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
    width: 100%;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const News = () => {
    const pageSize = 10;
    const [insertOpen, setInsertOpen] = useState(false);
    const [currListPage, setCurrListPage] = useState(1);
    const [type, setType] = useState('11');
    const [boardInsertType, setBoardInsertType] = useState('11');
    const [cont, setCont] = useState('');
    const [editSn, setEditSn] = useState(0);
    const userId = useRecoilValue(loginState);
    const quillObj = useRef<ReactQuill>();

    const handleClickOpen = () => {
        setInsertOpen(true);
    };
    const titleRef = useRef<HTMLInputElement>(null);

    const handleClose = () => {
        quillObj.current?.getEditor().setText('');
        setInsertOpen(false);
        setCont('');
        setEditSn(0);
    };

    const changeBoardType = (type: string) => {
        setBoardInsertType(type);
        setType(type);
    };

    const queryClient = useQueryClient();
    const { isLoading, isError, data, refetch } = useNewsListQuery(
        type,
        currListPage,
        pageSize,
    );
    const deleteNewsMutation = useMutation(deleteNews, {
        onSuccess: () => {
            queryClient.invalidateQueries('useNewsListQuery');
        },
    });
    const addNewsMutation = useMutation(addNews, {
        onSuccess: () => {
            queryClient.invalidateQueries('useNewsListQuery');
        },
    });
    const modNewsMutation = useMutation(modNews, {
        onSuccess: () => {
            queryClient.invalidateQueries('useNewsListQuery');
        },
    });

    useEffect(() => {
        refetch();
    }, [type, refetch, currListPage]);

    const onSubmitBoard = () => {
        const params = {
            title: titleRef.current?.value as string,
            cont,
            boardGbnCd: type,
            id: userId,
        };
        addNewsMutation.mutate(params);
        handleClose();
    };

    const onModifyBoard = () => {
        const params = {
            sn: editSn,
            title: titleRef.current?.value as string,
            cont,
            boardGbnCd: type,
            id: userId,
        };
        modNewsMutation.mutate(params);
        handleClose();
    };

    const editClick = (v: NewsData) => {
        setInsertOpen(true);
        setCont(v.cont);
        setTimeout(() => {
            titleRef.current!.value = v.title;
            setEditSn(v.sn);
        }, 100);
    };

    const deleteClick = (v: NewsData) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteNewsMutation.mutate(v.sn);
        }
    };

    const imageHandler = () => {
        const input = document.createElement('input') as HTMLInputElement;

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            let formData = new FormData();
            if (input && input.files) {
                let file = input.files[0];
                formData.append('file', file);
            }

            const response = await axiosApiInstance.post(
                apiUrl + '/api/news/upload',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );
            if (response.data.ResultCode === 1) {
                let fileName = response.data.ResultData;
                const range = quillObj.current?.getEditor().getSelection();
                quillObj.current
                    ?.getEditor()
                    .insertEmbed(range?.index as number, 'image', fileName);
            }
        };
    };

    const modules = {
        toolbar: {
            container: [
                [{ header: '1' }, { header: '2' }, { font: [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { indent: '-1' },
                    { indent: '+1' },
                ],
                ['link', 'image'],
            ],
            handlers: {
                image: imageHandler,
            },
        },
        clipboard: {
            matchVisual: false,
        },
        imageResize: {
            modules: ['Resize'],
        },
    };

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ];

    const changeEditor = (v: string) => {
        setTimeout(() => setCont(v), 0);
    };

    const setList = (page: number) => {
        setCurrListPage(page);
    };

    return (
        <Base>
            <ThemeProvider theme={theme}>
                <Title>
                    {type === '11' && (
                        <div style={{ float: 'left' }}>
                            레빠뉴스 |{' '}
                            <span
                                id="ls"
                                style={{
                                    fontSize: '20px',
                                    color: '#039be5',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    changeBoardType('12');
                                }}
                            >
                                레빠이벤트
                            </span>
                        </div>
                    )}
                    {type === '12' && (
                        <div style={{ float: 'left' }}>
                            레빠이벤트 |{' '}
                            <span
                                id="ls"
                                style={{
                                    fontSize: '20px',
                                    color: '#039be5',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    changeBoardType('11');
                                }}
                            >
                                레빠뉴스
                            </span>
                        </div>
                    )}
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                </Title>
                {isLoading || isError ? (
                    <div>loading</div>
                ) : (
                    <TableArea>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '600px' }} />
                                <col style={{ width: '225px' }} />
                                <col style={{ width: '125px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>제목</Th>
                                    <Th>등록일</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.content.map(
                                    (v: NewsData) => {
                                        return (
                                            <tr key={v.sn}>
                                                <Td
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    {v.title}
                                                </Td>
                                                <Td>{v.regDt}</Td>
                                                <Td>
                                                    <EditRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            editClick(v);
                                                        }}
                                                    />
                                                    <DeleteRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            deleteClick(v);
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                        <Pagination
                            itemsCount={data?.data.ResultData.totalElements}
                            pageSize={pageSize}
                            currPage={currListPage}
                            setList={setList}
                        />
                    </TableArea>
                )}

                <Dialog
                    open={insertOpen}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="lg"
                >
                    <DialogTitle id="form-dialog-title">
                        뉴스/이벤트 {editSn === 0 ? '등록' : '수정'}
                    </DialogTitle>
                    <DialogContent>
                        <div className="boardDiv">
                            <Select
                                variant="standard"
                                value={boardInsertType}
                                onChange={(e) => {
                                    setBoardInsertType(e.target.value);
                                }}
                            >
                                <MenuItem value="11">레빠뉴스</MenuItem>
                                <MenuItem value="12">레빠이벤트</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <TextField
                                variant="standard"
                                inputRef={titleRef}
                                label="제목"
                                fullWidth
                                style={{ margin: '8px 0px 8px 0px' }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div style={{ height: '420px', overflow: 'hidden' }}>
                            <ReactQuill
                                ref={(element) => {
                                    if (element !== null) {
                                        quillObj.current = element;
                                    }
                                }}
                                value={cont}
                                onChange={changeEditor}
                                modules={modules}
                                formats={formats}
                                style={{
                                    height: '350px',
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {editSn > 0 && (
                            <Button onClick={onModifyBoard} color="primary">
                                수정
                            </Button>
                        )}
                        {editSn === 0 && (
                            <Button onClick={onSubmitBoard} color="primary">
                                등록
                            </Button>
                        )}

                        <Button onClick={handleClose} color="primary">
                            취소
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </Base>
    );
};

export default React.memo(News);
