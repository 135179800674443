import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useEnterpriseLogListQuery } from '../../hooks/enterprise/useEnterprise';
import searchImage from '../../assets/search.png';
import Pagination from '../common/Pagination';
import { parseStringDateTime } from '../../utils/util';
import { EnterpriseLogData } from '../typings/enterprise/response';

const Container = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div`
    margin: 0;
    padding: 0;
`;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
    white-space: pre-wrap;
`;

const SearchArea = styled.div``;

const SearchInput = styled.input`
    width: 256px;
    height: 46px;
    padding: 0 15px;
    box-sizing: border-box;
    border: 1px solid #398fce;
    vertical-align: top;
`;

const SearchBtn = styled.span`
    float: right;
    width: 46px;
    height: 46px;
    line-height: 46px;
    background: #398fce url(${searchImage}) no-repeat 13px 13px;
    font-size: 0;
    text-indent: -9999px;
    cursor: pointer;
`;

const TitleArea = styled.div`
    display: flex;
    justify-content: space-between;
`;

function EnterpriseLog() {
    const pageSize = 20;
    const [currListPage, setCurrListPage] = useState(1);
    const [searchText, setSearchText] = useState('');

    const { isLoading, isError, data, refetch } = useEnterpriseLogListQuery(
        currListPage,
        pageSize,
        searchText,
    );

    const setList = (page: number) => {
        setCurrListPage(page);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (currListPage > 1) {
                setCurrListPage(1);
            } else {
                refetch();
            }
        }
    };

    const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const onSearch = () => {
        if (currListPage > 1) {
            setCurrListPage(1);
        } else {
            refetch();
        }
    };

    useEffect(() => {
        refetch();
    }, [currListPage, refetch]);

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Container>
                    <TitleArea>
                        <Title>사업장 로그 리스트</Title>
                        <SearchArea>
                            <SearchInput
                                placeholder="검색어를 입력하세요."
                                onKeyDown={onKeyDown}
                                value={searchText}
                                onChange={onSearchChange}
                            />
                            <SearchBtn onClick={onSearch} />
                        </SearchArea>
                    </TitleArea>
                    <TableArea>
                        <Table>
                            <colgroup>
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '600px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>시간</Th>
                                    <Th>관리자번호</Th>
                                    <Th>내용</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.content.map(
                                    (v: EnterpriseLogData) => {
                                        return (
                                            <tr key={v.time}>
                                                <Td>
                                                    {parseStringDateTime(
                                                        v.time,
                                                    )}
                                                </Td>
                                                <Td>{v.userId}</Td>
                                                <Td>{v.message}</Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                    {data?.data.ResultData.totalElements > 0 && (
                        <Pagination
                            itemsCount={data?.data.ResultData.totalElements}
                            pageSize={pageSize}
                            currPage={currListPage}
                            setList={setList}
                        />
                    )}
                </Container>
            )}
        </>
    );
}

export default React.memo(EnterpriseLog);
