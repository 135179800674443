import React, { useEffect, useState } from 'react';
import { DeleteRounded } from '@mui/icons-material';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteMappingProductClassification,
    insertMappingProductClassification,
    useMappingProductClassificationQuery,
    useMappingTargetProductClassificationQuery,
} from '../../hooks/keyword/useKeyword';

import styled from '@emotion/styled';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import {
    MappingProductClassificationData,
    MappingTargetProductClassificationData,
} from '../typings/keyword/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const RightDiv = styled.div`
    float: right;
    display: flex;
    align-items: center;
`;

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`;

function MappingProductClassification() {
    const [popupOpen, setPopupOpen] = useState(false);
    const [productCodeId, setProductCodeId] = useState(0);
    const [typeId, setTypeId] = useState(1);

    const queryClient = useQueryClient();
    const { isLoading, isError, data, refetch } =
        useMappingProductClassificationQuery(typeId);
    const {
        isLoading: targetIsLoading,
        isError: targetIsError,
        data: targetData,
        refetch: targetRefetch,
    } = useMappingTargetProductClassificationQuery(typeId);

    const insertMappingProductClassificationMutation = useMutation(
        insertMappingProductClassification,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    'useMappingProductClassificationQuery',
                );
            },
        },
    );

    const deleteMappingProductClassificationMutation = useMutation(
        deleteMappingProductClassification,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    'useMappingProductClassificationQuery',
                );
            },
        },
    );

    const handleTypeChange = (e: SelectChangeEvent) => {
        setTypeId(Number(e.target.value));
    };

    const handleClickOpen = () => {
        setPopupOpen(true);
    };

    const handleClose = () => {
        setPopupOpen(false);
        setProductCodeId(0);
    };

    const onSubmitProductClassification = () => {
        let params = {
            typeId,
            codeId: productCodeId,
        };
        insertMappingProductClassificationMutation.mutate(params);
        handleClose();
    };

    const deleteClick = (v: MappingProductClassificationData) => {
        if (window.confirm('삭제하시겠습니까?')) {
            let params = {
                mappingId: v.mappingId,
            };
            deleteMappingProductClassificationMutation.mutate(params);
        }
    };

    useEffect(() => {
        refetch();
        targetRefetch();
    }, [typeId, refetch, targetRefetch]);

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        일반상품 종목 매핑 관리
                    </Title>
                    <RightDiv>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={typeId.toString()}
                            label="Age"
                            onChange={handleTypeChange}
                            size="small"
                        >
                            <MenuItem value={1}>강으로</MenuItem>
                            <MenuItem value={2}>바다로</MenuItem>
                            <MenuItem value={3}>숲으로</MenuItem>
                            <MenuItem value={4}>도시로</MenuItem>
                        </Select>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </RightDiv>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '850px' }} />
                                <col style={{ width: '100px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>종목명</Th>
                                    <Th></Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.map(
                                    (v: MappingProductClassificationData) => {
                                        return (
                                            <tr key={v.mappingId}>
                                                <Td>{v.cdNm}</Td>
                                                <Td>
                                                    <DeleteRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            deleteClick(v);
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                    <Dialog
                        open={popupOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            일반상품 종목 매핑 등록
                        </DialogTitle>
                        <DialogContent>
                            <FlexDiv>
                                <Select
                                    labelId="productCodes"
                                    id="productCodes"
                                    value={productCodeId}
                                    label="종목명"
                                    onChange={(e) => {
                                        setProductCodeId(
                                            Number(e.target.value),
                                        );
                                    }}
                                >
                                    {!targetIsLoading &&
                                        !targetIsError &&
                                        targetData?.data.ResultData.map(
                                            (
                                                v: MappingTargetProductClassificationData,
                                            ) => {
                                                return (
                                                    <MenuItem
                                                        key={v.id}
                                                        value={v.id}
                                                    >
                                                        {v.name}
                                                    </MenuItem>
                                                );
                                            },
                                        )}
                                </Select>
                            </FlexDiv>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={onSubmitProductClassification}
                                color="primary"
                            >
                                등록
                            </Button>
                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
}

export default React.memo(MappingProductClassification);
