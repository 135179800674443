import React from 'react';
import SortableList, { SortableItem } from 'react-easy-sort';
import { Avatar, Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DeleteRounded, SettingsOverscanRounded } from '@mui/icons-material';
import { clickImg, clickMp4 } from '../../utils/util';
import styled from '@emotion/styled';
import { UseMutationResult } from 'react-query/types/react/types';
import { LeppaMarketImageData } from '../typings/leppa-market/response';
import {
    LeppaMarketImageType,
    LeppaMarketItemDeleteImageRequest,
    LeppaMarketItemImageOrderChangeRequest,
} from '../typings/leppa-market/types';
import { AxiosResponse } from 'axios';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        userSelect: 'none',
    },
    item: {
        position: 'relative',
        flexShrink: 0,
        display: 'flex',
        margin: 8,
        cursor: 'grab',
        userSelect: 'none',
    },
    image: {
        width: 150,
        height: 150,
        borderRadius: 0,
    },
    button: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    dragged: {
        '& button': {
            opacity: 0,
        },
    },
    infoButton: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        borderRadius: '50%',
        padding: 0,
        minWidth: 0,
        width: '40px',
        height: '40px',
        zIndex: 1051,
        boxShadow:
            '0px 3px 5px -1pxrgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: '#e0e0e0',
        justifyContent: 'center',
        display: 'inline-flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
});

const InfoButton = styled.div``;

interface Props {
    itemId: string;
    images: Array<LeppaMarketImageData>;
    orderChangeMutation: UseMutationResult<
        AxiosResponse,
        unknown,
        LeppaMarketItemImageOrderChangeRequest
    >;
    imageType: LeppaMarketImageType;
    deleteImageMutation: UseMutationResult<
        AxiosResponse,
        unknown,
        LeppaMarketItemDeleteImageRequest
    >;
}

const ImageList = ({
    itemId,
    images,
    orderChangeMutation,
    imageType,
    deleteImageMutation,
}: Props) => {
    const classes = useStyles();

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        let params = {
            id: images[oldIndex].imageId,
            targetOrdering: newIndex + 1,
            imageType,
        };
        orderChangeMutation.mutate(params);
    };

    const deleteImage = (imageId: number) => {
        if (window.confirm('삭제하시겠습니까?')) {
            let params = {
                itemId,
                imageId,
                imageType,
            };
            deleteImageMutation.mutate(params);
        }
    };

    return (
        <SortableList
            onSortEnd={onSortEnd}
            className={classes.root}
            draggedItemClassName={classes.dragged}
        >
            {images.map((v) => (
                <SortableItem key={v.imageId}>
                    <div className={classes.item}>
                        <Avatar
                            className={classes.image}
                            alt="상단이미지"
                            src={v.imgPath}
                            imgProps={{ draggable: false }}
                        />
                        <Fab
                            size="small"
                            className={classes.button}
                            aria-label="like"
                            onClick={() => deleteImage(v.imageId)}
                        >
                            <DeleteRounded />
                        </Fab>
                        <InfoButton
                            className={classes.infoButton}
                            onClick={() => {
                                if (v.imgPath.includes('mp4')) {
                                    clickMp4(v.imgPath);
                                } else {
                                    clickImg(v.imgPath, v.width, v.height);
                                }
                            }}
                        >
                            <SettingsOverscanRounded />
                        </InfoButton>
                    </div>
                </SortableItem>
            ))}
        </SortableList>
    );
};

export default ImageList;
