import React from 'react';
import styled from '@emotion/styled';
import logoImg from '../../assets/login_logo.png';

const HeaderWrapper = styled.div`
    border-bottom: 1px solid #ccc;
    background: #fff;
`;

const HeaderArea = styled.div`
    width: 1200px;
    height: 96px;
    margin: 0 auto;
`;

const HeaderLogo = styled.h2`
    float: left;
    width: 165px;
    height: 25px;
    margin-top: 35px;
`;

const Logo = styled.img`
    width: 165px;
    cursor: pointer;
`;

const Header = () => {
    return (
        <HeaderWrapper>
            <HeaderArea>
                <HeaderLogo>
                    <Logo src={logoImg} />
                </HeaderLogo>
            </HeaderArea>
        </HeaderWrapper>
    );
};

export default React.memo(Header);
