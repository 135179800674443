import React, { useState } from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { MenuItem } from '../typings/common/types';

const Base = styled.div``;

const MenuGroupTitle = styled.h3`
    height: 56px;
    line-height: 56px;
    padding-left: 30px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    background: #398fce;
    cursor: pointer;
    border-bottom: #2d518e 1px solid;
`;

const MouseDiv = styled.div`
    width: 30px;
    height: 56px;
`;
const MenuDiv = styled.div`
    background-color: #398fce;
    padding-left: 20px;
    border-radius: 4px;
`;

interface SubMenuProps {
    idx: number;
}
const SubMenuBase = styled.div<SubMenuProps>`
    display: flex;
    position: absolute;
    width: calc(100% + 70px);
    left: 198px;
    top: ${({ idx }) => `${idx * 57}px`};
    z-index: 100;
`;

const SubMenuUl = styled.ul`
    padding-top: 15px;
    padding-bottom: 15px;
    list-style-type: none;
    margin: 0;
`;

const SubMenuLi = styled.li`
    font-size: 15px;
    padding: 8px 30px 8px 5px;
`;

interface AddBtnProps {
    style: CSSStyleRule;
    titleAccess: string;
    onClick: () => void;
}

interface Props {
    title: string;
    items: Array<MenuItem>;
    idx: number;
    addBtn?: React.ReactElement<AddBtnProps>;
}
function HoverMenu({ title, items, idx, addBtn }: Props) {
    const [isMainMenuEnter, setIsMainMenuEnter] = useState(false);
    const [isSubMenuEnter, setIsSubMenuEnter] = useState(false);
    const handleMainMenuMouseEnter = () => {
        setIsMainMenuEnter(true);
    };
    const handleMainMenuMouseLeave = () => {
        setIsMainMenuEnter(false);
    };
    const handleSubMenuMouseEnter = () => {
        setIsSubMenuEnter(true);
    };
    const handleSubMenuMouseLeave = () => {
        setIsSubMenuEnter(false);
    };

    return (
        <Base>
            <MenuGroupTitle
                onMouseEnter={handleMainMenuMouseEnter}
                onMouseLeave={handleMainMenuMouseLeave}
            >
                <>
                    {title} {addBtn}
                </>
            </MenuGroupTitle>
            {(isMainMenuEnter || isSubMenuEnter) && (
                <SubMenuBase
                    idx={idx}
                    onMouseEnter={handleSubMenuMouseEnter}
                    onMouseLeave={handleSubMenuMouseLeave}
                >
                    <MouseDiv></MouseDiv>
                    <MenuDiv>
                        <SubMenuUl>
                            {items.map((v) => (
                                <SubMenuLi key={v.url}>
                                    <NavLink
                                        to={v.url}
                                        style={{ color: '#fff' }}
                                        onClick={() => {
                                            setIsSubMenuEnter(false);
                                            setIsMainMenuEnter(false);
                                        }}
                                    >
                                        {v.title}
                                    </NavLink>
                                </SubMenuLi>
                            ))}
                        </SubMenuUl>
                    </MenuDiv>
                </SubMenuBase>
            )}
        </Base>
    );
}

export default React.memo(HoverMenu);
