import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import { useQuery } from 'react-query';
import {
    DeleteMappingProductClassificationRequest,
    InsertMainKeywordRequest,
    InsertMappingProductClassificationRequest,
    InsertProductClassificationRequest,
    InsertReservationCommendRequest,
    InsertReservationCompanyTagRequest,
    InsertReservationKeywordRequest,
    InsertReservationMiddleClassificationRequest,
    LargeCategoryOrderChangeRequest,
    MainKeywordOrderChangeRequest,
    MainKeywordVisibilityRequest,
    ModifyMainKeywordRequest,
    ModifyProductClassificationRequest,
    ModifyReservationCommendRequest,
    ModifyReservationCompanyTagRequest,
    ModifyReservationKeywordRequest,
    ModifyReservationMiddleClassificationRequest,
    ProductClassificationOrderChangeRequest,
    ReservationCommendOrderChangeRequest,
    ReservationCommendVisibilityRequest,
    ReservationCompanyTagOrderChangeRequest,
    ReservationKeywordOrderChangeRequest,
    ReservationMiddleClassificationOrderChangeRequest,
} from '../../components/typings/keyword/types';

const getAdMainKeywordApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/ad/main/keyword',
        method: 'GET',
    });
};

export const useAdMainKeywordQuery = () =>
    useQuery(['useAdMainKeywordQuery'], () => getAdMainKeywordApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const deleteAdMainKeyword = (adMainKeywordSn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/main/keyword',
        method: 'DELETE',
        data: {
            adMainKeywordSn,
        },
    });

export const insertAdMainKeyword = (params: InsertMainKeywordRequest) => {
    let formData = new FormData();
    const file = document.getElementById('file-input') as HTMLInputElement;
    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    formData.append('title', params.title);
    formData.append('url', params.url);
    formData.append('adminId', params.adminId);
    formData.append('description', params.description);

    return axiosApiInstance.post(apiUrl + '/api/ad/main/keyword', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const modifyAdMainKeyword = (params: ModifyMainKeywordRequest) => {
    let formData = new FormData();
    if (params.fileName !== '') {
        const file = document.getElementById('file-input') as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append('adMainKeywordSn', params.adMainKeywordSn.toString());
    formData.append('title', params.title);
    formData.append('url', params.url);
    formData.append('adminId', params.adminId);
    formData.append('description', params.description);

    return axiosApiInstance.put(apiUrl + '/api/ad/main/keyword', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const setAdMainKeywordVisibility = (
    params: MainKeywordVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/main/keyword/visibility',
        method: 'PUT',
        data: params,
    });

export const setAdMainKeywordOrderChange = (
    params: MainKeywordOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/main/keyword/order-change',
        method: 'PUT',
        data: params,
    });

const getReservationKeywordApi = (typeId: number, popupTypeId?: number) => {
    return axiosApiInstance({
        url: apiUrl + '/api/reservation/keyword',
        method: 'GET',
        params: {
            typeId: typeId === 5 ? popupTypeId : typeId,
        },
    });
};

export const useReservationKeywordQuery = (
    typeId: number,
    popupTypeId?: number,
) =>
    useQuery(
        ['useReservationKeywordQuery'],
        () => getReservationKeywordApi(typeId, popupTypeId),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const insertReservationKeyword = (
    params: InsertReservationKeywordRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/reservation/keyword',
        method: 'POST',
        data: params,
    });

export const modifyReservationKeyword = (
    params: ModifyReservationKeywordRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/reservation/keyword',
        method: 'PUT',
        data: params,
    });

export const deleteReservationKeyword = (keywordId: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/reservation/keyword',
        method: 'DELETE',
        data: {
            keywordId,
        },
    });

export const setReservationKeywordOrderChange = (
    params: ReservationKeywordOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/reservation/keyword/order-change',
        method: 'PUT',
        data: params,
    });

const getReservationCompanyTagTopListApi = (typeId: number) => {
    return axiosApiInstance({
        url: apiUrl + '/api/reservation-tag/top',
        method: 'GET',
        params: {
            typeId,
        },
    });
};

export const useReservationCompanyTagTopQuery = (typeId: number) =>
    useQuery(
        ['useReservationCompanyTagTopQuery'],
        () => getReservationCompanyTagTopListApi(typeId),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const insertReservationCompanyTag = (
    params: InsertReservationCompanyTagRequest,
) => {
    let formData = new FormData();

    if (params.fileName !== '') {
        const file = document.getElementById('file-input') as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append('tagId', params.tagId.toString());
    formData.append('typeId', params.typeId.toString());

    return axiosApiInstance.post(
        apiUrl + '/api/reservation-tag/top',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const modifyReservationCompanyTagTop = (
    params: ModifyReservationCompanyTagRequest,
) => {
    let formData = new FormData();

    if (params.fileName !== '') {
        const file = document.getElementById('file-input') as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append('tagTopId', params.tagTopId.toString());
    formData.append('tagId', params.tagId.toString());
    formData.append('typeId', params.typeId.toString());

    return axiosApiInstance.put(apiUrl + '/api/reservation-tag/top', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const deleteReservationCompanyTagTop = (tagTopId: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/reservation-tag/top',
        method: 'DELETE',
        data: {
            tagTopId,
        },
    });

export const setReservationCompanyTagTopOrderChange = (
    params: ReservationCompanyTagOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/reservation-tag/top/order-change',
        method: 'PUT',
        data: params,
    });

const getCompanyRecommendApi = () =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/company/recommend',
        method: 'GET',
    });

export const useCompanyRecommendQuery = () =>
    useQuery(['useCompanyRecommendQuery'], () => getCompanyRecommendApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const insertCompanyRecommend = (
    params: InsertReservationCommendRequest,
) => {
    let formData = new FormData();
    const file = document.getElementById('file-input') as HTMLInputElement;
    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    formData.append('title', params.title);
    formData.append('url', params.url);
    formData.append('description', params.description);

    return axiosApiInstance.post(
        apiUrl + '/api/ad/company/recommend',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const modifyCompanyRecommend = (
    params: ModifyReservationCommendRequest,
) => {
    let formData = new FormData();
    if (params.fileName !== '') {
        const file = document.getElementById('file-input') as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append(
        'adCompanyRecommendSn',
        params.adCompanyRecommendSn.toString(),
    );
    formData.append('title', params.title);
    formData.append('url', params.url);
    formData.append('description', params.description);

    return axiosApiInstance.put(
        apiUrl + '/api/ad/company/recommend',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const deleteCompanyRecommend = (adCompanyRecommendSn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/company/recommend',
        method: 'DELETE',
        data: {
            adCompanyRecommendSn,
        },
    });

export const setCompanyRecommendVisibility = (
    params: ReservationCommendVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/company/recommend/visibility',
        method: 'PUT',
        data: params,
    });

export const setCompanyRecommendOrderChange = (
    params: ReservationCommendOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/company/recommend/order-change',
        method: 'PUT',
        data: params,
    });

const getReservationMiddleClassificationApi = (typeId: number) => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/middle-classification',
        method: 'GET',
        params: {
            typeId,
        },
    });
};

export const useReservationMiddleClassificationQuery = (typeId: number) =>
    useQuery(
        ['useReservationMiddleClassificationQuery'],
        () => getReservationMiddleClassificationApi(typeId),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const insertReservationMiddleClassification = (
    params: InsertReservationMiddleClassificationRequest,
) => {
    let formData = new FormData();
    const imgFile = document.getElementById(
        'img-file-input',
    ) as HTMLInputElement;
    if (imgFile && imgFile.files) {
        formData.append('imgFile', imgFile.files[0]);
    }
    const activeImgFile = document.getElementById(
        'active-img-file-input',
    ) as HTMLInputElement;
    if (activeImgFile && activeImgFile.files) {
        formData.append('activeImgFile', activeImgFile.files[0]);
    }
    formData.append('productCodeId', params.productCodeId.toString());
    formData.append('typeId', params.typeId.toString());

    return axiosApiInstance.post(
        apiUrl + '/api/v1/join/middle-classification',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const modifyReservationMiddleClassification = (
    params: ModifyReservationMiddleClassificationRequest,
) => {
    let formData = new FormData();
    if (params.imgFileName !== '') {
        const imgFile = document.getElementById(
            'img-file-input',
        ) as HTMLInputElement;
        if (imgFile && imgFile.files) {
            formData.append('imgFile', imgFile.files[0]);
        }
    }
    if (params.activeImgFileName !== '') {
        const activeImgFile = document.getElementById(
            'active-img-file-input',
        ) as HTMLInputElement;
        if (activeImgFile && activeImgFile.files) {
            formData.append('activeImgFile', activeImgFile.files[0]);
        }
    }
    formData.append('id', params.id.toString());

    return axiosApiInstance.put(
        apiUrl + '/api/v1/join/middle-classification',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const setReservationMiddleClassificationOrderChange = (
    params: ReservationMiddleClassificationOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/middle-classification/order-change',
        method: 'PUT',
        data: params,
    });

export const deleteReservationMiddleClassification = (id: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/middle-classification',
        method: 'DELETE',
        data: {
            id,
        },
    });

const getProductCodeApi = (typeId: number) => {
    return axiosApiInstance({
        url:
            apiUrl +
            `/api/v1/join/middle-classification/product-code?typeId=${typeId}`,
        method: 'GET',
    });
};

export const useProductCodesQuery = (typeId: number) =>
    useQuery(['useProductCodesQuery'], () => getProductCodeApi(typeId), {
        retry: 3,
        keepPreviousData: true,
    });

const getLargeCategoryApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/large-category',
        method: 'GET',
    });
};

export const useLargeCategoryQuery = () =>
    useQuery(['useLargeCategoryQuery'], () => getLargeCategoryApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const setLargeCategoryOrderChange = (
    params: LargeCategoryOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/large-category/order-change',
        method: 'PUT',
        data: params,
    });

const getProductClassificationApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/keyword/middle-classification',
        method: 'GET',
    });
};

export const useProductClassificationQuery = () =>
    useQuery(
        ['useProductClassificationQuery'],
        () => getProductClassificationApi(),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const setProductClassificationOrderChange = (
    params: ProductClassificationOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/keyword/middle-classification/order-change',
        method: 'PUT',
        data: params,
    });

export const insertProductClassification = (
    params: InsertProductClassificationRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/keyword/middle-classification',
        method: 'POST',
        data: params,
    });

export const modifyProductClassification = (
    params: ModifyProductClassificationRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/keyword/middle-classification',
        method: 'PUT',
        data: params,
    });

const getMappingProductClassificationApi = (typeId: number) => {
    return axiosApiInstance({
        url:
            apiUrl +
            `/api/v1/join/keyword/mapping/middle-classification?typeId=${typeId}`,
        method: 'GET',
    });
};

export const useMappingProductClassificationQuery = (typeId: number) =>
    useQuery(
        ['useMappingProductClassificationQuery'],
        () => getMappingProductClassificationApi(typeId),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

const getMappingTargetProductClassificationApi = (typeId: number) => {
    return axiosApiInstance({
        url:
            apiUrl +
            `/api/v1/join/keyword/mapping/middle-classification/product-code?typeId=${typeId}`,
        method: 'GET',
    });
};

export const useMappingTargetProductClassificationQuery = (typeId: number) =>
    useQuery(
        ['useMappingTargetProductClassificationQuery'],
        () => getMappingTargetProductClassificationApi(typeId),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const insertMappingProductClassification = (
    params: InsertMappingProductClassificationRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/keyword/mapping/middle-classification',
        method: 'POST',
        data: params,
    });

export const deleteMappingProductClassification = (
    params: DeleteMappingProductClassificationRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/keyword/mapping/middle-classification',
        method: 'DELETE',
        data: params,
    });
