import React, { useState } from 'react';
import { Button } from '@mui/material';
import { DeleteRounded } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import {
    deleteMappingVideoList,
    insertMappingVideoList,
    useMappingVideoQuery,
    useVideoListQuery,
} from '../../hooks/leppa-play/useLeppaPlay';
import { MappingVideoData } from '../typings/leppa-play/response';
import { AutoCompleteOnSelectData } from '../typings/leppa-play/types';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const AutoCompleteArea = styled.div`
    width: 300px;
    margin-right: 10px;
`;

const SearchArea = styled.div`
    align-items: center;
    display: flex;
    float: right;
`;

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`;

const LeppaPlayRecommendVideo = () => {
    const [videoId, setVideoId] = useState('');
    const [videoName, setVideoName] = useState('');

    const queryClient = useQueryClient();
    const { data: nameList } = useVideoListQuery();

    const { isLoading, isError, data } = useMappingVideoQuery();

    const deleteMappingVideoListMutation = useMutation(deleteMappingVideoList, {
        onSuccess: () => {
            queryClient.invalidateQueries('useMappingVideoQuery');
            queryClient.invalidateQueries('useVideoListQuery');
        },
    });

    const insertMappingVideoListMutation = useMutation(insertMappingVideoList, {
        onSuccess: () => {
            queryClient.invalidateQueries('useMappingVideoQuery');
            queryClient.invalidateQueries('useVideoListQuery');
        },
    });

    const deleteClick = (v: MappingVideoData) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteMappingVideoListMutation.mutate(v.mappingId);
        }
    };
    const handleInsert = () => {
        if (videoId === '') {
            alert('영상명을 입력해 주세요.');
            return false;
        }
        setVideoId('');
        setVideoName('');
        insertMappingVideoListMutation.mutate(videoId);
    };

    const handleOnSelect = (v: AutoCompleteOnSelectData) => {
        setVideoId(v.id);
    };

    const handleOnSearch = (string: string) => {
        setVideoName(string);
    };

    const handleOnClear = () => {
        setVideoName('');
        setVideoId('');
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        추천 영상 관리
                    </Title>
                    <SearchArea>
                        <div>
                            {nameList && (
                                <AutoCompleteArea>
                                    <ReactSearchAutocomplete
                                        items={nameList.data.ResultData}
                                        onSelect={handleOnSelect}
                                        onSearch={handleOnSearch}
                                        onClear={handleOnClear}
                                        showIcon={false}
                                        inputSearchString={videoName}
                                        styling={{
                                            borderRadius: '0',
                                            height: '37px',
                                        }}
                                    />
                                </AutoCompleteArea>
                            )}
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleInsert}
                            >
                                등록
                            </Button>
                        </div>
                    </SearchArea>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '400px' }} />
                                <col style={{ width: '300px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '100px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>제목</Th>
                                    <Th>채널</Th>
                                    <Th>영상</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.map(
                                    (v: MappingVideoData) => {
                                        return (
                                            <tr key={v.mappingId}>
                                                <Td>{v.title}</Td>
                                                <Td>
                                                    <FlexDiv>
                                                        <img
                                                            src={v.profileImg}
                                                            width="100px"
                                                            alt="썸네일 이미지"
                                                        />
                                                        {v.name}
                                                    </FlexDiv>
                                                </Td>
                                                <td>
                                                    <img
                                                        src={v.thumbnails}
                                                        width="300px"
                                                        alt="썸네일 이미지"
                                                        onClick={() =>
                                                            window.open(
                                                                v.videoLink,
                                                                '_blank',
                                                            )
                                                        }
                                                    />
                                                </td>
                                                <Td>
                                                    <DeleteRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            deleteClick(v);
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                </Base>
            )}
        </>
    );
};

export default React.memo(LeppaPlayRecommendVideo);
