import { atom } from 'recoil';

export const loginState = atom({
    key: 'loginState',
    default: '',
});

export const orderDetailState = atom({
    key: 'orderDetailState',
    default: false,
});

export const orderDataState = atom({
    key: 'orderDataState',
    default: {
        orderId: '',
    },
});
