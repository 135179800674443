import React, { useEffect, useState } from 'react';
import { SendRounded } from '@mui/icons-material';
import {
    pushAlarmManager,
    useReservationListQuery,
} from '../../hooks/reservation/useReservation';
import Pagination from '../common/Pagination';
import { getReservationStatusName } from '../../utils/util';
import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import { ReservationData } from '../typings/reservation/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const Reservation = () => {
    const pageSize = 20;
    const [currListPage, setCurrListPage] = useState(1);

    const queryClient = useQueryClient();
    const { isLoading, isError, data, refetch } = useReservationListQuery(
        currListPage,
        pageSize,
    );

    const pushAlarmManagerMutation = useMutation(pushAlarmManager, {
        onSuccess: () => {
            queryClient.invalidateQueries('useReservationListQuery');
        },
    });

    const setList = (page: number) => {
        setCurrListPage(page);
    };

    const sendManagerPush = (data: ReservationData) => {
        if (window.confirm('사업자에게 푸시알림을 발송하시겠습니까?')) {
            pushAlarmManagerMutation.mutate(data.bargSn);
        }
    };

    useEffect(() => {
        refetch();
    }, [currListPage, refetch]);

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title>
                        예약리스트(
                        <span className="list">
                            {data?.data.ResultData.totalElements}
                        </span>
                        )
                    </Title>
                    <TableArea>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '100px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>사업장명</Th>
                                    <Th>예약번호</Th>
                                    <Th>상품명/이용일</Th>
                                    <Th>예약자정보</Th>
                                    <Th>예약금액</Th>
                                    <Th>예약상태</Th>
                                    <Th>결제일</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.content.map(
                                    (v: ReservationData) => {
                                        return (
                                            <tr key={v.merchantUid}>
                                                <Td>{v.bbargeName}</Td>
                                                <Td>{v.merchantUid}</Td>
                                                <Td>
                                                    {v.title !== ''
                                                        ? v.title
                                                        : `${v.boatName} | ${v.leisureName}`}
                                                    /
                                                    {v.reservationDate +
                                                        (v.startTime !== ''
                                                            ? v.endTime !==
                                                                  '' &&
                                                              v.startTime !==
                                                                  v.endTime
                                                                ? ` ${v.startTime} ~ ${v.endTime}`
                                                                : ` ${v.startTime}`
                                                            : '')}
                                                </Td>
                                                <Td>
                                                    {`${v.nickName} | ${v.name}`}
                                                    <br />
                                                    {v.userCount > 0
                                                        ? v.galleryCount > 0
                                                            ? `인원${v.userCount}명/갤러리${v.galleryCount}명`
                                                            : `인원${v.userCount}명`
                                                        : ''}
                                                </Td>
                                                <Td>{`${v.reservationPrice.toLocaleString()}원`}</Td>
                                                <Td>
                                                    {getReservationStatusName(
                                                        v.reservationStatus,
                                                    )}
                                                </Td>
                                                <Td>{v.regDt}</Td>
                                                <Td>
                                                    {v.reservationStatus ===
                                                        'RESERVATION_WAIT' && (
                                                        <SendRounded
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                sendManagerPush(
                                                                    v,
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                        {data?.data.ResultData.totalElements > 0 && (
                            <Pagination
                                itemsCount={data?.data.ResultData.totalElements}
                                pageSize={pageSize}
                                currPage={currListPage}
                                setList={setList}
                            />
                        )}
                    </TableArea>
                </Base>
            )}
        </>
    );
};

export default React.memo(Reservation);
