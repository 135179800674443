import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import { useQuery, UseQueryResult } from 'react-query';
import {
    BannerType,
    InsertIntegrationAdvertisementRequest,
    IntegrationAdvertisementOrderChangeDragRequest,
    IntegrationAdvertisementResponse,
    IntegrationAdvertisementVisibilityRequest,
    ModifyIntegrationAdvertisementRequest,
} from '../../components/typings/integrationAd/types';
import { AxiosResponse } from 'axios';

const getIntegrationAdvertisement = (bannerType: BannerType) => {
    return axiosApiInstance.get(
        apiUrl + `/api/v1/integration-advertisement?bannerType=${bannerType}`,
    );
};

export const useGetIntegrationAdvertisement = (
    bannerType: BannerType,
): UseQueryResult<AxiosResponse<IntegrationAdvertisementResponse>, Error> =>
    useQuery(
        ['useGetIntegrationAdvertisement'],
        () => getIntegrationAdvertisement(bannerType),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const setIntegrationAdvertisementDragOrderChange = (
    params: IntegrationAdvertisementOrderChangeDragRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/integration-advertisement/order-change',
        method: 'PUT',
        data: params,
    });

export const setIntegrationAdvertisementVisibility = (
    params: IntegrationAdvertisementVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/integration-advertisement/visibility',
        method: 'PUT',
        data: params,
    });

export const deleteIntegrationAdvertisement = (bannerId: number) =>
    axiosApiInstance({
        url: apiUrl + `api/v1/integration-advertisement/${bannerId}`,
        method: 'DELETE',
    });

export const modifyIntegrationAdvertisement = (
    params: ModifyIntegrationAdvertisementRequest,
) => {
    let formData = new FormData();
    if (params.fileName !== '') {
        const file = document.getElementById(
            'integration-ad-file-input',
        ) as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append('bannerId', params.bannerId.toString());
    formData.append('company', params.company);
    formData.append('eventTitle', params.eventTitle);
    formData.append('url', params.url);
    formData.append('bannerType', params.bannerType);
    formData.append('description', params.description);
    formData.append('bgColor', params.bgColor);

    return axiosApiInstance.put(
        apiUrl + '/api/v1/integration-advertisement',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};
export const InsertIntegrationAdvertisement = (
    params: InsertIntegrationAdvertisementRequest,
) => {
    const file = document.getElementById(
        'integration-ad-file-input',
    ) as HTMLInputElement;
    let formData = new FormData();
    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    formData.append('company', params.company);
    formData.append('eventTitle', params.eventTitle);
    formData.append('url', params.url);
    formData.append('bannerType', params.bannerType);
    formData.append('description', params.description);
    formData.append('bgColor', params.bgColor);

    return axiosApiInstance.post(
        apiUrl + '/api/v1/integration-advertisement',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};
