import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import { useQuery, UseQueryResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { LeppaPointDetailResponse } from '../../components/typings/point/response';
import { MemberPointControlRequest } from '../../components/typings/point/types';

const getPointApi = (
    user: string,
    dateType: string,
    startDate: string,
    endDate: string,
    type: string,
    status: string,
    usage: string,
    page: number,
    size: number,
) => {
    return axiosApiInstance({
        url:
            apiUrl +
            `/api/v1/join/leppa-point?user=${user}&startDate=${startDate}&endDate=${endDate}&type=${type.replace(
                'ALL',
                '',
            )}&status=${status.replace(
                'ALL',
                '',
            )}&usage=${usage}&dateType=${dateType}&page=${
                page - 1
            }&size=${size}`,
        method: 'GET',
    });
};

export const usePointQuery = (
    user: string,
    dateType: string,
    startDate: string,
    endDate: string,
    type: string,
    status: string,
    usage: string,
    page: number,
    size: number,
) =>
    useQuery(
        ['usePointQuery'],
        () =>
            getPointApi(
                user,
                dateType,
                startDate,
                endDate,
                type,
                status,
                usage,
                page,
                size,
            ),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

const getPointPopupListApi = (memberNo: string, page: number, size: number) => {
    return axiosApiInstance({
        url:
            apiUrl +
            `/api/v1/join/leppa-point?memberNo=${memberNo}&page=${
                page - 1
            }&size=${size}`,
        method: 'GET',
    });
};

export const usePointPopupListQuery = (
    memberNo: string,
    page: number,
    size: number,
) =>
    useQuery(
        ['usePointPopupListQuery'],
        () => getPointPopupListApi(memberNo, page, size),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

const getPointDetailApi = (eventId: string) => {
    return axiosApiInstance({
        url: apiUrl + `/api/v1/join/leppa-point/${eventId}`,
        method: 'GET',
    });
};

export const usePointDetailQuery = (
    eventId: string,
): UseQueryResult<AxiosResponse<LeppaPointDetailResponse>, Error> =>
    useQuery(['usePointDetailQuery'], () => getPointDetailApi(eventId), {
        retry: 3,
        keepPreviousData: true,
    });

export const memberPointControl = (params: MemberPointControlRequest) =>
    axiosApiInstance({
        url: apiUrl + `/api/v1/join/leppa-point/${params.memberNo}/mod`,
        method: 'POST',
        data: {
            amount: params.amount,
            description: params.description,
        },
    });
