import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import { useQuery } from 'react-query';
import {
    AdvertisementBottomRandomVisibilityRequest,
    AdvertisementBottomVisibilityRequest,
    AdvertisementChatVisibilityRequest,
    AdvertisementMarketTopVisibilityRequest,
    AdvertisementSmartOrderChangeRequest,
    AdvertisementSmartVisibilityRequest,
    AdvertisementTextVisibilityRequest,
    AdvertisementTopOrderChangeDragRequest,
    AdvertisementTopOrderChangeRequest,
    AdvertisementTopVisibilityRequest,
    CompanyPromotionOrderChangeDragRequest,
    CompanyPromotionVisibilityRequest,
    InsertAdvertisementBottomRandomRequest,
    InsertAdvertisementBottomRequest,
    InsertAdvertisementChatRequest,
    InsertAdvertisementMarketTopRequest,
    InsertAdvertisementSmartRequest,
    InsertAdvertisementTextRequest,
    InsertAdvertisementTopRequest,
    InsertAlarmRequest,
    InsertCompanyPromotionRequest,
    InsertMarketingPushRequest,
    ModifyAdvertisementBottomRandomRequest,
    ModifyAdvertisementBottomRequest,
    ModifyAdvertisementChatRequest,
    ModifyAdvertisementMarketTopRequest,
    ModifyAdvertisementSmartRequest,
    ModifyAdvertisementTextRequest,
    ModifyAdvertisementTopRequest,
    ModifyAlarmRequest,
    ModifyCompanyPromotionRequest,
    ModifyMarketingPushRequest,
    SendAlarmRequest,
} from '../../components/typings/ad/types';

const getAdAlarmApi = (page: number, size: number) => {
    return axiosApiInstance.get(
        apiUrl + '/api/ad/alarm?size=' + size + '&page=' + (page - 1),
    );
};

export const useAdAlarm = (page: number, size: number) =>
    useQuery(['useAdAlarm'], () => getAdAlarmApi(page, size), {
        retry: 3,
        keepPreviousData: true,
    });

export const sendAdAlarm = (params: SendAlarmRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/alarm/send',
        method: 'POST',
        data: {
            params,
        },
    });

export const insertAdAlarm = (params: InsertAlarmRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/alarm',
        method: 'POST',
        data: params,
    });

export const modifyAdAlarm = (params: ModifyAlarmRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/alarm',
        method: 'PUT',
        data: params,
    });

export const deleteAdAlarm = (adSn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/alarm',
        method: 'DELETE',
        data: {
            adSn,
        },
    });

const getAdTopApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/ad/top',
        method: 'GET',
    });
};

export const useAdTopQuery = () =>
    useQuery(['useAdTopQuery'], () => getAdTopApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const deleteAdTop = (adTopSn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/top',
        method: 'DELETE',
        data: {
            adTopSn,
        },
    });

export const insertAdTop = (params: InsertAdvertisementTopRequest) => {
    const file = document.getElementById('file-input') as HTMLInputElement;
    let formData = new FormData();

    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('adminId', params.adminId);
    formData.append('description', params.description);
    return axiosApiInstance.post(apiUrl + '/api/ad/top', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const modifyAdTop = (params: ModifyAdvertisementTopRequest) => {
    let formData = new FormData();
    if (params.fileName !== '') {
        const file = document.getElementById('file-input') as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append('adTopSn', params.adTopSn.toString());
    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('adminId', params.adminId);
    formData.append('description', params.description);
    return axiosApiInstance.put(apiUrl + '/api/ad/top', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const setAdTopVisibility = (params: AdvertisementTopVisibilityRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/top/visibility',
        method: 'PUT',
        data: params,
    });

export const setAdTopOrderChange = (
    params: AdvertisementTopOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/top/order-change',
        method: 'PUT',
        data: params,
    });

export const setAdTopDragOrderChange = (
    params: AdvertisementTopOrderChangeDragRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/top/order-change/drag',
        method: 'PUT',
        data: params,
    });

const getAdBottomApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/ad/bottom',
        method: 'GET',
    });
};

export const useAdBottomQuery = () =>
    useQuery(['useAdBottomQuery'], () => getAdBottomApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const deleteAdBottom = (adBottomSn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/bottom',
        method: 'DELETE',
        data: {
            adBottomSn,
        },
    });

export const insertAdBottom = (params: InsertAdvertisementBottomRequest) => {
    let formData = new FormData();
    const file = document.getElementById(
        'file-bottom-input',
    ) as HTMLInputElement;

    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('adminId', params.adminId);
    formData.append('description', params.description);
    return axiosApiInstance.post(apiUrl + '/api/ad/bottom', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const modifyAdBottom = (params: ModifyAdvertisementBottomRequest) => {
    let formData = new FormData();
    if (params.fileName !== '') {
        const file = document.getElementById(
            'file-bottom-input',
        ) as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append('adBottomSn', params.adBottomSn.toString());
    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('adminId', params.adminId);
    formData.append('description', params.description);
    return axiosApiInstance.put(apiUrl + '/api/ad/bottom', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const setAdBottomVisibility = (
    params: AdvertisementBottomVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/bottom/visibility',
        method: 'PUT',
        data: params,
    });

const getAdBottomRandomApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/ad/bottom/random',
        method: 'GET',
    });
};

export const useAdBottomRandomQuery = () =>
    useQuery(['useAdBottomRandomQuery'], () => getAdBottomRandomApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const deleteAdBottomRandom = (adBottomRandomSn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/bottom/random',
        method: 'DELETE',
        data: {
            adBottomRandomSn,
        },
    });

export const insertAdBottomRandom = (
    params: InsertAdvertisementBottomRandomRequest,
) => {
    let formData = new FormData();
    const file = document.getElementById(
        'file-bottom-input',
    ) as HTMLInputElement;
    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('description', params.description);

    return axiosApiInstance.post(apiUrl + '/api/ad/bottom/random', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const modifyAdBottomRandom = (
    params: ModifyAdvertisementBottomRandomRequest,
) => {
    let formData = new FormData();
    if (params.fileName !== '') {
        const file = document.getElementById(
            'file-bottom-input',
        ) as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append('adBottomRandomSn', params.adBottomRandomSn.toString());
    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('description', params.description);

    return axiosApiInstance.put(apiUrl + '/api/ad/bottom/random', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const setAdBottomRandomVisibility = (
    params: AdvertisementBottomRandomVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/bottom/random/visibility',
        method: 'PUT',
        data: params,
    });

const getAdChatApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/ad/chat',
        method: 'GET',
    });
};

export const useAdChatQuery = () =>
    useQuery(['useAdChatQuery'], () => getAdChatApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const insertAdChat = (params: InsertAdvertisementChatRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/chat',
        method: 'POST',
        data: params,
    });

export const modifyAdChat = (params: ModifyAdvertisementChatRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/chat',
        method: 'PUT',
        data: params,
    });

export const deleteAdChat = (sn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/chat',
        method: 'DELETE',
        data: {
            sn,
        },
    });

export const setAdChatVisibility = (
    params: AdvertisementChatVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/chat/visibility',
        method: 'PUT',
        data: params,
    });

const getAdSmartApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/ad/smart',
        method: 'GET',
    });
};

export const useAdSmartQuery = () =>
    useQuery(['useAdSmartQuery'], () => getAdSmartApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const deleteAdSmart = (adSmartSn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/smart',
        method: 'DELETE',
        data: {
            adSmartSn,
        },
    });

export const insertAdSmart = (params: InsertAdvertisementSmartRequest) => {
    let formData = new FormData();
    const file = document.getElementById('file-input') as HTMLInputElement;
    if (file && file.files) {
        formData.append('file', file.files[0]);
    }

    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('adminId', params.adminId);
    formData.append('description', params.description);

    return axiosApiInstance.post(apiUrl + '/api/ad/smart', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const modifyAdSmart = (params: ModifyAdvertisementSmartRequest) => {
    let formData = new FormData();
    if (params.fileName !== '') {
        const file = document.getElementById('file-input') as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append('adSmartSn', params.adSmartSn.toString());
    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('adminId', params.adminId);
    formData.append('description', params.description);

    return axiosApiInstance.put(apiUrl + '/api/ad/smart', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const setAdSmartVisibility = (
    params: AdvertisementSmartVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/smart/visibility',
        method: 'PUT',
        data: params,
    });

export const setAdSmartOrderChange = (
    params: AdvertisementSmartOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/smart/order-change',
        method: 'PUT',
        data: params,
    });

const getAdMarketTopApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/ad/market/top',
        method: 'GET',
    });
};

export const useAdMarketTopQuery = () =>
    useQuery(['useAdMarketTopQuery'], () => getAdMarketTopApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const deleteAdMarketTop = (adMarketTopSn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/market/top',
        method: 'DELETE',
        data: {
            adMarketTopSn,
        },
    });

export const insertAdMarketTop = (
    params: InsertAdvertisementMarketTopRequest,
) => {
    let formData = new FormData();
    const file = document.getElementById('file-input') as HTMLInputElement;
    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('adminId', params.adminId);
    formData.append('description', params.description);

    return axiosApiInstance.post(apiUrl + '/api/ad/market/top', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const modifyAdMarketTop = (
    params: ModifyAdvertisementMarketTopRequest,
) => {
    let formData = new FormData();
    if (params.fileName !== '') {
        const file = document.getElementById('file-input') as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append('adMarketTopSn', params.adMarketTopSn.toString());
    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('adminId', params.adminId);
    formData.append('description', params.description);

    return axiosApiInstance.put(apiUrl + '/api/ad/market/top', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const setAdMarketTopVisibility = (
    params: AdvertisementMarketTopVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/ad/market/top/visibility',
        method: 'PUT',
        data: params,
    });

const getCompanyTopApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/company/top/list',
        method: 'GET',
    });
};

export const useCompanyTopQuery = () =>
    useQuery(['useCompanyTopQuery'], () => getCompanyTopApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const deleteCompanyTopList = (topListId: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/company/top/list',
        method: 'DELETE',
        data: {
            topListId,
        },
    });

export const insertCompanyTopList = (companyId: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/company/top/list',
        method: 'POST',
        data: {
            companyId,
        },
    });

const getCompanyNameListApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/get-company-name-list',
        method: 'GET',
    });
};

export const useCompanyNameListQuery = () =>
    useQuery(['useCompanyNameListQuery'], () => getCompanyNameListApi(), {
        retry: 3,
        keepPreviousData: true,
    });

const getAdMarketingPushApi = (page: number, size: number) => {
    return axiosApiInstance.get(
        apiUrl + '/api/v1/join/ad/push?size=' + size + '&page=' + (page - 1),
    );
};

export const useAdMarketingPush = (page: number, size: number) =>
    useQuery(['useAdMarketingPush'], () => getAdMarketingPushApi(page, size), {
        retry: 3,
        keepPreviousData: true,
    });

export const insertAdMarketingPush = (params: InsertMarketingPushRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/ad/push',
        method: 'POST',
        data: params,
    });

export const modifyAdMarketingPush = (params: ModifyMarketingPushRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/ad/push',
        method: 'PUT',
        data: params,
    });

export const deleteAdMarketingPush = (id: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/ad/push',
        method: 'DELETE',
        data: {
            id,
        },
    });

export const useAdTextQuery = () =>
    useQuery(['useAdTextQuery'], () => getAdTextApi(), {
        retry: 3,
        keepPreviousData: true,
    });

const getAdTextApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/advertisement/text',
        method: 'GET',
    });
};

export const deleteAdText = (id: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/advertisement/text',
        method: 'DELETE',
        data: {
            id,
        },
    });

export const insertAdText = (params: InsertAdvertisementTextRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/advertisement/text',
        method: 'POST',
        data: params,
    });

export const modifyAdText = (params: ModifyAdvertisementTextRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/advertisement/text',
        method: 'PUT',
        data: params,
    });

export const setAdTextVisibility = (
    params: AdvertisementTextVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/advertisement/text/visibility',
        method: 'PUT',
        data: params,
    });

const getCompanyPromotionApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/company-promotion',
        method: 'GET',
    });
};

export const useCompanyPromotionQuery = () =>
    useQuery(['useCompanyPromotionQuery'], () => getCompanyPromotionApi(), {
        retry: 3,
    });

export const companyPromotionVisibility = (
    params: CompanyPromotionVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + `/api/v1/join/company-promotion/${params.id}/visibility`,
        method: 'PUT',
        data: params,
    });

export const deleteCompanyPromotion = (id: string) =>
    axiosApiInstance({
        url: apiUrl + `/api/v1/join/company-promotion/${id}`,
        method: 'DELETE',
    });

export const insertCompanyPromotion = (
    params: InsertCompanyPromotionRequest,
) => {
    const file = document.getElementById('file-input') as HTMLInputElement;
    let formData = new FormData();

    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    formData.append('companyId', params.companyId.toString());
    formData.append('description', params.description);
    return axiosApiInstance.post(
        apiUrl + '/api/v1/join/company-promotion',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const modifyCompanyPromotion = (
    params: ModifyCompanyPromotionRequest,
) => {
    const file = document.getElementById('file-input') as HTMLInputElement;
    let formData = new FormData();

    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    formData.append('companyId', params.companyId.toString());
    formData.append('description', params.description);
    return axiosApiInstance.put(
        apiUrl + `/api/v1/join/company-promotion/${params.id}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const setCompanyPromotionDragOrderChange = (
    params: CompanyPromotionOrderChangeDragRequest,
) =>
    axiosApiInstance({
        url:
            apiUrl + `/api/v1/join/company-promotion/${params.id}/order-change`,
        method: 'PUT',
        data: params,
    });

export const useCompanyPromotionNameListQuery = () =>
    useQuery(
        ['useCompanyPromotionNameListQuery'],
        () => getCompanyPromotionNameListApi(),
        {
            retry: 3,
        },
    );

const getCompanyPromotionNameListApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/company-promotion/company-list',
        method: 'GET',
    });
};
