import React, { useState } from 'react';
import { apiUrl, onLoginSuccess } from '../../utils/common';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { loginState } from './atom';
import loginLogo from '../../assets/login_logo.png';
import styled from '@emotion/styled';

const Container = styled.div`
    background: rgb(248, 248, 248);
`;

const Wrapper = styled.div`
    position: relative;
`;

const TitleLogo = styled.img`
    position: absolute;
    left: 50%;
    top: 200px;
    width: 321px;
    height: 50px;
    margin-left: -160px;
    text-indent: -9999px;
    overflow: hidden;
`;

const LoginArea = styled.div`
    position: absolute;
    left: 50%;
    top: 300px;
    width: 500px;
    height: 362px;
    margin-left: -250px;
    background: #fff;
`;

const LoginTitle = styled.h2`
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    padding: 40px 0 30px;
`;

const LoginFieldset = styled.fieldset`
    width: 400px;
    margin: 0 auto;
    border: 0;
`;

const InputArea = styled.div`
    padding-bottom: 15px;
    font-size: 14px;
`;

const Input = styled.input`
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #8e8e8e;
`;

const LoginButton = styled.button`
    display: block;
    width: 100%;
    height: 53px;
    background: #398fce;
    border: 0;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
`;

function LoginForm() {
    const [id, setId] = useState('');
    const [pwd, setPwd] = useState('');
    let navigate = useNavigate();
    const setUserId = useSetRecoilState(loginState);

    const handleClick = () => {
        if (id === '' || pwd === '') {
            return false;
        }
        login(id, pwd);
    };

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleClick();
        }
    };

    const login = async (id: string, password: string) =>
        await axios
            .post(apiUrl + '/api/login', {
                id,
                password,
            })
            .then((res) => {
                if (res.data.ResultCode === 1) {
                    onLoginSuccess(res);
                    setUserId(res.data.ResultData.id);
                    navigate('/');
                }
            });
    return (
        <Container>
            <Wrapper>
                <TitleLogo src={loginLogo} />
                <LoginArea>
                    <LoginTitle>로그인</LoginTitle>
                    <LoginFieldset>
                        <InputArea>
                            <Input
                                type="text"
                                value={id}
                                onChange={({ target: { value } }) =>
                                    setId(value)
                                }
                                placeholder="아이디"
                                maxLength={20}
                            />
                        </InputArea>
                        <InputArea>
                            <Input
                                type="password"
                                onChange={({ target: { value } }) =>
                                    setPwd(value)
                                }
                                placeholder="비밀번호"
                                maxLength={20}
                                onKeyUp={onKeyPress}
                            />
                        </InputArea>
                        <LoginButton onClick={handleClick}>로그인</LoginButton>
                    </LoginFieldset>
                </LoginArea>
            </Wrapper>
        </Container>
    );
}

export default React.memo(LoginForm);
