import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Close from '../../assets/BtnIcClose.png';
import {
    setManualReturnComplete,
    useOrdersDetailQuery,
} from '../../hooks/leppa-market/useLeppaMarket';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { orderDataState, orderDetailState } from '../common/atom';
import { useMutation, useQueryClient } from 'react-query';
import Gift from '../../assets/PageTitle.png';

const Modal = styled.div`
    position: fixed;
    width: 1065px;
    height: 751px;
    top: calc(50% - 375px);
    left: calc(50% - 532.5px);
    background: #fff;
    border-radius: 8px;
`;

const ModalArea = styled.div`
    padding: 0 40px 40px;
`;

const InfoArea = styled.div`
    display: flex;
    padding: 10px 0 35px;
`;

const Info = styled.p`
    padding: 10px 20px 10px 0;
    strong {
        font-size: 14px;
        padding-right: 8px;
    }
`;

const Retrun = styled.button`
    font-size: 14px;
    color: #0482de;
    padding: 10px;
    border: 1px solid #ebeef0;
    border-radius: 4px;
    background: #fff;
    cursor: pointer;
`;

const TableArea = styled.div`
    display: flex;
    gap: 89px;
    table {
        width: 448px;
        border-top: 1px solid #25282b;
        border-bottom: 1px solid #ebeef0;
        td {
            padding: 10px 12px;
        }
    }
`;

const Table = styled.div`
    h2 {
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 15px;
        img {
            padding-right: 4px;
        }
    }
`;

const Thead = styled.th`
    width: 104px;
    color: #3f464e;
    background: #f8f9fa;
    padding: 10px 12px;
    font-weight: 600;
    text-align: left;
`;

const BtnArea = styled.div`
    padding-top: 20px;
    text-align: right;
`;

const CloseBtn = styled.button`
    width: 160px;
    height: 46px;
    line-height: 46px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    background: #0482de;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
`;

const GiftBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 95px;
`;

const Bg = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
`;

const LeppaMarketOrderDetail = () => {
    const ordersData = useRecoilValue(orderDataState);
    const { data, refetch } = useOrdersDetailQuery(ordersData.orderId);
    const setOrderDetailOpen = useSetRecoilState(orderDetailState);

    const queryClient = useQueryClient();
    const ReturnMutation = useMutation(setManualReturnComplete, {
        onSuccess: (res) => {
            if (res.data.ResultData.length > 0) {
                alert('업로드 에러\n주문아이디:' + res.data.ResultData);
            } else {
                alert('반품 완료 처리되었습니다.');
                refetch();
            }
            queryClient.invalidateQueries('useLeppaMarketOrderListQuery');
        },
    });

    const ReturnComplete = () => {
        let params = {
            orderIds: ordersData.orderId.toString(),
        };
        ReturnMutation.mutate(params);
    };

    useEffect(() => {
        refetch();
    }, [ordersData.orderId, refetch]);

    return (
        <>
            <Bg></Bg>
            <Modal>
                <p style={{ textAlign: 'right', padding: '20px 20px 0' }}>
                    <img
                        src={Close}
                        alt="닫기"
                        onClick={() => {
                            setOrderDetailOpen(false);
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                </p>
                <ModalArea>
                    <h1>주문 상세</h1>
                    <InfoArea>
                        <Info>
                            <strong>주문번호</strong>{' '}
                            <span>{ordersData.orderId}</span>
                        </Info>
                        {data?.data.ResultData.status === 'RETURN' ? (
                            ''
                        ) : (
                            <Retrun onClick={ReturnComplete}>
                                해당 주문 반품완료 처리하기
                            </Retrun>
                        )}
                    </InfoArea>
                    <TableArea>
                        <GiftBox>
                            <Table>
                                <h2>주문 정보</h2>
                                <table cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <Thead>주문일시</Thead>
                                            <td>
                                                {data?.data.ResultData.regDt}
                                            </td>
                                        </tr>
                                        <tr>
                                            <Thead>주문인</Thead>
                                            <td>
                                                {
                                                    data?.data.ResultData
                                                        .memberName
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <Thead>연락처</Thead>
                                            <td>
                                                {data?.data.ResultData
                                                    .memberMobileNo === null
                                                    ? '-'
                                                    : data?.data.ResultData.memberMobileNo.replace(
                                                          /(\d{3})(\d{4})(\d{4})/,
                                                          '$1-$2-$3',
                                                      )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <Thead>상품명</Thead>
                                            <td>
                                                <ol type="1">
                                                    {data?.data.ResultData.items.map(
                                                        (i, idx) => (
                                                            <li
                                                                key={idx}
                                                                style={{
                                                                    marginLeft:
                                                                        '15px',
                                                                }}
                                                            >
                                                                {i.itemName}{' '}
                                                                <span
                                                                    style={{
                                                                        color: 'rgba(89, 166, 245, 1)',
                                                                    }}
                                                                >
                                                                    {i.cnt ===
                                                                    null
                                                                        ? ''
                                                                        : `${i.cnt}개`}
                                                                </span>
                                                            </li>
                                                        ),
                                                    )}
                                                </ol>
                                            </td>
                                        </tr>
                                        <tr>
                                            <Thead>총 결제금액</Thead>
                                            <td>
                                                {data?.data.ResultData.totalPrice.toLocaleString()}
                                                원 (배송비{' '}
                                                {data?.data.ResultData.deliveryPrice.toLocaleString()}
                                                원 포함)
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Table>
                            {data?.data.ResultData.giftYn === 'Y' && (
                                <Table>
                                    <h2>
                                        <img src={Gift} alt={'선물'} />
                                        선물 수령 정보
                                    </h2>
                                    <table cellPadding={0} cellSpacing={0}>
                                        <tbody>
                                            <tr>
                                                <Thead>선물 수령인</Thead>
                                                <td>
                                                    {
                                                        data?.data.ResultData
                                                            .receiverName
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <Thead>
                                                    선물 수령인 연락처
                                                </Thead>
                                                <td>
                                                    {data?.data.ResultData
                                                        .receiverMobileNo ===
                                                    null
                                                        ? ''
                                                        : data?.data.ResultData.receiverMobileNo.replace(
                                                              /(\d{3})(\d{4})(\d{4})/,
                                                              '$1-$2-$3',
                                                          )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Table>
                            )}
                        </GiftBox>
                        <Table>
                            <h2>배송 정보</h2>
                            <table cellPadding={0} cellSpacing={0}>
                                <tbody>
                                    <tr>
                                        <Thead>선물 등록일시</Thead>
                                        <td>
                                            {data?.data.ResultData
                                                .acceptDate === ''
                                                ? '-'
                                                : data?.data.ResultData
                                                      .acceptDate}
                                        </td>
                                    </tr>
                                    <tr>
                                        <Thead>수령인</Thead>
                                        <td>
                                            {data?.data.ResultData.name === ''
                                                ? '-'
                                                : data?.data.ResultData.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <Thead>수령인 연락처</Thead>
                                        <td>
                                            {data?.data.ResultData.mobileNo ===
                                            null
                                                ? '-'
                                                : data?.data.ResultData.mobileNo.replace(
                                                      /(\d{3})(\d{4})(\d{4})/,
                                                      '$1-$2-$3',
                                                  )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <Thead>배송지</Thead>
                                        <td>
                                            {data?.data.ResultData.address ===
                                            'null null'
                                                ? '-'
                                                : data?.data.ResultData.address}
                                        </td>
                                    </tr>
                                    <tr>
                                        <Thead>배송 요청사항</Thead>
                                        <td>
                                            {data?.data.ResultData
                                                .requestMessage === ''
                                                ? '-'
                                                : data?.data.ResultData
                                                      .requestMessage}
                                        </td>
                                    </tr>
                                    <tr>
                                        <Thead>우편번호</Thead>
                                        <td>
                                            {data?.data.ResultData.zip === null
                                                ? '-'
                                                : data?.data.ResultData.zip}
                                        </td>
                                    </tr>
                                    <tr>
                                        <Thead>배송 시작일</Thead>
                                        <td>
                                            {data?.data.ResultData
                                                .deliveryStartDate === ''
                                                ? '-'
                                                : data?.data.ResultData
                                                      .deliveryStartDate}
                                        </td>
                                    </tr>
                                    <tr>
                                        <Thead>배송사</Thead>
                                        <td>
                                            {data?.data.ResultData
                                                .courierName === ''
                                                ? '-'
                                                : data?.data.ResultData
                                                      .courierName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <Thead>송장번호</Thead>
                                        <td>
                                            {data?.data.ResultData
                                                .invoiceNumber === null
                                                ? '-'
                                                : data?.data.ResultData
                                                      .invoiceNumber}
                                        </td>
                                    </tr>
                                    <tr>
                                        <Thead>배송 상태</Thead>
                                        <td>
                                            {
                                                {
                                                    DELIVERY_INPUT_READY:
                                                        '미등록',
                                                    REFUSAL: '선물 거절',
                                                    DELIVERY_READY:
                                                        '배송 준비 중',
                                                    DELIVERY_IN_PROGRESS:
                                                        '배송 중',
                                                    DELIVERY_COMPLETE:
                                                        '배송완료',
                                                    RETURN_APPLY: '반품 신청',
                                                    RETURN: '반품 완료',
                                                    CANCEL: '선물 취소',
                                                    EXPIRATION: '선물수락만료',
                                                    PAYMENT_ERROR: '결제오류',
                                                }[data?.data.ResultData.status!]
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Table>
                    </TableArea>
                    <BtnArea>
                        <CloseBtn
                            onClick={() => {
                                setOrderDetailOpen(false);
                            }}
                        >
                            확인
                        </CloseBtn>
                    </BtnArea>
                </ModalArea>
            </Modal>
        </>
    );
};

export default LeppaMarketOrderDetail;
