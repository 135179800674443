import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    DeleteRounded,
    EditRounded,
} from '@mui/icons-material';
import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteReservationCompanyTagTop,
    insertReservationCompanyTag,
    modifyReservationCompanyTagTop,
    setReservationCompanyTagTopOrderChange,
    useReservationCompanyTagTopQuery,
    useReservationKeywordQuery,
} from '../../hooks/keyword/useKeyword';
import {
    ReservationCompanyTagTopData,
    ReservationKeywordData,
} from '../typings/keyword/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const RightDiv = styled.div`
    float: right;
    display: flex;
    align-items: center;
`;

const ReservationCompanyTagTop = () => {
    const [
        reservationKeywordTopDialogOpen,
        setReservationKeywordTopDialogOpen,
    ] = useState(false);
    const [tagId, setTagId] = useState(0);
    const [editSn, setEditSn] = useState(0);
    const [fileName, setFileName] = useState('');
    const [typeId, setTypeId] = useState(5);
    const [popupTypeId, setPopupTypeId] = useState(1);

    const queryClient = useQueryClient();
    const { isLoading, isError, data, refetch } =
        useReservationCompanyTagTopQuery(typeId);
    const {
        isLoading: isTagLoading,
        isError: isTagError,
        data: tagData,
        refetch: tagRefetch,
    } = useReservationKeywordQuery(typeId, popupTypeId);

    const insertReservationCompanyTagMutation = useMutation(
        insertReservationCompanyTag,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    'useReservationCompanyTagTopQuery',
                );
            },
        },
    );

    const modifyReservationCompanyTagTopMutation = useMutation(
        modifyReservationCompanyTagTop,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    'useReservationCompanyTagTopQuery',
                );
            },
        },
    );

    const deleteReservationCompanyTagTopMutation = useMutation(
        deleteReservationCompanyTagTop,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    'useReservationCompanyTagTopQuery',
                );
            },
        },
    );

    const setReservationCompanyTagTopOrderChangeMutation = useMutation(
        setReservationCompanyTagTopOrderChange,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    'useReservationCompanyTagTopQuery',
                );
            },
        },
    );

    const handleClose = () => {
        setReservationKeywordTopDialogOpen(false);
        setFileName('');
        setTagId(0);
        setEditSn(0);
    };

    const editClick = (v: ReservationCompanyTagTopData) => {
        setReservationKeywordTopDialogOpen(true);
        setEditSn(v.tagTopId);
        setTagId(v.tagId);
    };

    const handleClickOpen = () => {
        setReservationKeywordTopDialogOpen(true);
    };
    const deleteClick = (v: ReservationCompanyTagTopData) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteReservationCompanyTagTopMutation.mutate(v.tagTopId);
        }
    };
    const onSubmitFreeMarketTopCategory = () => {
        if (tagId === 0) {
            alert('키워드를 선택해 주세요.');
            return false;
        }
        let params = {
            tagId,
            fileName,
            typeId,
        };
        insertReservationCompanyTagMutation.mutate(params);
        handleClose();
    };
    // const handleChangeImage = (v) => {
    //     let fileName = v.target.value;
    //     if (!checkImageExt(fileName.substring(fileName.lastIndexOf('.') + 1))) {
    //         alert('이미지 파일만 첨부 가능합니다.');
    //         return false;
    //     }
    //     setFileName(fileName.substring(fileName.lastIndexOf('\\') + 1));
    // };

    const orderChange = (v: ReservationCompanyTagTopData, arrow: string) => {
        let orgIndex = data?.data.ResultData.indexOf(v);
        let targetTagTopId;
        if (arrow === 'U') {
            if (orgIndex === 0) {
                return false;
            }
            targetTagTopId = data?.data.ResultData[orgIndex - 1].tagTopId;
        } else {
            if (orgIndex === data?.data.ResultData.length - 1) {
                return false;
            }
            targetTagTopId = data?.data.ResultData[orgIndex + 1].tagTopId;
        }
        if (window.confirm('연관검색어 노출 순서를 변경하시겠습니까?')) {
            let params = {
                orgTagTopId: v.tagTopId,
                targetTagTopId,
            };
            setReservationCompanyTagTopOrderChangeMutation.mutate(params);
        }
    };

    const onModifyFreeMarketTopCategory = () => {
        let params = {
            tagTopId: editSn,
            tagId,
            typeId,
            fileName,
        };
        modifyReservationCompanyTagTopMutation.mutate(params);
        handleClose();
    };

    const handleTypeChange = (e: SelectChangeEvent) => {
        setTypeId(Number(e.target.value));
    };

    const handlePopupTypeChange = (e: SelectChangeEvent) => {
        setPopupTypeId(Number(e.target.value));
    };

    useEffect(() => {
        refetch();
        tagRefetch();
    }, [typeId, refetch, tagRefetch]);

    useEffect(() => {
        tagRefetch();
    }, [popupTypeId, tagRefetch]);

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        연관검색어 노출 관리
                    </Title>
                    <RightDiv>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={typeId.toString()}
                            label="Age"
                            onChange={handleTypeChange}
                            size="small"
                        >
                            <MenuItem value={5}>전체</MenuItem>
                            <MenuItem value={1}>강으로</MenuItem>
                            <MenuItem value={2}>바다로</MenuItem>
                            <MenuItem value={3}>숲으로</MenuItem>
                            <MenuItem value={4}>도시로</MenuItem>
                        </Select>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </RightDiv>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '400px' }} />
                                <col style={{ width: '250px' }} />
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '150px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>키워드 명</Th>
                                    <Th>키워드이미지</Th>
                                    <Th>순서변경</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.map(
                                    (v: ReservationCompanyTagTopData) => {
                                        return (
                                            <tr key={v.tagTopId}>
                                                <Td>{v.name}</Td>
                                                <Td>
                                                    {v.imgPath !== '' && (
                                                        <img
                                                            src={v.imgPath}
                                                            alt="배너 이미지"
                                                        />
                                                    )}
                                                    {v.imgPath === '' &&
                                                        '이미지없음'}
                                                </Td>
                                                <Td>
                                                    <ArrowUpwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'U');
                                                        }}
                                                    />
                                                    <ArrowDownwardRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            orderChange(v, 'D');
                                                        }}
                                                    />
                                                </Td>
                                                <Td>
                                                    <EditRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            editClick(v);
                                                        }}
                                                    />
                                                    <DeleteRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            deleteClick(v);
                                                        }}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                    </TableArea>
                    <Dialog
                        open={reservationKeywordTopDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            연관검색어 노출 {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                {typeId === 5 && (
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="popup-type-select"
                                        value={popupTypeId.toString()}
                                        label="Age"
                                        onChange={handlePopupTypeChange}
                                        size="small"
                                    >
                                        <MenuItem value={1}>강으로</MenuItem>
                                        <MenuItem value={2}>바다로</MenuItem>
                                        <MenuItem value={3}>숲으로</MenuItem>
                                        <MenuItem value={4}>도시로</MenuItem>
                                    </Select>
                                )}
                                <Select
                                    labelId="tags"
                                    id="tags"
                                    value={tagId}
                                    label="키워드"
                                    onChange={(e) => {
                                        setTagId(Number(e.target.value));
                                    }}
                                >
                                    {!isTagLoading &&
                                        !isTagError &&
                                        tagData?.data.ResultData.map(
                                            (v: ReservationKeywordData) => {
                                                return (
                                                    <MenuItem
                                                        key={v.keywordId}
                                                        value={v.keywordId}
                                                    >
                                                        {v.name}
                                                    </MenuItem>
                                                );
                                            },
                                        )}
                                </Select>
                                {/*<TextField*/}
                                {/*    id="fileName"*/}
                                {/*    label="이미지"*/}
                                {/*    style={{ margin: '8px 0px 8px 0px' }}*/}
                                {/*    InputLabelProps={{ shrink: true }}*/}
                                {/*    disabled*/}
                                {/*    fullWidth*/}
                                {/*    value={fileName}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        setFileName(e.target.value);*/}
                                {/*    }}*/}
                                {/*/>*/}
                                {/*<Button*/}
                                {/*    variant="contained"*/}
                                {/*    component="label"*/}
                                {/*    startIcon={<CloudUploadRounded />}*/}
                                {/*>*/}
                                {/*    Upload*/}
                                {/*    <input*/}
                                {/*        id={'file-input'}*/}
                                {/*        style={{ display: 'none' }}*/}
                                {/*        type="file"*/}
                                {/*        name="file"*/}
                                {/*        accept="image/gif,image/jpeg,image/png,image/jpg"*/}
                                {/*        onChange={handleChangeImage}*/}
                                {/*    />*/}
                                {/*</Button>*/}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button
                                    onClick={onModifyFreeMarketTopCategory}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button
                                    onClick={onSubmitFreeMarketTopCategory}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
};

export default React.memo(ReservationCompanyTagTop);
