import React, { useCallback, useState } from 'react';
import { DeleteRounded, EditRounded } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextareaAutosize,
    TextField,
} from '@mui/material';
import Pagination from '../common/Pagination';
import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteAdMarketingPush,
    insertAdMarketingPush,
    modifyAdMarketingPush,
    useAdMarketingPush,
} from '../../hooks/ad/useAd';
import { parseStringDateTime } from '../../utils/util';
import { MarketingPush } from '../typings/ad/response';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { format, parse } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

const MessageTd = styled(Td)`
    padding: 15px 10px;
    white-space: pre;
`;

function AdMarketingPush() {
    const pageSize = 10;

    const [alarmDialogOpen, setAlarmDialogOpen] = useState(false);
    const [editSn, setEditSn] = useState(0);
    const [message, setMessage] = useState('');
    // const [sendDate, setSendDate] = useState('');
    const [url, setUrl] = useState('');
    const [currListPage, setCurrListPage] = useState(1);
    const [pushType, setPushType] = useState('3019');
    const [dataKey, setDataKey] = useState('eventUrl');
    const [clickAction, setClickAction] = useState('FCM_COUPON_ISSUANCE_PUSH');
    const [sendDate, setSendDate] = useState<Date | null>(null);

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useAdMarketingPush(
        currListPage,
        pageSize,
    );

    const insertAdMarketingPushMutation = useMutation(insertAdMarketingPush, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdMarketingPush');
        },
    });

    const modifyAdMarketingPushMutation = useMutation(modifyAdMarketingPush, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdMarketingPush');
        },
    });
    const deleteAdMarketingPushMutation = useMutation(deleteAdMarketingPush, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdMarketingPush');
        },
    });

    const setList = (page: number) => {
        setCurrListPage(page);
    };

    const deleteClick = (v: MarketingPush) => {
        let confirmMessage = '삭제하시겠습니까?';
        if (v.sendYn === 'Y') {
            confirmMessage = '이미 발송된 광고 알림입니다.\n' + confirmMessage;
        }
        if (window.confirm(confirmMessage)) {
            deleteAdMarketingPushMutation.mutate(v.sn);
        }
    };

    const editClick = (v: MarketingPush) => {
        setEditSn(v.sn);
        setMessage(v.message);
        setSendDate(
            v.sendDate !== ''
                ? parse(v.sendDate, 'yyyyMMddHH', new Date())
                : null,
        );
        setUrl(v.dataValue);
        setPushType(v.pushType);
        setClickAction(v.clickAction);
        setDataKey(v.dataKey);
        setAlarmDialogOpen(true);
    };

    const handleClickOpen = () => {
        setAlarmDialogOpen(true);
    };

    const handleClose = () => {
        setAlarmDialogOpen(false);
        setEditSn(0);
        setSendDate(null);
        setMessage('');
        setUrl('');
        setPushType('3019');
        setClickAction('FCM_COUPON_ISSUANCE_PUSH');
        setDataKey('eventUrl');
    };

    const onModifyAlarm = () => {
        if (message === '' || sendDate === null || url === '') {
            alert('정보를 입력하세요.');
            return false;
        }
        let params = {
            id: editSn,
            message,
            sendDate: format(sendDate, 'yyyyMMddHH'),
            dataValue: url,
            dataKey,
            pushType,
            clickAction,
        };
        modifyAdMarketingPushMutation.mutate(params);
        handleClose();
    };
    const onSubmitAlarm = () => {
        if (message === '' || sendDate === null || url === '') {
            alert('정보를 입력하세요.');
            return false;
        }
        let params = {
            message,
            sendDate: format(sendDate, 'yyyyMMddHH'),
            dataValue: url,
            dataKey,
            pushType,
            clickAction,
        };
        insertAdMarketingPushMutation.mutate(params);
        handleClose();
    };

    const parseSendDateTIme = (v: string) => {
        let ret = [
            v.substring(0, 4),
            '.',
            v.substring(4, 6),
            '.',
            v.substring(6, 8),
        ];
        if (v.length > 8) {
            ret.push(' ', v.substring(8, 10), '시');
        }
        return ret.join('');
    };

    const handleAlarmTypeChange = useCallback(
        (e: SelectChangeEvent) => {
            if (e.target.value === '3019') {
                setDataKey('eventUrl');
                setClickAction('FCM_COUPON_ISSUANCE_PUSH');
            } else {
                setDataKey('itemId');
                setClickAction('FCM_ITEM_VIEW_PUSH');
            }
            setPushType(e.target.value);
        },
        [setDataKey, setPushType],
    );

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        마케팅 PUSH알림 리스트(
                        <span className="list">
                            {data?.data.ResultData.totalElements}
                        </span>
                        )
                    </Title>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '400px' }} />
                                <col style={{ width: '125px' }} />
                                <col style={{ width: '125px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '95px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>발송일시</Th>
                                    <Th>문구</Th>
                                    <Th>발송여부</Th>
                                    <Th>등록일</Th>
                                    <Th>등록자</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.content.map(
                                    (v: MarketingPush) => {
                                        return (
                                            <tr key={v.sn}>
                                                <Td>
                                                    {parseSendDateTIme(
                                                        v.sendDate,
                                                    )}
                                                </Td>
                                                <MessageTd>
                                                    {v.message}
                                                </MessageTd>
                                                <Td>
                                                    {v.sendYn === 'Y'
                                                        ? '발송'
                                                        : '미발송'}
                                                </Td>
                                                <Td>
                                                    {parseStringDateTime(
                                                        v.regDt,
                                                    )}
                                                </Td>
                                                <Td>{v.createdId}</Td>
                                                <Td>
                                                    {v.sendYn === 'N' && (
                                                        <>
                                                            <EditRounded
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    editClick(
                                                                        v,
                                                                    );
                                                                }}
                                                            />
                                                            <DeleteRounded
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    deleteClick(
                                                                        v,
                                                                    );
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </Td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </Table>
                        {data?.data.ResultData.totalElements > 0 && (
                            <Pagination
                                itemsCount={data?.data.ResultData.totalElements}
                                pageSize={pageSize}
                                currPage={currListPage}
                                setList={setList}
                            />
                        )}
                    </TableArea>
                    <Dialog
                        open={alarmDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            마케팅 알림
                            {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <Select
                                    labelId="alarm-type"
                                    id="alarm-type"
                                    value={pushType}
                                    onChange={handleAlarmTypeChange}
                                    fullWidth
                                    style={{
                                        marginBottom: '7px',
                                    }}
                                >
                                    <MenuItem value="3019">
                                        이벤트페이지
                                    </MenuItem>
                                    <MenuItem value="3104">상품</MenuItem>
                                </Select>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={koLocale}
                                >
                                    <DateTimePicker
                                        renderInput={(props) => (
                                            <TextField {...props} />
                                        )}
                                        label="발송일"
                                        value={sendDate}
                                        inputFormat={'yyyy-MM-dd HH:00'}
                                        onChange={(newValue) => {
                                            setSendDate(newValue);
                                        }}
                                        views={['year', 'day', 'hours']}
                                        minDate={new Date()}
                                    />
                                </LocalizationProvider>
                                <TextareaAutosize
                                    id="message"
                                    placeholder="문구"
                                    minRows={6}
                                    style={{
                                        margin: '8px 0px 8px 0px',
                                        width: '100%',
                                    }}
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="url"
                                    label={
                                        pushType === '3104'
                                            ? '상품아이디'
                                            : 'URL'
                                    }
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={url}
                                    onChange={(e) => {
                                        setUrl(e.target.value);
                                    }}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button onClick={onModifyAlarm} color="primary">
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button onClick={onSubmitAlarm} color="primary">
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
}

export default React.memo(AdMarketingPush);
