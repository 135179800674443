import * as React from 'react';
import { ChangeEvent, useCallback, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextareaAutosize,
    TextField,
} from '@mui/material';
import styled from '@emotion/styled';
import {
    deleteToothJamContent,
    insertToothJamContent,
    modifyToothJamContent,
    setToothJamContentDragOrderChange,
    setToothJamContentOrderChange,
    setToothJamContentVisibility,
    useToothJamContentQuery,
} from '../../hooks/toothJamContent/useToothJamContent';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd';
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    CloudUploadRounded,
    DeleteRounded,
    EditRounded,
    VisibilityOffRounded,
    VisibilityRounded,
} from '@mui/icons-material';
import { checkImageExt, clickImg } from '../../utils/util';
import { useMutation, useQueryClient } from 'react-query';
import { ToothJamContentData } from '../typings/toothJamContent/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
    float: left;
`;

const TableArea = styled.div`
    clear: both;
`;

const Table = styled.div`
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.div`
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
    width: 10%;
`;

const Td = styled.div`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
    width: 10%;
`;

const ToothJamContent = () => {
    const [toothJamContentDialogOpen, setToothJamContentDialogOpen] =
        useState(false);
    const [editId, setEditId] = useState('');
    const [fileName, setFileName] = useState('');
    const [mobileFileName, setMobileFileName] = useState('');
    const [message, setMessage] = useState('');
    const { isLoading, isError, data } = useToothJamContentQuery();
    const queryClient = useQueryClient();

    const insertToothJamContentMutation = useMutation(insertToothJamContent, {
        onSuccess: (data) => {
            if (data.data.ResultCode === 1) {
                handleClose();
                queryClient.invalidateQueries('useToothJamContentQuery');
            }
        },
    });

    const modifyToothJamContentMutation = useMutation(modifyToothJamContent, {
        onSuccess: (data) => {
            if (data.data.ResultCode === 1) {
                handleClose();
                queryClient.invalidateQueries('useToothJamContentQuery');
            }
        },
    });

    const deleteToothJamContentMutation = useMutation(deleteToothJamContent, {
        onSuccess: (data) => {
            if (data.data.ResultCode === 1) {
                handleClose();
                queryClient.invalidateQueries('useToothJamContentQuery');
            }
        },
    });

    const setToothJamContentVisibilityMutation = useMutation(
        setToothJamContentVisibility,
        {
            onSuccess: (data) => {
                if (data.data.ResultCode === 1) {
                    handleClose();
                    queryClient.invalidateQueries('useToothJamContentQuery');
                }
            },
        },
    );

    const setToothJamContentOrderChangeMutation = useMutation(
        setToothJamContentOrderChange,
        {
            onSuccess: (data) => {
                if (data.data.ResultCode === 1) {
                    handleClose();
                    queryClient.invalidateQueries('useToothJamContentQuery');
                }
            },
        },
    );

    const setToothJamContentDragOrderChangeMutation = useMutation(
        setToothJamContentDragOrderChange,
        {
            onSuccess: (data) => {
                if (data.data.ResultCode === 1) {
                    handleClose();
                    queryClient.invalidateQueries('useToothJamContentQuery');
                }
            },
        },
    );

    const handleClose = useCallback(() => {
        setToothJamContentDialogOpen(false);
        setEditId('');
        setFileName('');
        setMobileFileName('');
        setMessage('');
    }, []);

    const handleClickOpen = useCallback(() => {
        setToothJamContentDialogOpen(true);
    }, []);

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            const { source, destination, draggableId } = result;
            if (!destination || source.index === destination.index) {
                return;
            }
            let params = {
                id: draggableId.replace('tooth-jam-', ''),
                targetOrdering: destination.index,
            };
            setToothJamContentDragOrderChangeMutation.mutate(params);
        },
        [setToothJamContentDragOrderChangeMutation],
    );

    const setVisibility = useCallback(
        (v: ToothJamContentData, isVisibility: string) => {
            let msg = '콘텐츠를 노출하시겠습니까?';
            if (isVisibility === 'N') {
                msg = '콘텐츠를 비노출하시겠습니까?';
            }
            if (window.confirm(msg)) {
                let params = {
                    id: v.id,
                    isVisibility,
                };
                setToothJamContentVisibilityMutation.mutate(params);
            }
        },
        [setToothJamContentVisibilityMutation],
    );

    const orderChange = useCallback(
        (v: ToothJamContentData, arrow: string) => {
            let orgIndex = data?.data.ResultData.content.indexOf(v);
            let targetId;
            if (arrow === 'U') {
                if (orgIndex === 0) {
                    return false;
                }
                targetId = data?.data.ResultData.content[orgIndex - 1].id;
            } else {
                if (orgIndex === data?.data.ResultData.content.length - 1) {
                    return false;
                }
                targetId = data?.data.ResultData.content[orgIndex + 1].id;
            }
            if (window.confirm('콘텐츠 순서를 변경하시겠습니까?')) {
                let params = {
                    id: v.id,
                    targetId,
                };
                setToothJamContentOrderChangeMutation.mutate(params);
            }
        },
        [data?.data.ResultData.content, setToothJamContentOrderChangeMutation],
    );

    const editClick = useCallback((v: ToothJamContentData) => {
        setToothJamContentDialogOpen(true);
        setEditId(v.id);
        setMessage(v.message);
    }, []);

    const deleteClick = useCallback(
        (v: ToothJamContentData) => {
            if (window.confirm('삭제하시겠습니까?')) {
                let params = { id: v.id };
                deleteToothJamContentMutation.mutate(params);
            }
        },
        [deleteToothJamContentMutation],
    );

    const onModifyToothJamContent = useCallback(() => {
        let params = {
            id: editId,
            message,
            fileName,
            mobileFileName,
        };
        modifyToothJamContentMutation.mutate(params);
    }, [
        editId,
        fileName,
        message,
        mobileFileName,
        modifyToothJamContentMutation,
    ]);

    const onSubmitToothJamContent = useCallback(() => {
        if (fileName === '' || mobileFileName === '') {
            alert('이미지를 선택해 주세요.');
            return false;
        }
        let params = {
            message,
        };
        insertToothJamContentMutation.mutate(params);
    }, [fileName, insertToothJamContentMutation, message, mobileFileName]);

    const handleChangeImage = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            let fileName = e.target.value;
            if (
                !checkImageExt(
                    fileName.substring(fileName.lastIndexOf('.') + 1),
                )
            ) {
                alert('이미지 파일만 첨부 가능합니다.');
                return false;
            }
            setFileName(fileName.substring(fileName.lastIndexOf('\\') + 1));
        },
        [setFileName],
    );

    const handleChangeMobileImage = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            let fileName = e.target.value;
            if (
                !checkImageExt(
                    fileName.substring(fileName.lastIndexOf('.') + 1),
                )
            ) {
                alert('이미지 파일만 첨부 가능합니다.');
                return false;
            }
            setMobileFileName(
                fileName.substring(fileName.lastIndexOf('\\') + 1),
            );
        },
        [setMobileFileName],
    );

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title>투스잼 콘텐츠 관리</Title>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="toothJamContent">
                            {(provided) => (
                                <TableArea
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <Table>
                                        <div style={{ display: 'flex' }}>
                                            <Th style={{ width: '20%' }}>
                                                <div style={{ width: '200px' }}>
                                                    데스크탑
                                                </div>
                                            </Th>
                                            <Th style={{ width: '10%' }}>
                                                <div style={{ width: '100px' }}>
                                                    모바일
                                                </div>
                                            </Th>
                                            <Th>문구</Th>
                                            <Th>등록자</Th>
                                            <Th>등록일</Th>
                                            <Th>노출여부</Th>
                                            <Th>순서변경</Th>
                                            <Th>&nbsp;</Th>
                                        </div>
                                        <div>
                                            {provided.placeholder}
                                            {data?.data.ResultData.content.map(
                                                (v: ToothJamContentData) => (
                                                    <Draggable
                                                        draggableId={`tooth-jam-${v.id}`}
                                                        index={v.ordering}
                                                        key={`tooth-jam-${v.id}`}
                                                    >
                                                        {(
                                                            provided,
                                                            snapshot,
                                                        ) => {
                                                            return (
                                                                <div
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                >
                                                                    <div
                                                                        key={
                                                                            v.id
                                                                        }
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Td
                                                                            style={{
                                                                                textAlign:
                                                                                    'left',
                                                                                width: '20%',
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    v.imageUrl
                                                                                }
                                                                                width="200px"
                                                                                height="100px"
                                                                                alt="콘텐츠 이미지"
                                                                                onClick={() =>
                                                                                    clickImg(
                                                                                        v.imageUrl,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Td>
                                                                        <Td
                                                                            style={{
                                                                                textAlign:
                                                                                    'left',
                                                                                width: '10%',
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    v.mobileImageUrl
                                                                                }
                                                                                width="100px"
                                                                                height="200px"
                                                                                alt="콘텐츠 이미지"
                                                                                onClick={() =>
                                                                                    clickImg(
                                                                                        v.mobileImageUrl,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Td>
                                                                        <Td>
                                                                            {
                                                                                v.message
                                                                            }
                                                                        </Td>
                                                                        <Td>
                                                                            {
                                                                                v.modifiedId
                                                                            }
                                                                        </Td>
                                                                        <Td>
                                                                            {
                                                                                v.modifiedDate
                                                                            }
                                                                        </Td>
                                                                        <Td>
                                                                            {v.showYn ===
                                                                                'Y' && (
                                                                                <VisibilityRounded
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setVisibility(
                                                                                            v,
                                                                                            'N',
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {v.showYn ===
                                                                                'N' && (
                                                                                <VisibilityOffRounded
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setVisibility(
                                                                                            v,
                                                                                            'Y',
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Td>
                                                                        <Td>
                                                                            <ArrowUpwardRounded
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => {
                                                                                    orderChange(
                                                                                        v,
                                                                                        'U',
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <ArrowDownwardRounded
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => {
                                                                                    orderChange(
                                                                                        v,
                                                                                        'D',
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Td>
                                                                        <Td>
                                                                            <EditRounded
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => {
                                                                                    editClick(
                                                                                        v,
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <DeleteRounded
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => {
                                                                                    deleteClick(
                                                                                        v,
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Td>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }}
                                                    </Draggable>
                                                ),
                                            )}
                                        </div>
                                    </Table>
                                </TableArea>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Dialog
                        open={toothJamContentDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            투스잼 콘텐츠
                            {editId === '' && ' 등록'}
                            {editId !== '' && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextareaAutosize
                                    id="message"
                                    placeholder="문구"
                                    minRows={6}
                                    style={{
                                        margin: '8px 0px 8px 0px',
                                        width: '100%',
                                    }}
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                    }}
                                />
                                <div
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        paddingBottom: '8px',
                                    }}
                                >
                                    - 데스크탑 이미지 권장사이즈 1920px * 960px
                                    (2:1)
                                </div>
                                <TextField
                                    id="fileName"
                                    label="데스크탑 이미지"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={fileName}
                                    onChange={(e) => {
                                        setFileName(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadRounded />}
                                >
                                    Upload
                                    <input
                                        id={'file-input'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="file"
                                        accept="image/gif,image/jpeg,image/png,image/jpg"
                                        onChange={handleChangeImage}
                                    />
                                </Button>
                                <div
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        padding: '8px 0',
                                    }}
                                >
                                    - 모바일 이미지 권장사이즈 840px * 1680px
                                    (1:2)
                                </div>
                                <TextField
                                    id="fileName"
                                    label="모바일 이미지"
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    fullWidth
                                    value={mobileFileName}
                                    onChange={(e) => {
                                        setMobileFileName(e.target.value);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadRounded />}
                                >
                                    Upload
                                    <input
                                        id={'mobile-file-input'}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="mobileFile"
                                        accept="image/gif,image/jpeg,image/png,image/jpg"
                                        onChange={handleChangeMobileImage}
                                    />
                                </Button>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {editId !== '' && (
                                <Button
                                    onClick={onModifyToothJamContent}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {editId === '' && (
                                <Button
                                    onClick={onSubmitToothJamContent}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
};
export default React.memo(ToothJamContent);
