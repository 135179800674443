import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import { useQuery } from 'react-query';
import {
    InsertLeppaPlayDetailAdRequest,
    InsertLeppaPlayRequest,
    InsertLeppaPlayTypeRequest,
    LeppaPlayDetailAdVisibilityRequest,
    LeppaPlayOrderChangeDragRequest,
    LeppaPlayTypeOrderChangeDragRequest,
    LeppaPlayTypeOrderChangeRequest,
    ModifyLeppaPlayDetailAdRequest,
    ModifyLeppaPlayTypeRequest,
    ModifyLeppaPlayTypeVisibilityRequest,
} from '../../components/typings/leppa-play/types';

const getLeppaPlayTypeApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/type',
        method: 'GET',
    });
};

export const useLeppaPlayTypeQuery = () =>
    useQuery(['useLeppaPlayTypeQuery'], () => getLeppaPlayTypeApi(), {
        retry: 3,
    });

export const setLeppaPlayTypeOrderChange = (
    params: LeppaPlayTypeOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/type/order-change',
        method: 'PUT',
        data: params,
    });

export const insertLeppaPlayType = (params: InsertLeppaPlayTypeRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/type',
        method: 'POST',
        data: params,
    });

export const modifyLeppaPlayType = (params: ModifyLeppaPlayTypeRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/type',
        method: 'PUT',
        data: params,
    });

export const modifyLeppaPlayTypeVisibility = (
    params: ModifyLeppaPlayTypeVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/type/visibility',
        method: 'PUT',
        data: params,
    });

const getLeppaPlayApi = (
    typeCd: string,
    currListPage: number,
    pageSize: number,
) => {
    return axiosApiInstance({
        url:
            apiUrl +
            `/api/v1/join/leppa-play?type=${typeCd}&page=${
                currListPage - 1
            }&size=${pageSize}`,
        method: 'GET',
    });
};

export const useLeppaPlayQuery = (
    typeCd: string,
    currListPage: number,
    pageSize: number,
) =>
    useQuery(
        ['useLeppaPlayQuery'],
        () => getLeppaPlayApi(typeCd, currListPage, pageSize),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

const getLeppaPlayTypeListApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/barg/common/cd/list?cd_grp_no=CD021',
        method: 'GET',
    });
};

export const useLeppaPlayTypeListQuery = () =>
    useQuery(['useLeppaPlayTypeListQuery'], () => getLeppaPlayTypeListApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const deleteLeppaPlay = (leppaPlayId: string) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play',
        method: 'DELETE',
        data: {
            leppaPlayId,
        },
    });

export const insertLeppaPlay = (params: InsertLeppaPlayRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play',
        method: 'POST',
        data: params,
    });

const getLeppaPlayDetailAdApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/ad/detail',
        method: 'GET',
    });
};

export const useLeppaPlayDetailAdQuery = () =>
    useQuery(['useLeppaPlayDetailAdQuery'], () => getLeppaPlayDetailAdApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const deleteLeppaPlayDetailAd = (leppaPlayDetailAdSn: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/ad/detail',
        method: 'DELETE',
        data: {
            leppaPlayDetailAdSn,
        },
    });

export const insertLeppaPlayDetailAd = (
    params: InsertLeppaPlayDetailAdRequest,
) => {
    let formData = new FormData();
    const file = document.getElementById('file-input') as HTMLInputElement;
    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('description', params.description);

    return axiosApiInstance.post(
        apiUrl + '/api/v1/join/leppa-play/ad/detail',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const modifyLeppaPlayDetailAd = (
    params: ModifyLeppaPlayDetailAdRequest,
) => {
    let formData = new FormData();
    if (params.fileName !== '') {
        const file = document.getElementById('file-input') as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append(
        'leppaPlayDetailAdSn',
        params.leppaPlayDetailAdSn.toString(),
    );
    formData.append('company', params.company);
    formData.append('url', params.url);
    formData.append('description', params.description);

    return axiosApiInstance.put(
        apiUrl + '/api/v1/join/leppa-play/ad/detail',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const setLeppaPlayDetailAdVisibility = (
    params: LeppaPlayDetailAdVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/ad/detail/visibility',
        method: 'PUT',
        data: params,
    });

const getVideoListApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/recommend/video/name-list',
        method: 'GET',
    });
};

export const useVideoListQuery = () =>
    useQuery(['useVideoListQuery'], () => getVideoListApi(), {
        retry: 3,
        keepPreviousData: true,
    });

const getMappingVideoApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/recommend/video',
        method: 'GET',
    });
};

export const useMappingVideoQuery = () =>
    useQuery(['useMappingVideoQuery'], () => getMappingVideoApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const deleteMappingVideoList = (mappingId: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/recommend/video',
        method: 'DELETE',
        data: {
            mappingId,
        },
    });

export const insertMappingVideoList = (videoId: string) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/recommend/video',
        method: 'POST',
        data: {
            videoId,
        },
    });

export const setLeppaPlayTypeDragOrderChange = (
    params: LeppaPlayTypeOrderChangeDragRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/type/order-change/drag',
        method: 'PUT',
        data: params,
    });

export const setLeppaPlayDragOrderChange = (
    params: LeppaPlayOrderChangeDragRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/join/leppa-play/order-change/drag',
        method: 'PUT',
        data: params,
    });
