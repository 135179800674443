import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import {
    DeleteRounded,
    EditRounded,
    VisibilityOffRounded,
    VisibilityRounded,
} from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { loginState } from '../common/atom';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteAdChat,
    insertAdChat,
    modifyAdChat,
    setAdChatVisibility,
    useAdChatQuery,
} from '../../hooks/ad/useAd';
import styled from '@emotion/styled';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

function AdChat() {
    const userId = useRecoilValue(loginState);
    const [adChatDialogOpen, setAdChatDialogOpen] = useState(false);
    const [editSn, setEditSn] = useState(0);
    const [company, setCompany] = useState('');
    const [url, setUrl] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [bgColor, setBgColor] = useState('#FFFFFF');
    const [fontColor, setFontColor] = useState('#000000');
    const [description, setDescription] = useState('');

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useAdChatQuery();

    const deleteAdChatMutation = useMutation(deleteAdChat, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdChatQuery');
        },
    });

    const insertAdChatMutation = useMutation(insertAdChat, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdChatQuery');
        },
    });

    const modifyAdChatMutation = useMutation(modifyAdChat, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdChatQuery');
        },
    });

    const setAdChatVisibilityMutation = useMutation(setAdChatVisibility, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdChatQuery');
        },
    });

    const handleClose = () => {
        setAdChatDialogOpen(false);
        setEditSn(0);
        setCompany('');
        setUrl('');
        setTitle('');
        setMessage('');
        setBgColor('#FFFFFF');
        setFontColor('#000000');
        setDescription('');
    };

    const handleClickOpen = () => {
        setAdChatDialogOpen(true);
    };

    const editClick = (v) => {
        setAdChatDialogOpen(true);
        setEditSn(v.sn);
        setCompany(v.company);
        setUrl(v.url);
        setTitle(v.title);
        setMessage(v.message);
        setBgColor(v.bgColor);
        setFontColor(v.fontColor);
        setDescription(v.description);
    };

    const deleteClick = (v) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteAdChatMutation.mutate(v.sn);
        }
    };

    const onModifyAdChat = () => {
        if (company === '') {
            alert('회사명을 입력해 주세요.');
            return false;
        }
        if (description === '') {
            alert('구분명을 입력해 주세요.');
            return false;
        }
        if (message === '') {
            alert('광고 문구를 입력해 주세요.');
            return false;
        }
        let params = {
            sn: editSn,
            company,
            url,
            title,
            message,
            bgColor,
            fontColor,
            userId,
            description,
        };
        modifyAdChatMutation.mutate(params);
        handleClose();
    };

    const onSubmitAdChat = () => {
        if (company === '') {
            alert('회사명을 입력해 주세요.');
            return false;
        }
        if (description === '') {
            alert('구분명을 입력해 주세요.');
            return false;
        }
        if (message === '') {
            alert('광고 문구를 입력해 주세요.');
            return false;
        }
        let params = {
            company,
            url,
            title,
            message,
            bgColor,
            fontColor,
            userId,
            description,
        };
        insertAdChatMutation.mutate(params);
        handleClose();
    };

    const setAdVisibility = (v, isVisibility) => {
        let msg = '채팅 목록 문구를 노출하시겠습니까?';
        if (isVisibility === 'N') {
            msg = '채팅 목록 문구를 비노출하시겠습니까?';
        }
        if (window.confirm(msg)) {
            let params = {
                sn: v.sn,
                isVisibility,
            };
            setAdChatVisibilityMutation.mutate(params);
        }
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        채팅목록 광고 관리
                    </Title>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '150px' }} />
                                <col style={{ width: '100px' }} />
                                <col style={{ width: '325px' }} />
                                <col style={{ width: '125px' }} />
                                <col style={{ width: '125px' }} />
                                <col style={{ width: '130px' }} />
                                <col style={{ width: '110px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>회사명</Th>
                                    <Th>구분명</Th>
                                    <Th>광고 문구</Th>
                                    <Th>등록자</Th>
                                    <Th>등록일</Th>
                                    <Th>노출여부</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.data.ResultData.map((v, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <Td>{v.company}</Td>
                                            <Td>{v.description}</Td>
                                            <Td
                                                style={{
                                                    textAlign: 'left',
                                                }}
                                            >
                                                <a
                                                    rel="noreferrer noopener nofollow"
                                                    href={v.url}
                                                    target="_blank"
                                                >
                                                    <span
                                                        style={{
                                                            color: v.fontColor,
                                                            backgroundColor:
                                                                v.bgColor,
                                                        }}
                                                    >
                                                        ({v.title}) {v.message}
                                                    </span>
                                                </a>
                                            </Td>
                                            <Td>{v.createdId}</Td>
                                            <Td>{v.regDt}</Td>
                                            <Td>
                                                {v.showYn === 'Y' && (
                                                    <VisibilityRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            setAdVisibility(
                                                                v,
                                                                'N',
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {v.showYn === 'N' && (
                                                    <VisibilityOffRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            setAdVisibility(
                                                                v,
                                                                'Y',
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </Td>
                                            <Td>
                                                <EditRounded
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        editClick(v);
                                                    }}
                                                />
                                                <DeleteRounded
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        deleteClick(v);
                                                    }}
                                                />
                                            </Td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </TableArea>
                    <Dialog
                        open={adChatDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            채팅목록 광고
                            {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    id="standard-basic"
                                    label="회사명"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={company}
                                    onChange={(e) => {
                                        setCompany(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="description"
                                    label="구분명"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="url"
                                    label="URL"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={url}
                                    onChange={(e) => {
                                        setUrl(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="title"
                                    label="제목"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="message"
                                    label="문구"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="fontColor"
                                    label="폰트색상"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={fontColor}
                                    onChange={(e) => {
                                        setFontColor(e.target.value);
                                    }}
                                />
                                <TextField
                                    id="bgColor"
                                    label="배경색상"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={bgColor}
                                    onChange={(e) => {
                                        setBgColor(e.target.value);
                                    }}
                                />
                                <div style={{ clear: 'both' }}>
                                    <span>미리보기 : </span>
                                    <span
                                        style={{
                                            color: fontColor,
                                            backgroundColor: bgColor,
                                        }}
                                    >
                                        ({title}) {message}
                                    </span>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button
                                    onClick={onModifyAdChat}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button
                                    onClick={onSubmitAdChat}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
}

export default React.memo(AdChat);
