import React, { useCallback, useEffect, useState } from 'react';
import { DeleteRounded } from '@mui/icons-material';
import { useMutation, useQueryClient } from 'react-query';

import styled from '@emotion/styled';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import {
    deleteLeppaPlay,
    insertLeppaPlay,
    setLeppaPlayDragOrderChange,
    useLeppaPlayQuery,
    useLeppaPlayTypeListQuery,
} from '../../hooks/leppa-play/useLeppaPlay';
import Pagination from '../common/Pagination';
import {
    LeppaPlayData,
    CommonCodeLeppaPlayTypeData,
} from '../typings/leppa-play/response';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div`
    clear: both;
`;

const Table = styled.div`
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.div`
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
    width: 30%;
    &:nth-last-of-type(1) {
        width: 10%;
    }
`;

const Td = styled.div`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
    width: 30%;
    &:nth-last-of-type(1) {
        width: 10%;
    }
`;

const RightDiv = styled.div`
    float: right;
    display: flex;
    align-items: center;
`;

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`;

const LeppaPlay = () => {
    const pageSize = 20;
    const [currListPage, setCurrListPage] = useState(1);
    const [popupOpen, setPopupOpen] = useState(false);
    const [youtubeId, setYoutubeId] = useState('');
    const [typeCd, setTypeCd] = useState('01');
    const [addTypeCd, setAddTypeCd] = useState('01');

    const queryClient = useQueryClient();
    const { isLoading, isError, data, refetch } = useLeppaPlayQuery(
        typeCd,
        currListPage,
        pageSize,
    );
    const {
        isLoading: typeIsLoading,
        isError: typeIsError,
        data: typeData,
    } = useLeppaPlayTypeListQuery();

    const setList = (page: number) => {
        setCurrListPage(page);
    };

    const insertLeppaPlayMutation = useMutation(insertLeppaPlay, {
        onSuccess: () => {
            queryClient.invalidateQueries('useLeppaPlayQuery');
        },
    });

    const deleteLeppaPlayMutation = useMutation(deleteLeppaPlay, {
        onSuccess: () => {
            queryClient.invalidateQueries('useLeppaPlayQuery');
        },
    });

    const setLeppaPlayDragOrderChangeMutation = useMutation(
        setLeppaPlayDragOrderChange,
        {
            onSuccess: () => {
                setTimeout(() => {
                    queryClient.invalidateQueries('useLeppaPlayTypeQuery');
                }, 500);
            },
        },
    );

    const handleClickOpen = () => {
        setPopupOpen(true);
    };

    const handleClose = () => {
        setPopupOpen(false);
        setYoutubeId('');
        setAddTypeCd('01');
    };

    const onSubmitProductClassification = () => {
        if (youtubeId === '') {
            alert('유튜브아이디를 입력해주세요.');
            return false;
        }
        let params = {
            youtubeId: youtubeId,
            type: addTypeCd,
        };
        insertLeppaPlayMutation.mutate(params);
        handleClose();
    };

    const deleteClick = (v: LeppaPlayData) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteLeppaPlayMutation.mutate(v.leppaPlayId);
        }
    };

    const handleTypeChange = (e: SelectChangeEvent) => {
        setCurrListPage(1);
        setTypeCd(e.target.value);
    };

    const handleAddTypeChange = (e: SelectChangeEvent) => {
        setAddTypeCd(e.target.value);
    };

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            const { source, destination, draggableId } = result;
            if (!destination || source.index === destination.index) {
                return;
            }
            let params = {
                id: draggableId.replace('leppa-play-', ''),
                targetOrderSn: destination.index,
            };
            setLeppaPlayDragOrderChangeMutation.mutate(params);
        },
        [setLeppaPlayDragOrderChangeMutation],
    );

    useEffect(() => {
        refetch();
    }, [currListPage, typeCd, refetch]);

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        레빠플레이 관리
                    </Title>
                    <RightDiv>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={typeCd}
                            label="Age"
                            onChange={handleTypeChange}
                            size="small"
                        >
                            {!typeIsError &&
                                !typeIsLoading &&
                                typeData?.data.ResultData.map(
                                    (v: CommonCodeLeppaPlayTypeData) => {
                                        return (
                                            <MenuItem key={v.Cd} value={v.Cd}>
                                                {v.CdNm}
                                            </MenuItem>
                                        );
                                    },
                                )}
                        </Select>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </RightDiv>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="leppaplay">
                            {(provided) => (
                                <TableArea
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <Table>
                                        <div style={{ display: 'flex' }}>
                                            <Th>제목</Th>
                                            <Th>채널</Th>
                                            <Th>영상</Th>
                                            <Th></Th>
                                        </div>
                                        <tbody>
                                            {data?.data.ResultData.content.map(
                                                (v: LeppaPlayData) => {
                                                    return (
                                                        <Draggable
                                                            draggableId={`leppa-play-${v.leppaPlayId}`}
                                                            index={v.ordering}
                                                            key={`leppa-play-${v.leppaPlayId}`}
                                                        >
                                                            {(
                                                                provided,
                                                                snapshot,
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                    >
                                                                        <div
                                                                            key={
                                                                                v.leppaPlayId
                                                                            }
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <Td>
                                                                                {
                                                                                    v.title
                                                                                }
                                                                            </Td>
                                                                            <Td>
                                                                                <FlexDiv>
                                                                                    <img
                                                                                        src={
                                                                                            v.profileImg
                                                                                        }
                                                                                        width="100px"
                                                                                        alt="썸네일 이미지"
                                                                                    />
                                                                                    {
                                                                                        v.name
                                                                                    }
                                                                                </FlexDiv>
                                                                            </Td>
                                                                            <Td>
                                                                                <img
                                                                                    src={
                                                                                        v.thumbnails
                                                                                    }
                                                                                    width="300px"
                                                                                    alt="썸네일 이미지"
                                                                                    onClick={() =>
                                                                                        window.open(
                                                                                            v.videoLink,
                                                                                            '_blank',
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Td>
                                                                            <Td>
                                                                                <DeleteRounded
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        deleteClick(
                                                                                            v,
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Td>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    </Table>
                                </TableArea>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {data?.data.ResultData.totalElements > 0 && (
                        <Pagination
                            itemsCount={data?.data.ResultData.totalElements}
                            pageSize={pageSize}
                            currPage={currListPage}
                            setList={setList}
                        />
                    )}
                    <Dialog
                        open={popupOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            레빠플레이 타입 등록
                        </DialogTitle>
                        <DialogContent>
                            <FlexDiv>
                                <TextField
                                    id="youtube-id"
                                    label="유튜브아이디"
                                    style={{
                                        margin: '8px 0px 8px 0px',
                                        paddingRight: '5px',
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={youtubeId}
                                    onChange={(e) => {
                                        setYoutubeId(e.target.value);
                                    }}
                                />
                            </FlexDiv>
                            <FlexDiv>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="leppa-play-type"
                                    value={addTypeCd}
                                    label="타입"
                                    onChange={handleAddTypeChange}
                                    size="small"
                                >
                                    {!typeIsError &&
                                        !typeIsLoading &&
                                        typeData?.data.ResultData.map(
                                            (
                                                v: CommonCodeLeppaPlayTypeData,
                                            ) => {
                                                return (
                                                    <MenuItem
                                                        key={v.Cd}
                                                        value={v.Cd}
                                                    >
                                                        {v.CdNm}
                                                    </MenuItem>
                                                );
                                            },
                                        )}
                                </Select>
                            </FlexDiv>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={onSubmitProductClassification}
                                color="primary"
                            >
                                등록
                            </Button>
                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
};

export default React.memo(LeppaPlay);
