import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import {
    DeleteRounded,
    EditRounded,
    VisibilityOffRounded,
    VisibilityRounded,
} from '@mui/icons-material';
import { useMutation, useQueryClient } from 'react-query';
import {
    deleteAdText,
    insertAdText,
    modifyAdText,
    setAdTextVisibility,
    useAdTextQuery,
} from '../../hooks/ad/useAd';
import styled from '@emotion/styled';
import { AdTextData } from '../typings/ad/response';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div``;

const Table = styled.table`
    border-collapse: collapse;
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.th`
    text-align: center;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
`;

const Td = styled.td`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
`;

function AdText() {
    const [adTexDialogOpen, setAdTexDialogOpen] = useState(false);
    const [editSn, setEditSn] = useState(0);
    const [message, setMessage] = useState('');

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useAdTextQuery();

    const deleteAdTextMutation = useMutation(deleteAdText, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdTextQuery');
        },
    });

    const insertAdTextMutation = useMutation(insertAdText, {
        onSuccess: (data) => {
            if (data.data.ResultCode === 1) {
                handleClose();
                queryClient.invalidateQueries('useAdTextQuery');
            }
        },
    });

    const modifyAdTextMutation = useMutation(modifyAdText, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdTextQuery');
        },
    });

    const setAdTextVisibilityMutation = useMutation(setAdTextVisibility, {
        onSuccess: () => {
            queryClient.invalidateQueries('useAdTextQuery');
        },
    });

    const handleClose = () => {
        setAdTexDialogOpen(false);
        setEditSn(0);
        setMessage('');
    };

    const handleClickOpen = () => {
        setAdTexDialogOpen(true);
    };

    const editClick = (v: AdTextData) => {
        setAdTexDialogOpen(true);
        setEditSn(v.id);
        setMessage(v.message);
    };

    const deleteClick = (v: AdTextData) => {
        if (window.confirm('삭제하시겠습니까?')) {
            deleteAdTextMutation.mutate(v.id);
        }
    };
    const onModifyAdText = () => {
        if (message === '') {
            alert('회사명을 입력해 주세요.');
            return false;
        }
        if (message === '') {
            alert('광고 문구를 입력해 주세요.');
            return false;
        }
        let params = {
            id: editSn,
            message,
        };
        modifyAdTextMutation.mutate(params);
        handleClose();
    };
    const onSubmitAdText = () => {
        if (message === '') {
            alert('회사명을 입력해 주세요.');
            return false;
        }
        if (message === '') {
            alert('광고 문구를 입력해 주세요.');
            return false;
        }
        let params = {
            message,
        };
        insertAdTextMutation.mutate(params);
    };

    const setAdVisibility = (v: AdTextData, isVisibility: string) => {
        let msg = '광고 문구를 노출하시겠습니까?';
        if (isVisibility === 'N') {
            msg = '광고 문구를 비노출하시겠습니까?';
        }
        if (window.confirm(msg)) {
            let params = {
                id: v.id,
                showYn: isVisibility,
            };
            setAdTextVisibilityMutation.mutate(params);
        }
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        광고 문구 관리
                    </Title>
                    <div style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </div>
                    <TableArea className="table_area" style={{ clear: 'both' }}>
                        <Table cellSpacing="0" cellPadding="0">
                            <colgroup>
                                <col style={{ width: '425px' }} />
                                <col style={{ width: '125px' }} />
                                <col style={{ width: '210px' }} />
                                <col style={{ width: '110px' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <Th>문구</Th>
                                    <Th>등록자</Th>
                                    <Th>등록일</Th>
                                    <Th>노출여부</Th>
                                    <Th>&nbsp;</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data.ResultData.map((v: AdTextData) => {
                                    return (
                                        <tr key={v.id}>
                                            <Td>{v.message}</Td>
                                            <Td>{v.modId}</Td>
                                            <Td>{v.modDate}</Td>
                                            <Td>
                                                {v.showYn === 'Y' && (
                                                    <VisibilityRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            setAdVisibility(
                                                                v,
                                                                'N',
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {v.showYn === 'N' && (
                                                    <VisibilityOffRounded
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            setAdVisibility(
                                                                v,
                                                                'Y',
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </Td>
                                            <Td>
                                                <EditRounded
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        editClick(v);
                                                    }}
                                                />
                                                <DeleteRounded
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        deleteClick(v);
                                                    }}
                                                />
                                            </Td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </TableArea>
                    <Dialog
                        open={adTexDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            광고 문구
                            {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <TextField
                                    id="message"
                                    label="광고문구"
                                    fullWidth
                                    style={{ margin: '8px 0px 8px 0px' }}
                                    InputLabelProps={{ shrink: true }}
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                    }}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button
                                    onClick={onModifyAdText}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button
                                    onClick={onSubmitAdText}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
}

export default React.memo(AdText);
