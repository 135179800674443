import React, { useState } from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';
import ArrowLeft from '../../assets/arrowLeft.png';
import ArrowRight from '../../assets/arrowRight.png';

const PageWrapper = styled.div`
    margin: 30px 0 40px;
    text-align: center;
`;

const Number = styled.a`
    cursor: pointer;
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    background: #fff;
    color: #75808a;
    &.on {
        color: #0482de;
        font-weight: 700;
        text-decoration: underline;
    }
`;

const ArrowImage = styled.img``;

interface Props {
    itemsCount: number;
    pageSize: number;
    currPage: number;
    setList: (page: number) => void;
}

const Pagination = (props: Props) => {
    const { itemsCount, pageSize, currPage, setList } = props; // 각각 아이템 개수, 한 페이지에 보여줄 아이템 개수, 최근 페이지, 페이지 클릭시 호출 함수
    const pageCount = Math.ceil(itemsCount / pageSize); // 몇 페이지가 필요한지 계산

    const [paginationNum, setPaginationNum] = useState(1);
    const paginationSize = 7;

    if (pageCount === 1 || itemsCount === 0) return null; // 1페이지 뿐이라면 페이지 수를 보여주지 않음

    const pages = _.range(1, pageCount + 1); // 마지막 페이지에 보여줄 컨텐츠를 위해 +1, https://lodash.com/docs/#range 참고

    const nextOnClick = () => {
        setPaginationNum(paginationNum + 1);
    };
    const prevClick = () => {
        setPaginationNum(paginationNum - 1);
    };
    const setPage = (page: number) => {
        setList(page);
    };
    return (
        <PageWrapper>
            {paginationNum > 1 && (
                <Number onClick={prevClick}>
                    <ArrowImage src={ArrowLeft} />
                </Number>
            )}
            {pages
                .slice(
                    (paginationNum - 1) * paginationSize,
                    (paginationNum - 1) * paginationSize + paginationSize,
                )
                .map((page: number, idx: number) => (
                    <Number
                        key={idx}
                        className={currPage === page ? 'on' : ''}
                        onClick={() => {
                            setPage(page);
                        }}
                    >
                        {page}
                    </Number>
                ))}
            {paginationNum !== Math.ceil(pages.length / paginationSize) && (
                <Number onClick={nextOnClick}>
                    <ArrowImage src={ArrowRight} />
                </Number>
            )}
        </PageWrapper>
    );
};

export default React.memo(Pagination);
