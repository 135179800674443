import React, { useCallback, useState } from 'react';
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    EditRounded,
    VisibilityOffRounded,
    VisibilityRounded,
} from '@mui/icons-material';
import { useMutation, useQueryClient } from 'react-query';

import styled from '@emotion/styled';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import {
    insertLeppaPlayType,
    modifyLeppaPlayType,
    modifyLeppaPlayTypeVisibility,
    setLeppaPlayTypeDragOrderChange,
    setLeppaPlayTypeOrderChange,
    useLeppaPlayTypeQuery,
} from '../../hooks/leppa-play/useLeppaPlay';
import { LeppaPlayTypeData } from '../typings/leppa-play/response';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
`;

const TableArea = styled.div`
    clear: both;
`;

const Table = styled.div`
    border-top: 2px solid #398fce;
    font-size: 14px;
    text-align: center;
`;

const Th = styled.div`
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    background: #eff9ff;
    width: 30%;
    &:nth-last-of-type(1) {
        width: 10%;
    }
`;

const Td = styled.div`
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
    background: #fff;
    width: 30%;
    &:nth-last-of-type(1) {
        width: 10%;
    }
`;

const RightDiv = styled.div`
    float: right;
    display: flex;
    align-items: center;
`;

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`;

const LeppaPlayType = () => {
    const [popupOpen, setPopupOpen] = useState(false);
    const [name, setName] = useState('');
    const [editSn, setEditSn] = useState(0);

    const queryClient = useQueryClient();
    const { isLoading, isError, data } = useLeppaPlayTypeQuery();

    const setLeppaPlayTypeOrderChangeMutation = useMutation(
        setLeppaPlayTypeOrderChange,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useLeppaPlayTypeQuery');
            },
        },
    );

    const setLeppaPlayTypeOrderChangeDragMutation = useMutation(
        setLeppaPlayTypeDragOrderChange,
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useLeppaPlayTypeQuery');
            },
        },
    );

    const insertLeppaPlayTypeMutation = useMutation(insertLeppaPlayType, {
        onSuccess: () => {
            queryClient.invalidateQueries('useLeppaPlayTypeQuery');
        },
    });

    const modifyLeppaPlayTypeMutation = useMutation(modifyLeppaPlayType, {
        onSuccess: () => {
            queryClient.invalidateQueries('useLeppaPlayTypeQuery');
        },
    });

    const modifyLeppaPlayTypeVisibilityMutation = useMutation(
        modifyLeppaPlayTypeVisibility,
        {
            onSuccess: () => {
                setTimeout(() => {
                    queryClient.invalidateQueries('useLeppaPlayTypeQuery');
                }, 500);
            },
        },
    );

    const orderChange = (v: LeppaPlayTypeData, arrow: string) => {
        let orgIndex = data?.data.ResultData.indexOf(v);
        let targetSn;
        if (arrow === 'U') {
            if (orgIndex === 0) {
                return false;
            }
            targetSn = data?.data.ResultData[orgIndex - 1].sn;
        } else {
            if (orgIndex === data?.data.ResultData.length - 1) {
                return false;
            }
            targetSn = data?.data.ResultData[orgIndex + 1].sn;
        }
        if (window.confirm('레빠플레이 타입 순서를 변경하시겠습니까?')) {
            let params = {
                sn: v.sn,
                targetSn,
            };
            setLeppaPlayTypeOrderChangeMutation.mutate(params);
        }
    };

    const handleClickOpen = () => {
        setPopupOpen(true);
    };

    const handleClose = () => {
        setPopupOpen(false);
        setName('');
        setEditSn(0);
    };

    const onSubmitProductClassification = () => {
        if (name === '') {
            alert('타입명을 입력해주세요.');
            return false;
        }
        let params = {
            name,
        };
        insertLeppaPlayTypeMutation.mutate(params);
        handleClose();
    };

    const onModifyProductClassification = () => {
        if (name === '') {
            alert('타입명을 입력해주세요.');
            return false;
        }
        let params = {
            sn: editSn,
            name,
        };
        modifyLeppaPlayTypeMutation.mutate(params);
        handleClose();
    };

    const editClick = (v: LeppaPlayTypeData) => {
        setPopupOpen(true);
        setEditSn(v.sn);
        setName(v.cdNm);
    };

    const setVisibility = (v: LeppaPlayTypeData, isVisibility: string) => {
        let msg = '레빠플레이 타입을 노출하시겠습니까?';
        if (isVisibility === 'N') {
            msg = '레빠플레이 타입을 비노출하시겠습니까?';
        }
        if (window.confirm(msg)) {
            let params = {
                typeId: v.sn,
                isVisibility,
            };
            modifyLeppaPlayTypeVisibilityMutation.mutate(params);
        }
    };

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            const { source, destination, draggableId } = result;
            if (!destination || source.index === destination.index) {
                return;
            }
            let params = {
                sn: parseInt(draggableId.replace('leppa-play-top-', '')),
                targetOrderSn: destination.index,
            };
            setLeppaPlayTypeOrderChangeDragMutation.mutate(params);
        },
        [setLeppaPlayTypeOrderChangeDragMutation],
    );

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title style={{ marginBottom: '20px', float: 'left' }}>
                        레빠플레이 타입 관리
                    </Title>
                    <RightDiv>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                        >
                            등록
                        </Button>
                    </RightDiv>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="leppaplay-type">
                            {(provided) => (
                                <TableArea
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <Table>
                                        <div style={{ display: 'flex' }}>
                                            <Th>타입명</Th>
                                            <Th>타입코드</Th>
                                            <Th>순서변경</Th>
                                            <Th></Th>
                                            <Th>노출여부</Th>
                                        </div>
                                        <div>
                                            {data?.data.ResultData.map(
                                                (v: LeppaPlayTypeData) => {
                                                    return (
                                                        <Draggable
                                                            draggableId={`leppa-play-top-${v.sn}`}
                                                            index={v.srtSn}
                                                            key={`leppa-play-top-${v.sn}`}
                                                        >
                                                            {(
                                                                provided,
                                                                snapshot,
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                    >
                                                                        <div
                                                                            key={
                                                                                v.sn
                                                                            }
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <Td>
                                                                                {
                                                                                    v.cdNm
                                                                                }
                                                                            </Td>
                                                                            <Td>
                                                                                {
                                                                                    v.cd
                                                                                }
                                                                            </Td>
                                                                            <Td>
                                                                                <ArrowUpwardRounded
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        orderChange(
                                                                                            v,
                                                                                            'U',
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                <ArrowDownwardRounded
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        orderChange(
                                                                                            v,
                                                                                            'D',
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Td>
                                                                            <Td>
                                                                                <EditRounded
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        editClick(
                                                                                            v,
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Td>
                                                                            <Td>
                                                                                {v.showYn ===
                                                                                    'Y' && (
                                                                                    <VisibilityRounded
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            setVisibility(
                                                                                                v,
                                                                                                'N',
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                {v.showYn ===
                                                                                    'N' && (
                                                                                    <VisibilityOffRounded
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            setVisibility(
                                                                                                v,
                                                                                                'Y',
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Td>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                },
                                            )}
                                        </div>
                                    </Table>
                                </TableArea>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Dialog
                        open={popupOpen}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            레빠플레이 타입
                            {editSn === 0 && ' 등록'}
                            {editSn > 0 && ' 수정'}
                        </DialogTitle>
                        <DialogContent>
                            <FlexDiv>
                                <TextField
                                    id="fileName"
                                    label="타입명"
                                    style={{
                                        margin: '8px 0px 8px 0px',
                                        paddingRight: '5px',
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </FlexDiv>
                        </DialogContent>
                        <DialogActions>
                            {editSn > 0 && (
                                <Button
                                    onClick={onModifyProductClassification}
                                    color="primary"
                                >
                                    수정
                                </Button>
                            )}
                            {editSn === 0 && (
                                <Button
                                    onClick={onSubmitProductClassification}
                                    color="primary"
                                >
                                    등록
                                </Button>
                            )}

                            <Button onClick={handleClose} color="primary">
                                취소
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Base>
            )}
        </>
    );
};

export default React.memo(LeppaPlayType);
