import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { usePointQuery } from '../../hooks/point/usePoint';
import icSearch from '../../assets/point/icSearch.png';
import icToolTip from '../../assets/point/icToolTip.png';
import icTitle from '../../assets/point/icTitle.png';
import icRefresh from '../../assets/point/icRefresh.png';
import {
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko';
import { format } from 'date-fns';
import { LeppaPointResponse } from '../typings/point/response';
import Pagination from '../common/Pagination';
import PointPopup from './PointPopup';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
    font-family: SUIT, serif;
`;

const Title = styled.h3`
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 40px;
`;
const SearchArea = styled.div`
    border-bottom: 1px solid #ebeef0;
`;

const UserSearch = styled.div`
    margin-bottom: 24px;
    display: flex;
    & > div:first-of-type {
        color: #3f464e;
        font-size: 16px;
        font-weight: 500;
        width: 200px;
        align-items: center;
    }
    & > div:nth-of-type(2) {
        border-radius: 8px;
        border: 1px solid #ebeef0;
        width: 326px;
        padding: 8px 12px;
        display: flex;
        & > input {
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
            border: 0;
            width: 305px;

            &:focus {
                outline: none;
            }
        }

        & > img {
            width: 20px;
            cursor: pointer;
        }
    }
`;

const DateSearch = styled.div`
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    & > div:first-of-type {
        color: #3f464e;
        font-size: 16px;
        font-weight: 500;
        width: 200px;
        align-items: center;
    }
    & > div:nth-of-type(2) {
        display: flex;
    }
`;

const UsageSearch = styled.div`
    margin: 24px 0;
    display: flex;
    align-items: center;
    & > div:first-of-type {
        color: #3f464e;
        font-size: 16px;
        font-weight: 500;
        width: 200px;
        align-items: center;
    }
`;

const SelectSearch = styled.div`
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    & > div:nth-of-type(1),
    & > div:nth-of-type(3) {
        color: #3f464e;
        font-size: 16px;
        font-weight: 500;
        width: 200px;
        align-items: center;
        display: flex;
        & > img {
            width: 16px;
            cursor: pointer;
            margin-top: 1px;
            margin-left: 3px;
        }
        position: relative;
    }
    & > div:nth-of-type(2),
    & > div:nth-of-type(4) {
        width: 200px;
    }
`;

const StatusDescriptionArea = styled.div`
    padding: 1rem;
    width: 436px;
    position: absolute;
    top: 35px;
    left: -120px;
    border-radius: 8px;
    border: 1px solid #25282b;
    background: #fff;
    box-shadow: 0px 12px 16px 4px rgba(37, 40, 43, 0.06),
        0px 4px 16px 4px rgba(37, 40, 43, 0.08);

    & > div:nth-of-type(1) {
        color: #3f464e;
        padding-bottom: 1rem;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebeef0;

        & > img {
            width: 16px;
            height: 18px;
            margin-right: 3px;
        }
    }
    & > div:nth-of-type(2) {
        padding-top: 1rem;
    }
`;

const StatusTitle = styled.div`
    color: #3f464e;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 8px;
`;
const StatusContent = styled.div`
    color: #3f464e;
    font-size: 13px;
    padding-bottom: 16px;
`;

const SearchInitArea = styled.div`
    padding-top: 16px;
    cursor: pointer;
    & > div {
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 10px 16px 10px 12px;

        border-radius: 18px;
        background: #f8f9fa;
        color: #25282b;
        width: 120px;
        & > div > img {
            margin-right: 3px;
        }
    }
`;

const DataArea = styled.div`
    padding-top: 16px;
    display: flex;
    align-items: center;
`;

const DataContent = styled.div`
    & > div {
        display: flex;
        align-items: center;
    }
`;

interface DataHeaderProps {
    width?: number;
}
const DataHeader = styled.div<DataHeaderProps>`
    color: #3f464e;
    border-top: 2px solid #25282b;
    border-bottom: 1px solid #25282b;
    width: ${({ width }) => `${!!width ? width : 80}px`};
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
`;
interface DataValueProps {
    width?: number;
    isUnderline?: boolean;
    type?: string;
    isPointer?: boolean;
}
const DataValue = styled.div<DataValueProps>`
    color: ${({ type }) =>
        `${type === 'CANCEL' || type === 'MOD' ? '#EA2C4E' : '#3f464e'}`};
    border-bottom: 1px solid #ebeef0;
    width: ${({ width }) => `${!!width ? width : 80}px`};
    cursor: ${({ isPointer }) => `${isPointer ? 'pointer' : 'auto'}`};
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
    padding: 10px 0;
    text-decoration: ${({ isUnderline }) =>
        `${isUnderline ? 'underline' : 'none'}`};

    & > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
`;

interface StatusValueProps {
    bgColor?: string;
    color?: string;
    borderColor?: string;
}

const StatusValue = styled.div<StatusValueProps>`
    padding: 7px 10px;
    align-items: center;
    border-radius: 16px;
    border: 1px solid
        ${({ borderColor }) => `${!!borderColor ? borderColor : 'none'}`};
    background: ${({ bgColor }) => `${!!bgColor ? bgColor : '#F8F9FA'}`};
    color: ${({ color }) => `${!!color ? color : '#3F464E'}`};
`;

const Point = () => {
    const [user, setUser] = useState('');
    const [dateType, setDateType] = useState('C');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [type, setType] = useState('ALL');
    const [status, setStatus] = useState('ALL');
    const [usage, setUsage] = useState('');
    const [statusDescriptionShow, setStatusDescriptionShow] = useState(false);
    const [detailPopupShow, setDetailPopupShow] = useState(false);
    const [eventId, setEventId] = useState('');
    const [memberNo, setMemberNo] = useState('');

    const pageSize = 10;
    const [currListPage, setCurrListPage] = useState(1);

    const setList = (page: number) => {
        setCurrListPage(page);
    };

    const { isLoading, isError, data, refetch } = usePointQuery(
        user,
        dateType,
        startDate,
        endDate,
        type,
        status,
        usage,
        currListPage,
        pageSize,
    );

    const dateTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDateType(e.target.value);
    };

    const usageChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUsage(e.target.value);
    };

    const handleTypeChange = (e: SelectChangeEvent) => {
        setType(e.target.value);
    };

    const handleStatusChange = (e: SelectChangeEvent) => {
        setStatus(e.target.value);
    };

    const parseUsage = (v: string) => {
        switch (v) {
            case 'RESERVATION':
                return '레저예약';
            case 'LEPPA_MARKET':
            case 'LEPPA_MARKET_GIFT':
                return '레빠마켓';
            default:
                return '';
        }
    };

    const parseType = (v: string) => {
        switch (v) {
            case 'PAYMENT':
                return '결제';
            case 'EVENT':
                return '이벤트';
            case 'REVIEW':
                return '리뷰작성';
            case 'REVIEW_REMOVE':
                return '리뷰삭제';
            case 'MOD':
                return '직접처리';
            case 'CANCEL':
                return '환불';
            default:
                return '';
        }
    };

    const parseStatus = (v: string) => {
        switch (v) {
            case 'RETURN':
                return (
                    <StatusValue color={'#EA2C4E'} bgColor={'#F8E3E7'}>
                        반환
                    </StatusValue>
                );
            case 'CANCEL':
                return (
                    <StatusValue color={'#EA2C4E'} bgColor={'#F8E3E7'}>
                        적립취소
                    </StatusValue>
                );
            case 'SCHEDULE_SAVE':
                return (
                    <StatusValue color={'#47B50B'} bgColor={'#EAF9E1'}>
                        적립예정
                    </StatusValue>
                );
            case 'USE':
                return <StatusValue borderColor={'#EBEEF0'}>사용</StatusValue>;
            case 'SAVE':
                return <StatusValue borderColor={'#EBEEF0'}>적립</StatusValue>;
            case 'EXTINCTION':
                return <StatusValue borderColor={'#EBEEF0'}>소멸</StatusValue>;
            default:
                return <StatusValue borderColor={'#EBEEF0'}>-</StatusValue>;
        }
    };

    const onClickInitSearch = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault();
            setUser('');
            setDateType('C');
            setStartDate('');
            setEndDate('');
            setType('ALL');
            setStatus('ALL');
            setUsage('');
        },
        [],
    );

    useEffect(() => {
        refetch();
    }, [
        currListPage,
        user,
        dateType,
        startDate,
        endDate,
        type,
        status,
        usage,
        refetch,
    ]);

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <Title>포인트 관리</Title>
                    <SearchArea>
                        <UserSearch>
                            <div>사용자검색</div>
                            <div>
                                <input
                                    placeholder="사용자 정보(본명, 연락처)를 입력해주세요"
                                    value={user}
                                    onChange={(e) => setUser(e.target.value)}
                                />
                                <img src={icSearch} alt="검색" />
                            </div>
                        </UserSearch>
                        <DateSearch>
                            <div>포인트 조회 기간 기준</div>
                            <div>
                                <RadioGroup
                                    row
                                    aria-label="조회기간기준"
                                    name="row-radio-buttons-group"
                                    onChange={dateTypeChange}
                                    value={dateType}
                                >
                                    <FormControlLabel
                                        value="C"
                                        control={<Radio />}
                                        label="변동일"
                                    />
                                    <FormControlLabel
                                        value="E"
                                        control={<Radio />}
                                        label="만료일"
                                    />
                                </RadioGroup>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={koLocale}
                                >
                                    <DateTimePicker
                                        renderInput={(props) => (
                                            <TextField
                                                {...props}
                                                error={false}
                                            />
                                        )}
                                        label={startDate}
                                        value={startDate}
                                        inputFormat={'yyyyMMdd'}
                                        onChange={(newValue: Date | null) => {
                                            if (newValue) {
                                                setStartDate(
                                                    format(
                                                        newValue,
                                                        'yyyyMMdd',
                                                    ),
                                                );
                                            }
                                        }}
                                        views={['year', 'day']}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={koLocale}
                                >
                                    <DateTimePicker
                                        renderInput={(props) => (
                                            <TextField
                                                {...props}
                                                error={false}
                                            />
                                        )}
                                        label={endDate}
                                        value={endDate}
                                        inputFormat={'yyyyMMdd'}
                                        onChange={(newValue: Date | null) => {
                                            if (newValue) {
                                                setEndDate(
                                                    format(
                                                        newValue,
                                                        'yyyyMMdd',
                                                    ),
                                                );
                                            }
                                        }}
                                        views={['year', 'day']}
                                    />
                                </LocalizationProvider>
                            </div>
                        </DateSearch>
                    </SearchArea>
                    <SearchArea>
                        <UsageSearch>
                            <div>구분</div>
                            <div>
                                <RadioGroup
                                    row
                                    aria-label="사용처"
                                    name="usage-radio-buttons-group"
                                    onChange={usageChange}
                                    value={usage}
                                >
                                    <FormControlLabel
                                        value=""
                                        control={<Radio />}
                                        label="전체"
                                    />
                                    <FormControlLabel
                                        value="RESERVATION"
                                        control={<Radio />}
                                        label="레저예약"
                                    />
                                    <FormControlLabel
                                        value="LEPPA_MARKET"
                                        control={<Radio />}
                                        label="레빠마켓"
                                    />
                                </RadioGroup>
                            </div>
                        </UsageSearch>
                        <SelectSearch>
                            <div>포인트 변동유형</div>
                            <div>
                                <Select
                                    labelId="type-select-label"
                                    id="type-select"
                                    value={type}
                                    label="변동유형"
                                    onChange={handleTypeChange}
                                    size="small"
                                >
                                    <MenuItem value="ALL">전체</MenuItem>
                                    <MenuItem value="PAYMENT">결제</MenuItem>
                                    <MenuItem value="EVENT">이벤트</MenuItem>
                                    <MenuItem value="REVIEW">리뷰작성</MenuItem>
                                    <MenuItem value="REVIEW_REMOVE">
                                        리뷰삭제
                                    </MenuItem>
                                    <MenuItem value="MOD">MOD</MenuItem>
                                </Select>
                            </div>
                            <div>
                                포인트 상태
                                <img
                                    src={icToolTip}
                                    alt="설명"
                                    onMouseOver={() => {
                                        setStatusDescriptionShow(true);
                                    }}
                                    onMouseOut={() => {
                                        setStatusDescriptionShow(false);
                                    }}
                                />
                                {statusDescriptionShow && (
                                    <StatusDescriptionArea>
                                        <div>
                                            <img src={icTitle} alt="이미지" />
                                            포인트 상태 분류
                                        </div>
                                        <div>
                                            <StatusTitle>1. 사용</StatusTitle>
                                            <StatusContent>
                                                레저예약 및 레빠마켓 상품 구매시
                                                결제 단계에서 사용된 포인트
                                            </StatusContent>
                                            <StatusTitle>2. 적립</StatusTitle>
                                            <StatusContent>
                                                레저예약 후 이용일에, 레빠마켓
                                                상품 구매 후 구매확정 시, 그 외
                                                이벤트 등을 통해 적립된 포인트
                                            </StatusContent>
                                            <StatusTitle>3. 반환</StatusTitle>
                                            <StatusContent>
                                                레저예약을 하고 예약 일자가
                                                지나기 전, 레빠마켓 상품이
                                                구매확정되기 전 상태일 때의
                                                포인트
                                            </StatusContent>
                                            <StatusTitle>
                                                4. 적립예정
                                            </StatusTitle>
                                            <StatusContent>
                                                레저예약을 취소하거나 레빠마켓
                                                상품을 환불할 때, 결제 시 사용한
                                                포인트를 돌려주는 경우의 상태값
                                            </StatusContent>
                                            <StatusTitle>
                                                5. 적립취소
                                            </StatusTitle>
                                            <StatusContent>
                                                적립예정인 상태의 포인트를
                                                환불/예약취소할 때의 상태값
                                            </StatusContent>
                                        </div>
                                    </StatusDescriptionArea>
                                )}
                            </div>
                            <div>
                                <Select
                                    labelId="status-select-label"
                                    id="status-select"
                                    value={status}
                                    label="포인트상태"
                                    onChange={handleStatusChange}
                                    size="small"
                                >
                                    <MenuItem value="ALL">전체</MenuItem>
                                    <MenuItem value="SAVE">적립</MenuItem>
                                    <MenuItem value="SCHEDULE_SAVE">
                                        적립예정
                                    </MenuItem>
                                    <MenuItem value="USE">사용</MenuItem>
                                    <MenuItem value="RETURN">반환</MenuItem>
                                    <MenuItem value="CANCEL">적립취소</MenuItem>
                                </Select>
                            </div>
                        </SelectSearch>
                    </SearchArea>
                    <SearchInitArea onClick={onClickInitSearch}>
                        <div>
                            <img src={icRefresh} alt="새로고침" />
                            <div>검색초기화</div>
                        </div>
                    </SearchInitArea>
                    <DataArea>
                        <DataHeader>날짜</DataHeader>
                        <DataHeader>사용자이름</DataHeader>
                        <DataHeader>사용처</DataHeader>
                        <DataHeader>유형</DataHeader>
                        <DataHeader>상태</DataHeader>
                        <DataHeader width={120}>포인트</DataHeader>
                        <DataHeader width={250}>내용</DataHeader>
                        <DataHeader width={100}>구매번호</DataHeader>
                        <DataHeader width={100}>만료일</DataHeader>
                        <DataHeader>보유포인트</DataHeader>
                    </DataArea>
                    <DataContent>
                        {data?.data.ResultData.content.map(
                            (v: LeppaPointResponse) => (
                                <div key={v.id}>
                                    <DataValue>{v.regDt}</DataValue>
                                    <DataValue
                                        isUnderline={true}
                                        isPointer={true}
                                        onClick={() => {
                                            setDetailPopupShow(true);
                                            setEventId(v.id);
                                            setMemberNo(v.memberNo);
                                        }}
                                    >
                                        {v.name}
                                    </DataValue>
                                    <DataValue>
                                        {parseUsage(v.usePlace)}
                                    </DataValue>
                                    <DataValue type={v.type}>
                                        {parseType(v.type)}
                                    </DataValue>
                                    <DataValue width={120}>
                                        {parseStatus(v.status)}
                                    </DataValue>
                                    <DataValue>{`${v.amount.toLocaleString()}Lp`}</DataValue>
                                    <DataValue width={250}>
                                        {v.description}
                                    </DataValue>
                                    <DataValue
                                        width={100}
                                        title={`${v.purchaseId}`}
                                    >
                                        <div>{v.purchaseId}</div>
                                    </DataValue>
                                    <DataValue width={100}>
                                        {v.expiredDate}
                                    </DataValue>
                                    <DataValue>{`${v.currentPoint.toLocaleString()}Lp`}</DataValue>
                                </div>
                            ),
                        )}
                    </DataContent>
                    {data?.data.ResultData.totalElements > 0 && (
                        <Pagination
                            itemsCount={data?.data.ResultData.totalElements}
                            pageSize={pageSize}
                            currPage={currListPage}
                            setList={setList}
                        />
                    )}
                    {detailPopupShow && (
                        <PointPopup
                            setDetailPopupShow={setDetailPopupShow}
                            setEventId={setEventId}
                            eventId={eventId}
                            memberNo={memberNo}
                        />
                    )}
                </Base>
            )}
        </>
    );
};
export default Point;
