import axiosApiInstance from '../../utils/axiosInterceptor';
import { apiUrl } from '../../utils/common';
import { useQuery } from 'react-query';
import {
    FreeMarketOrderChangeRequest,
    FreeMarketTopOrderChangeRequest,
    LeppaMarketCategoryOrderChangeRequest,
    LeppaMarketCategoryVisibilityRequest,
    ModifyFreeMarketRequest,
    ModifyFreeMarketTopRequest,
    ModifyLeppaMarketCategoryRequest,
} from '../../components/typings/category/types';

const getFreeMarketCategoryApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/free-market/category',
        method: 'GET',
    });
};

export const useFreeMarketCategoryQuery = () =>
    useQuery(['useFreeMarketCategoryQuery'], () => getFreeMarketCategoryApi(), {
        retry: 3,
        keepPreviousData: true,
    });

export const insertFreeMarketCategory = (name: string) =>
    axiosApiInstance({
        url: apiUrl + '/api/free-market/category',
        method: 'POST',
        data: {
            name,
        },
    });

export const modifyFreeMarketCategory = (params: ModifyFreeMarketRequest) =>
    axiosApiInstance({
        url: apiUrl + '/api/free-market/category',
        method: 'PUT',
        data: params,
    });

export const setFreeMarketCategoryOrderChange = (
    params: FreeMarketOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/free-market/category/order-change',
        method: 'PUT',
        data: params,
    });

const getFreeMarketTopCategoryApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/free-market/top/category',
        method: 'GET',
    });
};

export const useFreeMarketTopCategoryQuery = () =>
    useQuery(
        ['useFreeMarketTopCategoryQuery'],
        () => getFreeMarketTopCategoryApi(),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const insertFreeMarketTopCategory = (categoryId: number) => {
    let formData = new FormData();
    const file = document.getElementById('file-input') as HTMLInputElement;
    if (file && file.files) {
        formData.append('file', file.files[0]);
    }
    formData.append('categoryId', categoryId.toString());

    return axiosApiInstance.post(
        apiUrl + '/api/free-market/top/category',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const modifyFreeMarketTopCategory = (
    params: ModifyFreeMarketTopRequest,
) => {
    let formData = new FormData();

    if (params.fileName !== '') {
        const file = document.getElementById('file-input') as HTMLInputElement;
        if (file && file.files) {
            formData.append('file', file.files[0]);
        }
    }
    formData.append('categoryMappingId', params.categoryMappingId.toString());
    formData.append('categoryId', params.categoryId.toString());

    return axiosApiInstance.put(
        apiUrl + '/api/free-market/top/category',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
};

export const deleteFreeMarketTopCategory = (categoryMappingId: number) =>
    axiosApiInstance({
        url: apiUrl + '/api/free-market/top/category',
        method: 'DELETE',
        data: {
            categoryMappingId,
        },
    });

export const setFreeMarketTopCategoryOrderChange = (
    params: FreeMarketTopOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/free-market/top/category/order-change',
        method: 'PUT',
        data: params,
    });

const getLeppaMarketCategoryApi = () => {
    return axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/category',
        method: 'GET',
    });
};

export const useLeppaMarketCategoryQuery = () =>
    useQuery(
        ['useLeppaMarketCategoryQuery'],
        () => getLeppaMarketCategoryApi(),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

export const insertLeppaMarketCategory = (name: string) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/category',
        method: 'POST',
        data: {
            name,
        },
    });

export const modifyLeppaMarketCategory = (
    params: ModifyLeppaMarketCategoryRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/category',
        method: 'PUT',
        data: params,
    });

export const setLeppaMarketCategoryOrderChange = (
    params: LeppaMarketCategoryOrderChangeRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/category/order-change',
        method: 'PUT',
        data: params,
    });

export const setLeppaMarketCategoryVisibility = (
    params: LeppaMarketCategoryVisibilityRequest,
) =>
    axiosApiInstance({
        url: apiUrl + '/api/v1/biz/leppa-market/category/visibility',
        method: 'PUT',
        data: params,
    });
