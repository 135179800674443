import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';

import Calendar from 'react-calendar';
import { GetAppRounded, VisibilityOffRounded } from '@mui/icons-material';
import { format } from 'date-fns';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    getBbargeStateMemberMainApi,
    getCalcMainApi,
    getEnterpriseInfoApi,
    getVisitHistoryExcel,
    saveEnterpriseLonLat,
    setManagerActive,
} from '../../hooks/enterprise/useEnterprise';
import styled from '@emotion/styled';
import { useParams } from 'react-router';
import { QRCodeCanvas } from 'qrcode.react';

const Base = styled.div`
    position: ${window.innerWidth < 1220 ? 'absolute' : 'relative'};
    left: ${window.innerWidth < 1220 ? '198px' : 'auto'};
    float: left;
    width: 950px;
    padding-top: 40px;
    padding-left: 30px;
`;

const Title = styled.h2`
    font-size: 24px;
    margin-bottom: 20px;
`;

const Table = styled.table`
    font-size: 16px;
    margin-bottom: 10px;
`;

const Th = styled.th`
    width: 100px;
    background: #398fce;
    padding: 10px;
    color: #fff;
    font-weight: 400;
`;

const Td = styled.td`
    width: 170px;
    background: #fff;
    padding: 10px 15px;
`;

const MemberShipDiv = styled.div`
    width: 610px;
    float: left;
`;

const DefaultBox = styled.div`
    width: 340px;
    height: 350px;
    float: left;
    background: #fff;
    margin: 10px 0;
    border-radius: 5px;
`;

const WrapperBox = styled.div`
    width: 260px;
    height: 360px;
    float: left;
    margin: 0 10px 0 0;
    border-radius: 5px;
`;

const HalfBox = styled.div`
    width: 100%;
    height: 170px;
    font-size: 14px;
    float: left;
    border-radius: 5px;
    margin: 10px;
    background: #fff;
`;

const BoxTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
    margin: 15px;
`;

const DateText = styled.div`
    color: #2391de;
    margin: 0 0 25px 15px;
    float: left;
`;

const CountText = styled.div`
    float: right;
    margin: -5px 15px 0 0;
    color: #00468e;
`;

const SalesArea = styled.div`
    width: 610px;
    height: 140px;
    float: left;
    background: #fff;
`;

const SalesTitle = styled.h3`
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding-top: 20px;
`;

const SalesTable = styled.table`
    width: 100%;
    border-top: 1px solid #ccc;
    margin-top: 15px;
    font-size: 14px;
`;

const SalesTh = styled.th`
    padding: 10px;
    font-weight: 400;
    border-bottom: 1px solid #ccc;
`;

const SalesTd = styled.td`
    padding: 10px;
    font-weight: 700;
    text-align: center;
`;

const InfoBox = styled.div`
    width: 330px;
    height: 500px;
    float: left;
    margin: 10px 0 0 10px;
    font-size: 14px;
    background-color: #2d518e;
    color: #fff;
`;

const RightArrow = styled.span`
    cursor: pointer;
    margin: 5px;
    font-size: 20px;
    color: #2391de;
`;

const Binfo = styled.div`
    width: 100%;
    font-size: 16px;
    padding: 0 30px;
    margin-top: 35px;
    box-sizing: border-box;
`;

const Ul = styled.ul`
    list-style: none;
`;

const Li = styled.li`
    line-height: 22px;
`;

const CodeBox = styled.div`
    text-align: center;
    line-height: 20px;
`;

const CodeBoxInput = styled.input`
    width: 220px;
    padding: 5px 10px;
    font-size: 14px;
    color: #fff;
    border: none;
    border-bottom: 1px solid #fff;
    background: none;
`;

const CodeBoxButtonLi = styled.li`
    float: left;
`;

const CodeBoxButton = styled.span`
    width: 80px;
    padding: 5px 0;
    font-weight: 700;
    display: inline-block;
    background: #fff;
    color: #404040;
    cursor: pointer;
    &:first-of-type {
        margin-right: 5px;
    }
`;

const ExcelDownloadArea = styled.div`
    float: right;
    cursor: pointer;
`;

const QrImage = styled.div``;

const ButtonArea = styled.div`
    text-align: center;
    line-height: 20px;
`;

const Hr = styled.hr`
    clear: both;
    width: 90%;
    margin: 0 0 25px 15px;
`;

const CodeBoxButtonUl = styled.ul`
    width: 165px;
    margin: 15px auto 0;
    overflow: hidden;
`;

function EnterpriseDetail() {
    const { id } = useParams();
    const [dateVal, setDateVal] = useState(new Date());

    const onChange = (searchDate: Date) => {
        setDateVal(searchDate);
    };

    const { isLoading, isError, data } = useQuery(
        ['useEnterpriseInfoQuery'],
        () => getEnterpriseInfoApi(Number(id)),
        {
            retry: 3,
            keepPreviousData: true,
        },
    );

    const entpNo = data?.data.ResultData.EntpNo;
    const bargNo = data?.data.ResultData.BargNo;
    const bargNm = data?.data.ResultData.BargNm;
    const bargLat = data?.data.ResultData.lat;
    const bargLon = data?.data.ResultData.lon;

    const [lat, setLat] = useState('');
    const [lon, setLon] = useState('');

    useEffect(() => {
        if (bargLat && bargLon) {
            setLat(bargLat);
            setLon(bargLon);
        }
    }, [bargLat, bargLon]);

    const { data: stateMemberMainData, refetch: stateMemberMainDataRefetch } =
        useQuery(
            ['useBbargeStateMemberMainQuery'],
            () => {
                if (entpNo !== '' && bargNo !== '') {
                    return getBbargeStateMemberMainApi(
                        entpNo,
                        bargNo,
                        format(dateVal, 'yyyyMMdd'),
                    );
                }
            },
            {
                retry: 3,
                keepPreviousData: true,
                enabled: !!entpNo && !!bargNo,
            },
        );

    const { data: calcDy, refetch: calcDyRefetch } = useQuery(
        ['useCalcMainQuery'],
        () => {
            if (entpNo !== '' && bargNo !== '') {
                return getCalcMainApi(
                    entpNo,
                    bargNo,
                    format(dateVal, 'yyyyMMdd'),
                    'D',
                );
            }
        },
        {
            retry: 3,
            keepPreviousData: true,
            enabled: !!entpNo && !!bargNo,
        },
    );

    const queryClient = useQueryClient();
    const { data: calcMonth, refetch: calcMonthRefetch } = useQuery(
        ['useCalcMainMonthQuery'],
        () => {
            if (entpNo !== '' && bargNo !== '') {
                return getCalcMainApi(
                    entpNo,
                    bargNo,
                    format(dateVal, 'yyyyMMdd'),
                    'M',
                );
            }
        },
        {
            retry: 3,
            keepPreviousData: true,
            enabled: !!entpNo && !!bargNo,
        },
    );
    const saveEnterpriseLonLatMutation = useMutation(saveEnterpriseLonLat, {
        onSuccess: () => {
            queryClient.invalidateQueries('useEnterpriseInfoQuery');
        },
    });
    const setManagerActiveMutation = useMutation(setManagerActive, {
        onSuccess: () => {
            queryClient.invalidateQueries('useEnterpriseInfoQuery');
        },
    });

    const getVisitHistoryExcelMutation = useMutation(getVisitHistoryExcel, {
        onSuccess: (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            const contentDisposition = res.headers['content-disposition']; // 파일 이름
            let fileName = 'unknown';
            if (contentDisposition) {
                const [fileNameMatch] = contentDisposition
                    .split(';')
                    .filter((str) => str.includes('filename'));
                if (fileNameMatch) [, fileName] = fileNameMatch.split('=');
                fileName = decodeURI(fileName);
                link.href = url;
                link.setAttribute('download', `${fileName}`);
                link.style.cssText = 'display:none';
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        },
    });

    useEffect(() => {
        if (!!entpNo && !!bargNo) {
            stateMemberMainDataRefetch();
            calcDyRefetch();
            calcMonthRefetch();
        }
    }, [
        bargNo,
        calcDyRefetch,
        calcMonthRefetch,
        dateVal,
        entpNo,
        stateMemberMainDataRefetch,
    ]);

    const dateMemberDetail = () => {
        if (
            stateMemberMainData &&
            stateMemberMainData.data.ResultData.TodayCnt > 0
        ) {
        }
    };

    const totalMemberDetail = () => {
        if (
            stateMemberMainData &&
            stateMemberMainData.data.ResultData.TotCnt > 0
        ) {
        }
    };

    const getCouponTotalDetail = () => {
        if (
            stateMemberMainData &&
            stateMemberMainData.data.ResultData.TotCpnCnt > 0
        ) {
        }
    };

    const getCouponDateDetail = () => {
        if (
            stateMemberMainData &&
            stateMemberMainData.data.ResultData.TodayCpnAplyCnt > 0
        ) {
        }
    };

    const saveLatLon = () => {
        if (lon !== '' && lat !== '') {
            let params = {
                barg_no: bargNo,
                barg_lat: lat,
                barg_log: lon,
            };
            saveEnterpriseLonLatMutation.mutate(params);
        }
    };

    const downloadClick = () => {
        let params = {
            bargNo,
            bargNm,
            dy: format(dateVal, 'yyyyMM'),
        };
        getVisitHistoryExcelMutation.mutate(params);
    };

    const clickManagerActive = () => {
        if (window.confirm('사업자 계정 승인처리를 하시겠습니까?')) {
            setManagerActiveMutation.mutate(bargNo);
        }
    };

    return (
        <>
            {isLoading || isError ? (
                <div>loading</div>
            ) : (
                <Base>
                    <div className="basic_info">
                        <Title>
                            {data?.data.ResultData.EntpNm}(빠지명:
                            {data?.data.ResultData.BargNm})
                        </Title>
                        <Table cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    <Th>관리자명</Th>
                                    <Td>{data?.data.ResultData.RepNm}</Td>
                                    <Th>관리번호</Th>
                                    <Td>{data?.data.ResultData.bargMngNo}</Td>
                                    <Th>연락처</Th>
                                    <Td>
                                        {data?.data.ResultData.BargMngTelNo}
                                    </Td>
                                </tr>
                                <tr>
                                    <Th>이메일</Th>
                                    <Td colSpan={3}>
                                        {data?.data.ResultData.RepEmail}
                                    </Td>
                                    <Th>승인 처리</Th>
                                    <Td>
                                        {data?.data.ResultData.mngStatCd ===
                                            '01' && '승인완료'}
                                        {data?.data.ResultData.mngStatCd ===
                                            '02' && (
                                            <VisibilityOffRounded
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    clickManagerActive();
                                                }}
                                            />
                                        )}
                                    </Td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <MemberShipDiv>
                        <DefaultBox>
                            <Calendar
                                calendarType={'US'}
                                className={'calendar_center'}
                                onChange={onChange}
                                value={dateVal}
                                maxDate={new Date()}
                            />
                        </DefaultBox>
                        <WrapperBox>
                            <HalfBox>
                                <BoxTitle>
                                    회원현황
                                    <ExcelDownloadArea>
                                        <GetAppRounded
                                            onClick={downloadClick}
                                        />
                                    </ExcelDownloadArea>
                                </BoxTitle>

                                <DateText>
                                    {format(dateVal, 'M월 d일')} 방문회원
                                </DateText>
                                <CountText>
                                    {stateMemberMainData &&
                                        stateMemberMainData.data.ResultData &&
                                        stateMemberMainData.data.ResultData
                                            .TodayCnt}
                                    명
                                    <RightArrow onClick={dateMemberDetail}>
                                        {'>'}
                                    </RightArrow>
                                </CountText>
                                <Hr />
                                <DateText>전체회원</DateText>
                                <CountText>
                                    {stateMemberMainData &&
                                        stateMemberMainData.data.ResultData &&
                                        stateMemberMainData.data.ResultData
                                            .TotCnt}
                                    명
                                    <RightArrow onClick={totalMemberDetail}>
                                        {'>'}
                                    </RightArrow>
                                </CountText>
                            </HalfBox>
                            <HalfBox>
                                <BoxTitle>쿠폰현황</BoxTitle>
                                <DateText>
                                    {format(dateVal, 'M월 d일')} 쿠폰신청 회원
                                </DateText>
                                <CountText>
                                    {stateMemberMainData &&
                                        stateMemberMainData.data.ResultData &&
                                        stateMemberMainData.data.ResultData
                                            .TodayCpnAplyCnt}
                                    명
                                    <RightArrow onClick={getCouponDateDetail}>
                                        {'>'}
                                    </RightArrow>
                                </CountText>
                                <Hr />
                                <DateText>전체 선불쿠폰 회원</DateText>
                                <CountText>
                                    {stateMemberMainData &&
                                        stateMemberMainData.data.ResultData &&
                                        stateMemberMainData.data.ResultData
                                            .TotCpnCnt}
                                    명
                                    <RightArrow onClick={getCouponTotalDetail}>
                                        {'>'}
                                    </RightArrow>
                                </CountText>
                            </HalfBox>
                        </WrapperBox>
                        <SalesArea>
                            <SalesTitle>정산관리_매출 현황</SalesTitle>
                            <SalesTable cellPadding="0" cellSpacing="0">
                                <colgroup>
                                    <col style={{ width: '50%' }} />
                                    <col style={{ width: '50%' }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <SalesTh>일별 내역</SalesTh>
                                        <SalesTh>월별 내역</SalesTh>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <SalesTd>
                                            {calcDy &&
                                            calcDy.data.ResultData.day &&
                                            calcDy.data.ResultData.TotAmt
                                                ? calcDy.data.ResultData.TotAmt.toLocaleString()
                                                : 0}
                                            원
                                        </SalesTd>
                                        <SalesTd>
                                            {calcMonth &&
                                            calcMonth.data.ResultData &&
                                            calcMonth.data.ResultData.TotAmt
                                                ? calcMonth.data.ResultData.TotAmt.toLocaleString()
                                                : 0}
                                            원
                                        </SalesTd>
                                    </tr>
                                </tbody>
                            </SalesTable>
                        </SalesArea>
                    </MemberShipDiv>
                    <InfoBox>
                        <Binfo>
                            <Ul>
                                <Li>
                                    대표자 이름 : {data?.data.ResultData.RepNm}
                                </Li>
                                <Li>
                                    사업자번호 :{' '}
                                    {data?.data.ResultData.EntpRegNo}
                                </Li>
                                <Li>
                                    업체주소 : {data?.data.ResultData.Addr}
                                    &nbsp;
                                    {data?.data.ResultData.DtlAddr}
                                </Li>
                                <Li>
                                    빠지주소 : {data?.data.ResultData.BargAddr}
                                    &nbsp;
                                    {data?.data.ResultData.BargDtlAddr}
                                </Li>
                                <Li>
                                    기업번호 : {data?.data.ResultData.EntpNo}
                                </Li>
                                <Li>
                                    빠지번호 : {data?.data.ResultData.BargNo}
                                </Li>
                            </Ul>
                        </Binfo>
                        <CodeBox>
                            <QrImage>
                                <QRCodeCanvas
                                    value={`http://api.le-bbarge.com/barg/qrcode?entp_no=${entpNo}&barg_no=${bargNo}&lat=${lat}&log=${lon}`}
                                    size={130}
                                />
                            </QrImage>
                            <Ul>
                                <Li>
                                    위도 :
                                    <CodeBoxInput
                                        type="text"
                                        value={lat}
                                        onChange={(e) => {
                                            setLat(e.target.value);
                                        }}
                                    />
                                </Li>
                                <Li>
                                    경도 :
                                    <CodeBoxInput
                                        type="text"
                                        value={lon}
                                        onChange={(e) => {
                                            setLon(e.target.value);
                                        }}
                                    />
                                </Li>
                            </Ul>
                            <ButtonArea>
                                <CodeBoxButtonUl>
                                    <CodeBoxButtonLi>
                                        <CodeBoxButton onClick={saveLatLon}>
                                            저장
                                        </CodeBoxButton>
                                    </CodeBoxButtonLi>
                                </CodeBoxButtonUl>
                            </ButtonArea>
                        </CodeBox>
                    </InfoBox>
                </Base>
            )}
        </>
    );
}

export default React.memo(EnterpriseDetail);
